import React from "react"
import { CarouselComponent } from "./CarouselComponent"

export const WelcomeComponent = () => {
  
  const getLandingImage = () => {
    let isDesktop = window.matchMedia && window.matchMedia("(min-width: 768px)").matches
    if (isDesktop) { return "/images/FrontDoor/frontdoor-landing-desk.png" } else { return "/images/FrontDoor/frontdoor-landing-mob.png" }
  }

  return (
    <CarouselComponent bgPosition="top center" height="110vh" images={[getLandingImage()]}>
      <div className="row">
        <div className="col mx-auto">
          <h1 className="text-center">Welcome to the Redemption Center.<br />Ready to get started?</h1>
        </div>
      </div>
    </CarouselComponent>
  )
}
