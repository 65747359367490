export default {
    R3DSTAPLER_TEXT: "If you’ve already redeemed this offer, you can manage your Hulu subscription here.",
    R3DSTAPLER_P_QUESTION: "Existing Hulu subscriber?",
    R3DSTAPLER_P2_BEFORE: " Before you can start your Hulu with Ads, On Us plan you will need to cancel your current Hulu subscription and wait for your subscription to end before redeeming this offer. For steps on how to cancel your Hulu subscription see ",
    R3DSTAPLER_P3_THIS_HULU: " this Hulu page",
    R3DSTAPLER_P4_IF_YOU_HAVE: " if you have a monthly Hulu subscription, and",
    R3DSTAPLER_P5_ANNUAL: " if you have an annual subscription. As soon as your Hulu becomes inactive (You'll see the exact date on Hulu's Manage Your Account page, and Hulu will notify you when your old subscription ends), come back here to get your Hulu with Ads, On Us!", 
    R3DSTAPLER_SECOND_BUTTON_TEXT: "Manage your Hulu account",

  }
 