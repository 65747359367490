import React, { useEffect, useState } from "react";

import { CrossCompanyPhoneCheck } from "../auth/CrossCompanyPhoneCheck";
import { Button } from "../styled/Button";
import { OfferApi } from "../../utils/api";
import { saniziteRawHtml } from "../../utils/Helpers";
import ReactHtmlParser from "react-html-parser";

const GO5GNEXT_PROMO_CODE = "HULUGO5GNEXT";

export const GO5GNEXTGetStartedComponent = () => {
  const [submissionPromoCode, setSubmissionPromoCode] =
    useState(GO5GNEXT_PROMO_CODE);
  const [bodyText, setBodyText] = useState("");
  const [firstBodyText, setFirstBodyText] = useState("");

  const offerId = Number.parseInt(process.env.REACT_APP_GO5GNEXT_OFFER_ID);

  const initialValues = {
    phoneNumber: "",
  };

  const getButton =
    (promoCode) =>
    ({ disabled, handleClick }) => {
      return (
        <>
          <Button
            type="button"
            className="btn"
            primary
            disabled={disabled}
            onClick={() => {
              setSubmissionPromoCode(promoCode);
              sessionStorage.setItem("promoCode", promoCode);
              handleClick();
            }}
          >
            Continue
          </Button>
        </>
      );
    };

  const submitButtons = [getButton(GO5GNEXT_PROMO_CODE)];

  useEffect(() => {
    const getBodyText = async () => {
      const bodyDescription = await OfferApi.getOfferAsset(
        offerId,
        "BODY_TEXT_LANDING_PAGE2"
      );
      const bodyDescriptionFirstSection = await OfferApi.getOfferAsset(
        offerId,
        "BODY_TEXT_LANDING_PAGE"
      );
      setBodyText(bodyDescription.notes);
      setFirstBodyText(bodyDescriptionFirstSection.notes);
    };
    getBodyText();
  }, [offerId]);

  return (
    <section>
      <div className="container py-5 hero3">
        <div className="row text-center">
          <div className="col">
            <h3 className="magenta text-center">Let's start your submission</h3>
          </div>
        </div>
        <div className="row text-center">
          <div className="col">
            <div className="col">
              {ReactHtmlParser(saniziteRawHtml(firstBodyText))}
            </div>
            <img
              style={{ marginTop: "1rem" }}
              src={"images/GO5GNEXT/GO5GNEXT_Logo.png"}
              alt="GO5GNEXT Logo"
              className="mb-3"
            />
            <div className="col">
              {ReactHtmlParser(saniziteRawHtml(bodyText))}
            </div>
          </div>
        </div>
        <div className="mx-auto col">
          <CrossCompanyPhoneCheck
            initialValues={initialValues}
            buttons={submitButtons}
            promoCode={submissionPromoCode}
            phoneInputClass="col-md-4 mx-auto"
          />
        </div>
        <div className="mx-auto col-md-8 text-center">
          <p>
            Questions before you continue? To find answers{" "}
            <a
              href="https://promotions.t-mobile.com/faq"
              target="_blank"
              rel="noopener noreferrer"
            >
              click here
            </a>{" "}
            or{" "}
            <a
              href="https://promotions.t-mobile.com/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact us
            </a>
            .
          </p>
        </div>
      </div>
    </section>
  );
};
