import styled, { css } from "styled-components"

export const LinkButton = styled.a`
display: inline-block;
font-size: 1rem;
padding: 8px 20px 8px 20px;
min-width: 120px;
width: 250px;
margin-bottom: 20px;
color: #e20074 !important;
border: none !important;
border-radius: 0px;
background-color: #fff !important;
cursor: pointer;
text-decoration: none !important;

@media (min-width: 768px)  {
  width: auto;
  margin-bottom: 30px
}

${props => props.primary && css`
    background: #e20074 !important;
    color: #fff !important;
  `}

`
