import styled from "styled-components"

export const XButton = styled.span`
  color: #fff;
  background: #e20074;
  font-size: xx-large;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  width: 30px;
  cursor: pointer;
`
