import React from "react"

import { LinkButton } from "./styled"
import { QuestionsAndContactComponent } from "./index"
import { CrossCompanyPhoneCheck } from "./auth/CrossCompanyPhoneCheck"

export const GetStartedComponent = (props) => {
  const initialValues = {
    phoneNumber: ""
  }

  return (
    <section>
      <div className="container py-5 hero3">
        <div className="row text-center">
          <div className="col">
            <h1 className="magenta">Let&apos;s start your submission.</h1>
            <p >You will use the same login that you use to access you T-Mobile account online(TMOID). If you dont remember what that is <a href="/faq" rel="noreferrer">click here</a>. </p>
            <p>Bringing a phone number to T-Mobile? Please wait until your number is active on our network before continuing. The temporarily number assigned to you at activation is not compatible with our promotions.</p>
            <p>If you are Home Internet only customer, please find your assigned billing phone number on your bill under "<a href="/faq" rel="noreferrer">connected devices</a>".</p>      

          {/*   <p>If you are a T-Mobile for Business customer, please "<a href={process.env.REACT_APP_TMO_TFB_ID_AUTH_URL} rel="noreferrer">click here to login</a>".</p> */}
          </div>
        </div>
        {props.sprintRedirectActive ? (
          <div className="mx-auto col-md-4">
            <CrossCompanyPhoneCheck initialValues={initialValues} />
          </div>
        ) : (
          <div className="row text-center">
            <div className="mx-auto col-md-10">
              <LinkButton className="btn" id="btnSubmit"
                href={process.env.REACT_APP_TMO_ID_AUTH_URL} 
                onClick={() =>   sessionStorage.setItem("isFromStandardPage", true)}
                primary>Continue</LinkButton>
            </div>
          </div>
        )}
        <div className="text-center">   
          <QuestionsAndContactComponent />
          <p><a href="https://www.t-mobile.com/support/new-to-tmobile/carrier-freedom" rel="noreferrer">Carrier Freedom™ | T-Mobile Support</a> and <a href="https://www.t-mobile.com/support/new-to-tmobile/keep-and-switch" rel="noreferrer">Keep and Switch | T-Mobile Support</a> </p>
        </div>
      </div>
    </section>
  );
}
