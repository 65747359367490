import React, {useEffect, useContext, useState} from "react"
import PropTypes from "prop-types"
import {AppContext} from "../components/context/AppContext"
import {OfferApi} from "../utils/api"
import AnalyticsService from "../utils/AnalyticsService"
import { ROUTE_PATHS } from "../utils/routes"

import {Button} from "../components/styled"
import {MaintenanceModeComponent, CheckStatusComponent, CarouselComponent } from "../components"
const offerId = Number.parseInt(process.env.REACT_APP_FIRST_RESPONDERS_OFFER_ID)

export const FrontDoorLandingPage = () => {
    const {state} = useContext(AppContext)
    const [inMaintenanceMode, setInMaintenanceMode] = useState(true)
    const [maintenanceText, setMaintenanceText] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [isErrorFetchingMaintenanceText, setIsErrorFetchingMaintenanceText] = useState(false)
  
    const getLandingImage = () => {
      let isDesktop = window.matchMedia && window.matchMedia("(min-width: 768px)").matches
      if (isDesktop) { return "/images/FrontDoor/frontdoor-landing-desk.png" } else { return "/images/FrontDoor/frontdoor-landing-mob.png" }
    }

    useEffect(() => {
      
      AnalyticsService.sendUniquePageview(ROUTE_PATHS.FRONTDOORLANDING);
    },)
  
    useEffect(() => {
      const checkMaintenanceMode = async () => {
  
        setIsLoading(true)
        try {
          const clientMaintenanceFlagResult = await OfferApi.getClientAsset("MaintenanceFlag")
  
          const clientMaintenanceText = await OfferApi.getClientAsset("MaintenanceText")
  
          if (clientMaintenanceFlagResult.notes.toLowerCase() === "true") {
            setMaintenanceText(clientMaintenanceText.notes)
            setIsLoading(false)
            return
          }
  
          const offerMaintenanceFlag = await OfferApi.getOfferAsset(offerId, "MaintenanceFlag")
          const offerMaintenanceText = await OfferApi.getOfferAsset(offerId, "MaintenanceText")
  
          if (offerMaintenanceFlag.notes.toLowerCase() === "true") {
            setMaintenanceText(offerMaintenanceText.notes)
            setIsLoading(false)
            return
          }
  
          setInMaintenanceMode(false)
        } catch (error) {
          if (error.message === "Unable to retrieve client asset") {
            setIsErrorFetchingMaintenanceText(true)
          }
          setErrorMessage("Something went wrong. Please try again.")
        } finally {
          setIsLoading(false)
        }
      }
  
      checkMaintenanceMode()
    }, [inMaintenanceMode])
  
    const ActiveContent = () => (
        <>
          <CheckStatusComponent errorMessage={state.commonError} offerId={offerId} />
        </>)
  
    return (
      <> 
            <CarouselComponent bgPosition="top center" height="110vh" images={[getLandingImage()]}>
              <div className="row">
                <div className="col mx-auto">
                  <h1 className="text-center">Welcome to the Redemption Center.<br />Ready to get started?</h1>
                </div>
              </div>
            </CarouselComponent>
            
            <div className="App-header">
            <h3 className="magenta text-center tex-magintop">Let's start your submission</h3>

            <p >
                You will use the same login that you use to access you T-Mobile account online(TMOID). If you dont remember what that is <a
                    href="https://promotions.t-mobile.com/faq"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    click here
                </a>.
            </p>

            <p >
                Bringing a phone number to T-Mobile? Please wait until your number is active on our network before continuing. The
                temporarily number assigned to you at activation is not compatible with our promotions.
            </p>

            <p >
                If you are Home Internet only customer, please find your assigned billing phone number on your bill under "  <a
                    href="https://promotions.t-mobile.com/faq"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    connected devices
                </a>".
            </p>
            <Button type="submit" className="btn" primary >
			Continue
		</Button>
            <p >
                Questions before you continue? To find answer <a
                    href="https://promotions.t-mobile.com/faq"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    click here
                </a> or <a
                    href="https://promotions.t-mobile.com/contact"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    contact us.
                </a>
            </p>
                </div>
                <ActiveContent />

      {!isErrorFetchingMaintenanceText &&
      <div className="text-center container">
        <h1 className="magenta">Temporarily Unavailable</h1>
        <p>This site is currently under going scheduled maintenance. We&apos;ll be back soon.</p>
        <p> Thank you for your patience.</p>
      </div> }
      {isLoading && <div style={{textAlign: "center"}}>Loading ...</div>}
      {errorMessage.length > 0 && !isLoading && !isErrorFetchingMaintenanceText && <div style={{textAlign: "center"}}>{errorMessage}</div>}
      {inMaintenanceMode && !isLoading && <MaintenanceModeComponent maintenanceText={maintenanceText} />}
      {!inMaintenanceMode && !isLoading && !isErrorFetchingMaintenanceText && <ActiveContent /> }
      </>
    )
  }
  
  FrontDoorLandingPage.propTypes = {
    history: PropTypes.object
  }
  