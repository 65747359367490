import React, { useEffect, useState, useContext } from "react"
import PropTypes from "prop-types" 
import AnalyticsService from "../utils/AnalyticsService"
import { ROUTE_PATHS } from "../utils/routes"
import { Formik, Form, Field } from "formik"
import { TextInput, SelectInput } from "../components/forms"
import { ErrorMessage } from "../components/styled"
import { OfferApi, AuthApi } from "../utils/api";
import { Button } from "../components/styled"
import { AppContext } from "../components/context/AppContext"
import * as Yup from "yup"

export const PromoCode = ({ history }) => {
    const { dispatch, state } = useContext(AppContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const validPhoneLengthRegEx = /(^$)|(^\d{10}$)/
    const isTFBAccount = state.user.isTFBAccount;
    const [eligiblesLinesResponse, setEligiblesResponse] = useState([]);

    useEffect(() => {
        AnalyticsService.sendUniquePageview(ROUTE_PATHS.PROMO_CODE_PAGE);
        if (eligiblesLinesResponse.length > 0 && !isTFBAccount) {
            setEligiblesResponse(eligiblesLinesResponse)
        } else if (eligiblesLinesResponse.length <= 0 && !isTFBAccount) {
            const getListOfLines = async () => {
                setIsSubmitting(true)
                try {
                    let eligibleNumbersRequest = {
                        userInfo: '',
                        isTFBAccount: isTFBAccount,
                        phoneNumber: '',
                    }
                    await AuthApi.getEligiblesLines(eligibleNumbersRequest).then((response) => {
                        //if user has ineligible reason -- where will errors show in the UI?
                        if (response.data.length <= 0) {
                            setErrorMessage('There is no eligibles lines');
                        }
                        else {
                            setEligiblesResponse(response.data)
                        }
                        setIsSubmitting(false)
                    },
                        (error) => {
                            setErrorMessage("Something went wrong, please try again");
                            setIsSubmitting(false);
                        })
                }
                catch (error) {
                    setErrorMessage("Something went wrong, please try again");
                    setIsSubmitting(false);
                }
            }
            getListOfLines()
        }
    }, [eligiblesLinesResponse, isTFBAccount])

    const submitPromocodeForm = async (event) => {
        AnalyticsService.sendGa4Event({ event: 'Promotions', trigger: "submitted", category: "submitPromocodeForm", action: isTFBAccount, label: "continue" })
        if (isTFBAccount) {
            await setUserTfbInfo(event);

        } else if (!isTFBAccount) {
            await submitPromoCode(event, '');
        }
    }

    const setUserTfbInfo = async (event) => {
        let values = state.user;
        values.primaryPhoneNumber = event.tfbNumber.trim();
        const user = await AuthApi.setTfbUserInfo(values);
        if (!user) {
            dispatch({
                type: "COMMONERROR",
                payload:
                    "The phone number you entered was not found. Please try again",
            });
            setErrorMessage("The phone number you entered was not found. Please try again");
            return;
        }
        dispatch({ type: "SETUSER", payload: user });
        await submitPromoCode(event, user);
    }

    const submitPromoCode = async (e, user) => {
        setIsSubmitting(true);
        let phoneNumberForm;
        if (isTFBAccount) {
            phoneNumberForm = e.tfbNumber
        } else {
            phoneNumberForm = e.phoneNumber
        }
        AnalyticsService.sendGa4Event({ event: 'Promotions', trigger: "submitted", category: "submitPromoCode", action: 'continue', label: e.promoCode })
        let lookupValues = {
            phoneNumber: phoneNumberForm,
            promoCode: e.promoCode,
            validateLine: true,
            userInfo: user.userInfo
        };

        await OfferApi.getOfferDetails(lookupValues).then((response) => {
            if (!response.offerId && response.ineligibleReason) {
                setErrorMessage(response.ineligibleReason);
                setIsSubmitting(false);
            }
            else {
                dispatch({ type: "SETCAMPAIGN", payload: response });
                dispatch({ type: "SETPROMOCODE", payload: e.promoCode });
                dispatch({ type: "SETSELECTEDPHONE", payload: phoneNumberForm });
                dispatch({ type: "SETLOOKUPVALUES", payload: lookupValues });
                history.push(ROUTE_PATHS.PROMOTION);
            }
        },
            (error) => {
                setErrorMessage("Something went wrong, please try again");
                setIsSubmitting(false);
            })
    }

    const validatePhone = async (value) => {
        setIsSubmitting(true)
        let error;
        if (!value) {
            error = "T-Mobile phone number is required"
        }

        if (value && !value.replace(/[^a-z0-9]/g, "").match(validPhoneLengthRegEx)) {
            error = "T-Mobile phone number must be 10 digits"
        }

        if (value && (value.charAt(0) === "1" || value.charAt(0) === "0")) {
            error = "T-Mobile phone number cannot start with 0 or 1"
        }
        return error
    }

    const initialValues = {
        phoneNumber: "",
        promoCode: "",
        tfbNumber: ""
    };

    const bauValidationSchema = Yup.object().shape({
        phoneNumber: Yup.string().required('T-Mobile Phone Number is requried'),
        promoCode: Yup.string().required('PromoCode cannot be empty')
    });

    const tfbValidationSchema = Yup.object().shape({
        tfbNumber: Yup.string().required('T-Mobile Phone Number is requried'),
        promoCode: Yup.string().required('PromoCode cannot be empty')
    });

    return (
        <>
            <div className="frontdoor">
                <div>
                    <h1 className="magenta">Start a new submission</h1>
                    <p> Submit for a promotion by choosing the line you want to submit for and entering a promotion code. </p>
                </div>
                <p >
                    Fields marked with * are required
                </p>
                <Formik initialValues={initialValues} validationSchema={ isTFBAccount ? tfbValidationSchema : bauValidationSchema} onSubmit={(e) => submitPromocodeForm(e)} >
                    <Form>
                        {!isTFBAccount && <div className="row">
                            <div className="form-group col-md-6">
                                <label className="frontdoor-label-size">Please select a phone number to submit for a promotion*</label>
                                <Field id="phonenumber" name="phoneNumber" component={SelectInput} aria-required="true">
                                    <option value=""></option>
                                    {eligiblesLinesResponse.map((numbers, y) => {
                                        return (<option key={y}>{numbers}</option>)
                                    })}
                                </Field>
                            </div>
                        </div>}
                        {isTFBAccount && <div className="row">
                            <div className="form-group col-md-6">
                                <label className="frontdoor-label-size">Please enter a phone number to submit for a promotion*</label>
                                <Field id="tfbNumber" name="tfbNumber" component={TextInput} type="text" validate={validatePhone} placeholder="Phone Number"></Field>

                            </div>
                        </div>}
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label className="frontdoor-label-size" htmlFor="promocode">Promotion Code*</label>
                                <Field id="promoCode" name="promoCode" component={TextInput} type="text" placeholder="" arialabel="Enter the promotion code" aria-required="true"></Field>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-6 text-center text-md-left">
                                <Button type="submit" className="btn" id="btnSubmit" primary >Continue</Button>
                            </div>
                        </div>
                        {!isSubmitting && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                    </Form>
                </Formik>
            </div>
        </>
    )
}
PromoCode.requiresAuth = true;
PromoCode.propTypes = {
  history: PropTypes.object.isRequired, 
}
