import React, { useEffect, useContext, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { LinkButton } from "../components/styled";
import { AppContext } from "../components/context/AppContext";
import { LoadingSpinner } from "../components/loadingSpinner";
import { ROUTE_PATHS } from "../utils/routes";
import { AuthApi, OfferApi } from "../utils/api";
import AnalyticsService from "../utils/AnalyticsService";
import { resetDispatch, getTmoAuthUrl, setDnsSetting } from "../utils/Helpers";
import { useCookies } from "react-cookie";
const SAMSCLUB_CODE = "240003";
const COSTCO_CODE = "240150";

export const Dashboard = ({ location, history }) => {
	const { dispatch, state } = useContext(AppContext);
	const [isFetchingUser, setIsFetchingUser] = useState(false);
	const [isFetchingCampaignFromState, setIsFetchingCampaignFromStateCode] =
		useState(false);
	const [showRetryButton, setShowRetryButton] = useState(false);
	const [redirectUrl, setRedirectUrl] = useState("");
	const [campaignId, setCampaignId] = useState(false);
	const [promoCodeInParam, setPromoCode] = useState(false);
	

	const getPromoCodeFromParams = useCallback(() => {
		const searchParams = new URLSearchParams(location.search);
		const redirectStateVariables = searchParams.get("state");
		if (redirectStateVariables) {
			let redirectStateArray = redirectStateVariables.split(":");
			if (
				redirectStateArray.length === 2 &&
				redirectStateArray[0].toLocaleUpperCase() === "PROMOCODE"
			) {
				return redirectStateArray[1];
			}
		}
	}, [location.search]);

	const [cookies] = useCookies(["id_token"]);

	const setDnsCookie = (value) => {
		const date = new Date();
		const expiration = new Date(date.getTime() + 31536000000);
		const cookie =
			(value.toLowerCase() === "donotsell" ? "dnt=1" : "dnt=0") +
			";domain=" +
			window.location.domain +
			";expires=" +
			expiration.toGMTString();
		document.cookie = cookie;
	};

	const searchParams = new URLSearchParams(location.search);
	const isRedirectToStatusPage = searchParams.get("state") !== null ? searchParams.get("state").toLowerCase() === "submissionstatus" : false;

	//REDIRECT URL
	useEffect(() => {
		const getRedirectUrl = () => {
			const promoCode = getPromoCodeFromParams();
			const tmoURL = getTmoAuthUrl(
				promoCode,
				state.user.isTFBAccount,
				state.user.isMyTMOFlow,
				isRedirectToStatusPage
			);
			setRedirectUrl(tmoURL);
		};

		const id_token = cookies.id_token;
		if (!id_token) {
			getRedirectUrl();
		}
	}, [
		setRedirectUrl,
		getPromoCodeFromParams,
		state.campaign.offerId,
		state.user.isTFBAccount,
		state.user.isMyTMOFlow,
		cookies.id_token,
		isRedirectToStatusPage
	]);

	//LOGIN
	useEffect(() => {
		const login = async () => {
			const OAuthCode = searchParams.get("code");
			const ban = searchParams.get("ban");
			const userToken = searchParams.get("user_token");
			const sessionNumber = searchParams.get("session_num");
			const id_token = cookies.id_token;
				 
			if (!state.user.tMobileId) {
				try {
					setIsFetchingUser(true);
					AnalyticsService.sendGa4Event({
						event: "Promotions",
						trigger: "signin",
						category: "dashboard",
						action: "Upload_Site",
						label: "Sign_In",
					});

					AnalyticsService.sendEvent("Upload Site", "Sign In", "dashboard", {
						event: "signin",
					});

					if (id_token && !OAuthCode && !ban && !userToken) {
												  
						const user = await AuthApi.loginWithIdToken(id_token);
						console.log("User: " + user);
						if (!user) {
							window.location.href = process.env.REACT_APP_TMO_ID_AUTH_URL;
						}

						const dnsSetting = await AuthApi.getGlobalDnsSettingV2(
							user.tMobileId
						);
						user.Provider = "T-Mobile";
						if (dnsSetting) {
							setDnsCookie(dnsSetting.localDoNotSellSetting);
							setDnsSetting(dnsSetting, user.tMobileId);
						} else {
							console.log("DNS retrieval falied");
						}
						dispatch({ type: "SETUSER", payload: user });
						setIsFetchingUser(false);
					}

					if (OAuthCode) {
						//login with OAuth code
												   
						const user = await AuthApi.loginWithTmoId(OAuthCode, sessionNumber);
						const dnsSetting = await AuthApi.getGlobalDnsSettingV2(
							user.tMobileId
						);
						user.Provider = "T-Mobile";
						if (dnsSetting) {
							setDnsCookie(dnsSetting.localDoNotSellSetting);
							setDnsSetting(dnsSetting, user.tMobileId);
						} else {
							console.log("DNS retrieval falied");
						}
						dispatch({ type: "SETUSER", payload: user });
						setIsFetchingUser(false);
					}
					if (ban) {
						// ban-login for testing
											 
						const user = await AuthApi.loginWithBan(ban);
						const provider = searchParams.get("provider");
						user.Provider = provider;
						dispatch({ type: "SETUSER", payload: user });
						setIsFetchingUser(false);
					}
					if (userToken) {
						// if a user_token is present in the url,
						// then we have logged in with an HttpHeader directly with the API
						console.log("httpHeader token callback");
						const user = await AuthApi.userTokenHeaderCallback(userToken);
						user.Provider = "T-Mobile";
						dispatch({ type: "SETUSER", payload: user });
						setIsFetchingUser(false);
					}
				} catch (error) {
					setShowRetryButton(true);
					setIsFetchingUser(false);
					setIsFetchingCampaignFromStateCode(false);
				}
			}

			if (state.user.tMobileId) {
				if(isRedirectToStatusPage){
					history.push(ROUTE_PATHS.SUBMISSION_STATUS);
					return;
				}

				// if we have a promoCode from OAuth state or sesion storage (thank you page),
				// go straight to the found promotion page for the given promo-code campaign
				const promoCodeParams = getPromoCodeFromParams()
					? getPromoCodeFromParams()
					: state.lookupValues.promoCode;
				const promoCodeStorage = sessionStorage.getItem("promoCode");
				const isFromStandardPage = sessionStorage.getItem("isFromStandardPage");
				let promoCode;
				//show promoCode form only for Costco and Samsclub flow 
				if(promoCodeStorage === SAMSCLUB_CODE || promoCodeStorage === COSTCO_CODE ){
					return history.push(ROUTE_PATHS.PROMO_CODE_PAGE);
				}

				if (!isFromStandardPage && (promoCodeParams || promoCodeStorage)) {
					if (promoCodeParams) {
						promoCode = promoCodeParams;
					} else {
						promoCode = promoCodeStorage;
						dispatch({ type: "SETPROMOCODE", payload: promoCode });
					}

					// reset promoCode
					sessionStorage.setItem("promoCode", "");

					// reset submission
					resetDispatch(state, dispatch);
					setIsFetchingCampaignFromStateCode(true);

					//hotfix to fix FirstResponders
					if (!state.lookupValues.phoneNumber) {
						state.lookupValues.phoneNumber = state.user.phoneNumbers[0];
					}
					//TFB flow
					if (state.user.isTFBAccount) {
						sessionStorage.setItem("promoCode",promoCode);
						history.push(ROUTE_PATHS.GETTFBMSISDN);
						//values.primaryPhoneNumber = state.lookupValues.phoneNumber.trim();						
					} else {
						// do campaign-lookup
						let values = {
							phoneNumber: state.lookupValues.phoneNumber,
							promoCode: promoCode,
							isTFBAccount: state.user.isTFBAccount,
							userInfo: state.user.userInfo,
							validateLine: true,
							isMyTMOFlow: state.user.isMyTMOFlow,
						};
						
						if (!campaignId && promoCodeInParam !== values.promoCode) {
							const response = await OfferApi.getOfferDetails(values);

							if (!response.offerId && response.ineligibleReason) {
								setIsFetchingCampaignFromStateCode(false);
								setShowRetryButton(true)
								dispatch({
									type: "COMMONERROR",
									payload: response.ineligibleReason,
								});
								return;
							}
							else {
								setCampaignId(response.campaignId);
								setPromoCode(response.description);
							}
		
							dispatch({ type: "SETCAMPAIGN", payload: response });
						}
									
						history.push(ROUTE_PATHS.PROMOTION);
						}						  	
					}
					else{
						sessionStorage.removeItem("isFromStandardPage");
						history.push(ROUTE_PATHS.FRONTDOORFAQ);
				}
				if (
					!state.user.isTFBAccount
				) {
					// Only retreive submissions by ban if we intend to stay on this page
					//getSubmissions();
				}
			}

			if (   
				!state.user.tMobileId &&
				!userToken &&
				!ban &&
				!OAuthCode &&
				!id_token
			) {
				history.push(ROUTE_PATHS.HOME);
			}
		};
		login();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.user]);

	/* const cleanAndOrderSubmissions = (unorderedSubmissions) => {
		const mergedSubmissions = [
			...unorderedSubmissions.submissions,
			...unorderedSubmissions.iSubmissions,
		];
		const distinctSubmissions = [];
		const map = new Map();

		//get an array of distinct submissions by tracking id
		for (const submission of mergedSubmissions) {
			if (!map.has(submission.trackingId)) {
				map.set(submission.trackingId, true);
				distinctSubmissions.push(submission);
			}
		}

		return distinctSubmissions.sort((sub1, sub2) => {
			if (sub1.stampDate > sub2.stampDate) {
				return -1;
			}

			if (sub1.stampDate < sub2.stampDate) {
				return 1;
			}

			return 0;
		});
	};

	 const getSubmissions = async () => {
		try {
			if (!state.user.isTFBAccount) {
				const submissions = await SubmissionApi.getSubmissions(state.user);
				const orderedSubmissions = cleanAndOrderSubmissions(submissions);
				dispatch({ type: "SETSUBMISSIONS", payload: orderedSubmissions });
			}
		} catch (error) {
			console.log("Something went wrong calling submissions endpoint: " + error)
		}
	}; */

	return (
		<>
			{(isFetchingCampaignFromState || isFetchingUser) && !state.user.isTFBAccount && (
				<>
					<div className="container hero3">
						<div className="row">
							<div className="col-md-12" style={{ textAlign: "center" }}>
								<LoadingSpinner
									className="mb-3"
									enabled={isFetchingCampaignFromState || isFetchingUser}
								></LoadingSpinner>
								<h2 className="magenta">Loading...</h2>
								<br />
							</div>
						</div>
					</div>
				</>
			)}
			{!isFetchingCampaignFromState && !isFetchingUser && showRetryButton && (
				<div>
					<div className="container hero3">
						<div className="row">
							<div className="col-md-12">
								<h1 className="magenta">Oops! Something went wrong.</h1>
								<br />
								<p style={{ textAlign: "center" }}>Please try again.</p>
							</div>
						</div>
					</div>
					<div style={{ textAlign: "center" }}>
						<LinkButton href={redirectUrl} primary>
							Retry
						</LinkButton>
					</div>
				</div>
			)}
		</>
	);
};

Dashboard.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

Dashboard.requiresAuth = true;
