import React, { useState, useContext, useEffect} from "react"
import {useDropzone} from "react-dropzone"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faFilePdf} from "@fortawesome/free-solid-svg-icons"

import {Dropzone, ThumbnailContainer, Button, XButton, ErrorMessage} from "../styled"
import {AppContext} from "../context/AppContext"

//for some reason the thumbnail div would not work as styled component :(
const thumbnailStyles = {
  marginBottom: 8,
  marginRight: 8,
  position: "relative"
}

const previewStyles = {
  display: "inline",
  width: 100,
  height: 100
}

const uploadNameStyles = {
  width: 100,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}

export const FileInput = () => {
  const {state, dispatch} = useContext(AppContext)

  const [errorMessage, setErrorMessage] = useState("")

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragActive,
    isDragReject,
    open,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: "image/jpeg, image/jpg, image/png, application/pdf",
    maxSize: 5242880,
    onDrop: (acceptedFiles, rejectedFiles)  => {
      setErrorMessage("")
      let errorMessage = []
      if (rejectedFiles.length > 0) {
        errorMessage.push("Please ensure each file is below 5MB and are of the type .pdf, .png, or jpeg.")
      }

      const maxAcceptedFiles = 16 - state.files.length - acceptedFiles.length
      if (state.files.length + acceptedFiles.length > 15) {
        errorMessage.push("The maximum number of uploads allowed is 15.")
      }

      let addedFiles = []
      for (let i = 0; i < acceptedFiles.length; i++) {
        const existing = state.files.filter((file) => file.name === acceptedFiles[i].name)
        if (existing.length === 0 && (state.files.length + addedFiles.length < 15)) {
          addedFiles.push(acceptedFiles[i])
        }
      }
      if (addedFiles.length < acceptedFiles.length && addedFiles.length < maxAcceptedFiles) {
        errorMessage.push("Files with duplicate names as those ready for upload were skipped.")
      }
      if (errorMessage.length > 0) {
        setErrorMessage(errorMessage.join(" "))
      }

      const fileList = [...state.files, ...addedFiles]
      // this needs to be dispatched to context
      dispatch({type: "SETFILES", payload: fileList.map(file => Object.assign(file, {preview: URL.createObjectURL(file)}))})
    }
  })

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) { newWindow.opener = null }
  }

  const onClickUrl = (url) => {
    return () => openInNewTab(url)
  }

  const removeFile = (fileToRemove) => {
    const filtered = state.files.filter((file) => file.name !== fileToRemove.name)
    dispatch({type: "SETFILES", payload: filtered})
  }

  const renderThumbnails = state.files.map(file => (
    <div style={thumbnailStyles} key={file.name}>
      {
        file.type === "application/pdf"
          ? <FontAwesomeIcon icon={faFilePdf} size="5x" style={previewStyles} onClick={onClickUrl(file.preview)}/>

          : <img src={file.preview} style={previewStyles} alt={file.name} onClick={onClickUrl(file.preview)} />
      }
      <div style={uploadNameStyles}>{file.name}</div>
      <XButton tabIndex={0} onKeyPress={event => { if (event.key === "Enter") { removeFile(file) } } } className="text-center" onClick={(e) => removeFile(file,e)}>&times;</XButton>
    </div>
  ))

  useEffect(() => () => {
    // dropzone docs recommend revoking data uris to avoid memory leaks

    state.files.forEach(file => URL.revokeObjectURL(file.preview))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Dropzone {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
        <input {...getInputProps({disabled: false})}/>
        <p className="drag-and-drop-color">Drag and Drop some files here</p>
        <Button primary style={{cursor: "pointer"}} onClick={open}>Upload Images</Button>
      </Dropzone>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <ThumbnailContainer>
        {renderThumbnails}
      </ThumbnailContainer>
    </>
  )
}
