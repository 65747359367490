import React, {useContext, useEffect} from "react"
import {Link} from "react-router-dom"
import PropTypes from "prop-types"

import {ROUTE_PATHS} from "../utils/routes"
import {AppContext} from "../components/context/AppContext"
import {QuestionsAndContactComponent} from "../components"
import {Button} from "../components/styled"
import AnalyticsService from "../utils/AnalyticsService";


export const SubmissionMethod = ({history}) => {
  const {dispatch, state} = useContext(AppContext)
  useEffect(() => {
		AnalyticsService.sendUniquePageview(ROUTE_PATHS.THANKS);
	
	},);
  const handleMailInClicked = () => {
    dispatch({type: "SETCHOSEMAILIN", payload: true})

    const pdfUrl = state.campaign.offerFormUrl

    window.open(pdfUrl, "_blank")

    history.push(ROUTE_PATHS.THANKS)

  }

  return (
    <section>
      <div className="container hero3">
        <div className="row">
          <div className="col-md-12 mb-4">
            <h1 className="magenta">Promotion Submission Method</h1>
            <QuestionsAndContactComponent />
            <p>Choose your submission method by selecting one of the following options:</p>
            <ol>
              <li>Online: Enter your submission online.</li>
              <li>Mail In: Print your submission form and mail in with the required materials.</li>
            </ol>
          </div>
          <div className="ro">
            <div className="col-md-12 mb-4">
              <Link to={ROUTE_PATHS.REGISTER} ><Button style={{cursor: "pointer"}} className="btn" primary>Online</Button></Link>
              <Button style={{cursor: "pointer"}} className="btn ml-md-3" primary onClick={handleMailInClicked}>Mail In</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

SubmissionMethod.propTypes = {
  history: PropTypes.object
}

SubmissionMethod.requiresAuth = true
