import React, { useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import AnalyticsService from "../../utils/AnalyticsService"
import { ROUTE_PATHS } from "../../utils/routes"
import { Formik, Form, Field } from "formik"
import { SelectInput } from "../../components/forms"
import { TextInput } from "../../components/forms"
import { ErrorMessage } from "../../components/styled"
import { OfferApi, AuthApi } from "../../utils/api";
import { Button } from "../../components/styled"
import { AppContext } from "../../components/context/AppContext"
import { validateDisableButtonTfb } from "../../utils/FormUtils";

export const SelectFrontdorPhoneForm = ({ history, submitting, showButton, isShowPromoCode, inputPhoneNumber, isTfbOrBau, onSubmit, isDisable, isEligiblesNumbers, showPromoCodeLink, onChange, tfbError, handleClickLinkCancel, handleClickLink }) => {
    const { dispatch, state } = useContext(AppContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmittingPromoCode, setIsSubmittingPromoCode] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [disableButton, setDisableButton] = useState(true)
    const validPhoneLengthRegEx = /(^$)|(^\d{10}$)/
      /*  const isEligiblesNumbersTest = ['6502329247', '6502329249', '6502329247'];   */ 

    useEffect(() => {
        AnalyticsService.sendUniquePageview(ROUTE_PATHS.FRONTDOORLANDING);
        if (tfbError) {
            setErrorMessage(tfbError)
            setIsSubmitting(false);
        }
        if(submitting) {
            setIsSubmittingPromoCode(submitting)
        } else if (!submitting) {
            setIsSubmittingPromoCode(submitting)
        }

    }, [errorMessage, tfbError, submitting, showPromoCodeLink])

    const submitPromoCode = async (e) => {
        setIsSubmittingPromoCode(true);

        if(isTfbOrBau){
            let values = state.user;
            values.primaryPhoneNumber = inputPhoneNumber;
            const user = await AuthApi.setTfbUserInfo(values);
            if (!user) {
                dispatch({
                    type: "COMMONERROR",
                    payload:
                        "The phone number you entered was not found. Please try again",
                });
                setErrorMessage("The phone number you entered was not found. Please try again");
                setIsSubmittingPromoCode(false);
                return;
            }
            dispatch({ type: "SETUSER", payload: user });
        }

        AnalyticsService.sendGa4Event({ event: 'Promotions', trigger: "submitted", category: "submitPromoCode", action: isTfbOrBau, label: e.promoCode })
        let lookupValues = {
            phoneNumber: inputPhoneNumber,
            promoCode: e.promoCode,
            validateLine: true,
            userInfo: state.user.userInfo
        };

        await OfferApi.getOfferDetails(lookupValues).then((response) => {
            if (!response.offerId && response.ineligibleReason) {
                setErrorMessage(response.ineligibleReason);
                setIsSubmittingPromoCode(false);
            }
            else {
                dispatch({ type: "SETCAMPAIGN", payload: response });
                dispatch({ type: "SETPROMOCODE", payload: e.promoCode });
                dispatch({ type: "SETSELECTEDPHONE", payload: inputPhoneNumber });
                dispatch({ type: "SETLOOKUPVALUES", payload: lookupValues });
                history.push(ROUTE_PATHS.PROMOTION);
            }
        },
            (error) => {
                setErrorMessage("Something went wrong, please try again");
                setIsSubmittingPromoCode(false);
            })

    }

    const validatePhone = async (value) => {
        setIsSubmitting(true)
        let error;
        if (!value) {
            error = "T-Mobile phone number is required"
        }

        if (value && !value.replace(/[^a-z0-9]/g, "").match(validPhoneLengthRegEx)) {
            error = "T-Mobile phone number must be 10 digits"
        }

        if (value && (value.charAt(0) === "1" || value.charAt(0) === "0")) {
            error = "T-Mobile phone number cannot start with 0 or 1"
        }
        const isDisableTfbButton = validateDisableButtonTfb(error)
        setDisableButton(isDisableTfbButton)
        return error
    }

    return (
        <>
            <Formik onSubmit={onSubmit}>
                <Form onChange={onChange}>
                    {!isTfbOrBau && isEligiblesNumbers.length > 0 && <div className="row">
                        <div className="form-group col-md-6">
                            <label className="frontdoor-label-size">Please select a phone number to submit for a promotion or check a previous request's status</label>
                            <Field id="phonenumber" name="phoneNumber" component={SelectInput} aria-required="true">
                                <option value="" ></option>
                                {isEligiblesNumbers.map((numbers, y) => {
                                    return (<option key={y}>{numbers}</option>)
                                })}
                            </Field>
                        </div>

                    </div>}
                    {isTfbOrBau && <div className="row">
                        <div className="form-group col-md-6">
                            <label className="frontdoor-label-size">T-Mobile Phone Number*</label>
                            <Field id="tfbNumber" name="tfbNumber" component={TextInput} type="text" validate={validatePhone} placeholder="Phone Number"></Field>

                        </div>
                    </div>}
                    {!isSubmitting && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

                    <p >Have a promo code for your offer? <button className="link-button" onClick={(e) => handleClickLink(e)}>Click here to enter your promo code (optional):</button></p>

                    {showButton &&
                        <div className="mt-3  text-center text-md-left">
                            <Button type="submit" className="btn" id="btnSubmit" primary disabled={isTfbOrBau ? disableButton : isDisable} >Continue</Button>
                        </div>}

                </Form>
            </Formik>
            {isShowPromoCode && <Formik onSubmit={(e) => submitPromoCode(e)} >
                <Form>
                    <div className="max-width-frondoor">
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label className="frontdoor-label-size" htmlFor="promocode">Promotion Code*</label>
                                <Field id="promocode" name="promoCode" component={TextInput} type="text" placeholder="" arialabel="Enter the promotion code" aria-required="true"></Field>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mt-3 continue-frontdoor text-center text-md-left">
                                <Button type="submit" className="btn" id="btnSubmit" primary disabled={inputPhoneNumber !== "" && inputPhoneNumber !== undefined ? false : true} >Continue</Button>
                            </div>
                            <div className="mt-3 cancel-frontdoor text-center text-md-left">
                                <Button type="submit" className="btn" id="btnSubmit" onClick={(e) => handleClickLinkCancel(e)} primary  >Cancel</Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>}
            {!isSubmittingPromoCode && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </>
    )
}

SelectFrontdorPhoneForm.propTypes = {
    history: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    isTfbOrBau: PropTypes.bool.isRequired,
    isDisable: PropTypes.bool.isRequired,
    showButton: PropTypes.bool.isRequired,
    isShowPromoCode: PropTypes.bool.isRequired,
    isEligiblesNumbers: PropTypes.array.isRequired,
    inputPhoneNumber: PropTypes.string,
    showPromoCodeLink: PropTypes.bool.isRequired,
    handleClickLink: PropTypes.func.isRequired,
    handleClickLinkCancel: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
}
