import React  from "react"
import PropTypes from "prop-types"
import {withRouter} from "react-router-dom"
import {LinkButton, Button} from "../components/styled"

const rewardOfferArray = [
  Number.parseInt(process.env.REACT_APP_YT_BASE_GROUPA_OFFER_ID),
  Number.parseInt(process.env.REACT_APP_YT_BASE_GROUPB_OFFER_ID),
  Number.parseInt(process.env.REACT_APP_YT_BASE_GROUPC_OFFER_ID),
  268504,
  268509
];

const PARAMOUNT_OFFERID = Number.parseInt(process.env.REACT_APP_PARAMOUNT_OFFER_ID)
const AAA_OFFERID = Number.parseInt(process.env.REACT_APP_AAA_OFFER_ID)
const AAA_REWARD_URL = process.env.REACT_APP_AAA_REWARD_REDIRECT_URL

export const RedeemButton = withRouter(({submission, history }) => {
  return (
    <div className="pt-3 text-center text-md-left">
      {/* virtual pay redeem button */}
      {submission.isVirtualPay
        && submission.debitCard
        && submission.debitCard.issueStatus === 2
        && <LinkButton href={submission.debitCard.virtualPayClaimCode} target="_blank" primary>Redeem Card</LinkButton>
      }

      {/* offer reward button */}
      { submission.valid
        && rewardOfferArray.includes(submission.offerId)
        && <LinkButton href={submission.redemptionLink} target="_blank" primary>Click Here For Your Reward</LinkButton>
      }
      
      { submission.offerId !== PARAMOUNT_OFFERID &&
        submission.offerId !== AAA_OFFERID &&
        submission.instantValidationCodes
        && submission.instantValidationCodes.length > 0
        && <LinkButton href={submission.instantValidationCodes[0].instantCodeValue} target="_blank" primary>Click Here For Your Reward</LinkButton>
      }

      {/* Redirect link for AAA Year on US redeemtion */} 
      { submission.offerId === AAA_OFFERID &&
        submission.instantValidationCodes
        && submission.instantValidationCodes.length > 0
        && <LinkButton href={AAA_REWARD_URL+submission.instantValidationCodes[0].instantCodeValue} target="_blank" primary>Click Here For Your Reward</LinkButton>
      }
      
      { submission.offerId === PARAMOUNT_OFFERID &&
        submission.instantValidationCodes &&
        submission.instantValidationCodes.length > 0 &&
        submission.instantValidationCodes[0].instantCodeValue &&
        <>
          <div>
            <LinkButton href={submission.instantValidationCodes[0].instantCodeValue} target="_blank" primary>New to Paramount+ Redeem Now</LinkButton>
          </div>
          <div>
            <LinkButton href="https://www.paramountplus.com/account" target="_blank" primary>Existing Paramount+ Essential monthly subscriber? Redeem here using code below.</LinkButton>
            <p>
              Copy and apply this code:
              <br />
              <b>{submission.instantValidationCodes[0].instantCodeValue.split("=")[1]}</b>
              <br />
              in your account under "Redeem a Coupon".
            </p>
          </div>
        </>
      }

      <Button style={{cursor: "pointer"}} className="ml-md-3" secondary onClick={history.goBack}>Back</Button>
    </div>
  )
})

RedeemButton.propTypes = {
  submission: PropTypes.object.isRequired
}
