import BaseApi from "./BaseApi"

const SUBMISSION_ENDPOINT = "/submission"

class SubmissionApi extends BaseApi {

  async getSubmissions(user) {
    try {

      const endpoint = `${SUBMISSION_ENDPOINT}/ban`
      const response = await this.get(endpoint)
      return response.data
    } catch (error) {
      throw new Error("Unable to retreive submissions")
    }
  }

  async getOffers(frontDoorQuestionary) {
    try {

      const endpoint = `${SUBMISSION_ENDPOINT}/offers`
      const response = await this.get(endpoint, frontDoorQuestionary)
      return response.data
    } catch (error) {
      throw new Error("Unable to retreive offers")
    }
  }

  async insert(submissionValues) {
    try {
      const response = await this.post(SUBMISSION_ENDPOINT, submissionValues)
      return response.data
    } catch (error) {
      throw new Error("Unable to create submission")
    }
  }

  async update(iSubmission) {
    try {
      const response = await this.put(SUBMISSION_ENDPOINT, iSubmission)
      return response.data
    } catch (error) {
      throw new Error("Unable to update submission")
    }
  }

  async upload(files, iSubmission) {
    try {
      const data = new FormData()
      files.forEach((file) => data.append("files", file))
      data.append("files", files)
      data.append("userInfoDetails", iSubmission.userInfo)

      const endpoint = `${SUBMISSION_ENDPOINT}/${iSubmission.iSubmitId}/upload`
      const response = await this.post(endpoint, data)
      return response.data
    } catch (error) {
      throw new Error("Unable to upload files")
    }
  }

  async finalize(iSubmission) {
    try {
      const endpoint = `${SUBMISSION_ENDPOINT}/${iSubmission.iSubmitId}/finalize`
      const response = await this.post(endpoint, iSubmission)
      return response.data
    } catch (error) {
      throw new Error("Unable to finalize submission")
    }
  }

  async validateIfExists(offerId, carrier, phoneNumber, userInfo) {
    try {
      const endpoint = `${SUBMISSION_ENDPOINT}/lookupisubmission`
      const data = {
        offerId: offerId,
        carrier: carrier,
        phoneNumber: phoneNumber,
        userInfo: userInfo
      }
      const response = await this.post(endpoint,data)
      return response.data
    } catch (error) {
      throw new Error("Unable to retreive submission")
    }
  }

  async getPreviousSubmissionsByBan(requestData) {
    try {
      const endpoint = `${SUBMISSION_ENDPOINT}/banwithmsisdn`
      const response = await this.post(endpoint,requestData);
      return response.data
    } catch (error) {
      throw new Error("Unable to retreive previous submissions on ban")
    }
  }
  
  async getAllPreviousSubmissionsByBan(requestData) {
    try {
      const endpoint = `${SUBMISSION_ENDPOINT}/allsubmissionsbyban`
      const response = await this.post(endpoint,requestData);
      return response.data
    } catch (error) {
      throw new Error("Unable to retreive all previous submissions on ban");
    }
  }

}

export default new SubmissionApi()
