import React, { useEffect, useState } from 'react'
import { CarouselContent, CarouselImg, DotIndicator, DotIndicatorWrapper } from './styled/CarousellElements'

const IMAGE_INTERVAL =  8000;
var offerId = Number.parseInt(process.env.REACT_APP_SIXMONTHS_OFFER_ID)

export const CarouselComponent = ({images,children,bgPosition,height}) => {

    const [currentImg, setCurrentImg] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
         currentImg === images.length -1 ? setCurrentImg(0) : setCurrentImg(currentImg + 1)
        }, IMAGE_INTERVAL)
      
        return () => clearInterval(interval) // This represents the unmount function, to clear the interval to prevent memory leaks.
      }, [currentImg, images])

    return (
        <>
        <CarouselImg bgPosition={bgPosition} src={images[currentImg]} height={height} offerId={offerId}>
            <span/>
            <CarouselContent className="container-fluid pb-3">
                {children}
            </CarouselContent>
           
         </CarouselImg> 
         
       { images.length > 1 && <DotIndicatorWrapper className="text-center">
                <div className="mx-auto">
                 {images.map((img,index) => <DotIndicator key={img} active={index === currentImg} onClick={()=>setCurrentImg(index)} className="mx-1"/>)}
                </div>
        </DotIndicatorWrapper>}
         </>
    )
}

