import {AuthApi} from "../utils/api"
import offerIds from "../utils/offerIds"
import sanitizeHtml from "sanitize-html-react"

const {TVISION_OFFERID,
  APPLETV_REBATE_OFFERID,
  APPLE_FITNESS_P2,
  APPLE_FITNESS_P3,
  APPLE_TV_P1,
  APPLE_TV_P3,
  APPLE_TV_P4,
  YT_BG_A,
  YT_BG_B,
  YT_BG_C,
  PHILO_OFFERID,
  PARAMOUNT_OFFERID,
  AAA_OFFERID,
  OOMA_OFFERID,
  TIDAL_OFFERID,
  SIRIUS_OFFERID,
  GOOGLEHINT_OFFERID,
  SIRIUSXM_OFFERID,
  PANDORA_OFFERID,R3DSTAPLER_OFFERID,
  SIXMONTHS_OFFERID,GO5GNEXT_OFFERID} = offerIds

export const getDateAsString = (date) => new Date(date).toLocaleDateString()

export const getSubmissionStatusAsString = (submission) => {
  if (!submission.submitId) {   // this is an isubmission
    if (submission.instantValidationResult && submission.instantValidationResult === "VALID") {
      return "Approved"
    }
    if (submission.statusId !== 0 || submission.statusId !== 3) {
      if (submission.instantValidationResult && submission.instantValidationResult === "INVALID") {
        return "Denied"
      }
      return "Pending"
    }
    return "Partial"
  }

  if (submission.offerErrors.length > 0 || (submission.instantValidationResult && submission.instantValidationResult === "INVALID")) {
    return "Denied"
  }

  if (submission.debitCard && submission.debitCard.issueStatus === 2) {
    return "Sent"
  }

  if (submission.valid === true || (submission.instantValidationResult && submission.instantValidationResult === "VALID")) {
    return "Approved"
  }

  return "Received"
}

export const parseClientFaq = (faq) => {
  const faqHeaders = [...new Set(faq.map(question => question.headerText))]
  const parsedFaq = []

  faqHeaders.forEach((header) => {
    const questionArray = faq
      .filter((question => question.headerText === header))
      .sort((a, b) => a.itemDisplayOrder - b.itemDisplayOrder)

    const question = {header: header, questions: questionArray }
    parsedFaq.push(question)
  })

  return parsedFaq
    .sort((q1, q2) => q1.questions[0].headerDisplayOrder - q2.questions[0].headerDisplayOrder)
}

export const createMarkup = (text) => {
  return {__html: text}
}

export const resetDispatch = async (state, dispatch) => {
  dispatch({type: "SETISUBMISSION", payload: ""})
  const removeAll = state.files.filter((file) => file.name === "")
  dispatch({type: "SETFILES", payload: removeAll})
  dispatch({type: "SETCAMPAIGN", payload: ""})
  dispatch({type: "SETSELECTEDPHONE", payload: ""})
  dispatch({type: "SETSUBMITTED", payload: false})
  dispatch({type: "SETCHOSEMAILIN", payload: false})
}

export const validateImei = (imei) => {
  const imeiArray = imei.split("")

  const validationArray = imeiArray.map((digit, index) => {
    let parsedDigit = parseInt(digit, 10)

    if (index % 2 !== 0) {
      parsedDigit = parsedDigit * 2
    }

    if (parsedDigit > 9) {
      parsedDigit = ((parsedDigit % 10) + (parsedDigit / parsedDigit))
    }

    return parsedDigit
  })

  const validationTotal = validationArray.reduce( (accumulator, currentValue) => accumulator + currentValue)

  return validationTotal % 10 === 0
}

export const getTmoAuthUrl = (promoCode, isTFBAccount, isMyTMOFlow, isRedirectToStatusPage) => {
  if(isRedirectToStatusPage){
    if (isTFBAccount) {
      return process.env.REACT_APP_TMO_TFB_ID_AUTH_URL.replace("TMobileAuthCallback","submissionstatus")
    } else {
      if (isMyTMOFlow) {
        return process.env.REACT_APP_TMO_MYTMO_ID_AUTH_URL.replace("TMobileAuthCallback","submissionstatus")
      } else {
        return process.env.REACT_APP_TMO_ID_AUTH_URL.replace("TMobileAuthCallback", "submissionstatus")
      }
  }
}

  if (promoCode) {
    if (isTFBAccount) {
      return process.env.REACT_APP_TMO_TFB_ID_AUTH_URL.replace("=TMobileAuthCallback", `=PromoCode:${promoCode}`)
    } else {
      if (isMyTMOFlow) {
        return process.env.REACT_APP_TMO_MYTMO_ID_AUTH_URL.replace("=TMobileAuthCallback", `=PromoCode:${promoCode}`)
      } else {
        return process.env.REACT_APP_TMO_ID_AUTH_URL.replace("=TMobileAuthCallback", `=PromoCode:${promoCode}`)
      }
    }
  } else {
    if (isTFBAccount) {
      return process.env.REACT_APP_TMO_TFB_ID_AUTH_URL
    } else {
      if (isMyTMOFlow) {
        return process.env.REACT_APP_TMO_MYTMO_ID_AUTH_URL
      } else {
        return process.env.REACT_APP_TMO_ID_AUTH_URL
      }
    }
  }
}

export const validatePhone = (value) => {
  let error
  const validPhoneLengthRegEx = /(^$)|(^\d{10}$)/
  if (!value) {
    error = "Phone Number is required"
  }

  if (value && !value.replace(/[^a-z0-9]/g, "").match(validPhoneLengthRegEx)) {
    error = "Phone Number must be 10 digits"
  }

  if (value && (value.charAt(0) === "1" || value.charAt(0) === "0")) {
    error = "Phone Number cannot start with 0 or 1"
  }

  return error
}

export const isNotNullOrWhiteSpace = (str) => str && str.trim()

export const isOnPage = (page) => {
  if (window.location.pathname && window.location.pathname.length) {
    const path = window.location.pathname.toLowerCase()
    if (path.includes(page)) {
      return true
    }
  }

  return false
}

export const setDnsSetting = async (dnsObject, tMobileId) => {
  if (navigator.globalPrivacyControl ) {
    if (dnsObject.globalDoNotSellSetting === "Sell") {
      await AuthApi.setGlobalDnsSetting(tMobileId, 1)
    } else if (dnsObject.globalDoNotSellSetting === "DoNotSell" && dnsObject.globalDoNotSellSettingDate == null) {
      await AuthApi.setGlobalDnsSetting(tMobileId, 1)
    }
  }
}

export const identifyOffer = (offerId) => {
  const offersObject = {
    isAppleFitnessOffer: offerId === APPLE_FITNESS_P2 || offerId ===  APPLE_FITNESS_P3 || offerId ===  APPLE_TV_P1,
    isAppleTvOffer: offerId ===  APPLE_TV_P1,
    isTVisionOffer: offerId === TVISION_OFFERID || offerId === APPLETV_REBATE_OFFERID,
    isYouTubeBaseOffers: offerId === YT_BG_A || offerId === YT_BG_B || offerId === YT_BG_C,
    isPhiloOffer: offerId === PHILO_OFFERID,
    isParamountOffer: offerId === PARAMOUNT_OFFERID,
    isOomaOffer: offerId === OOMA_OFFERID,
    isAaaOffer: offerId === AAA_OFFERID,
    isTidalOffer: offerId === TIDAL_OFFERID,
    isSiriusOffer: offerId === SIRIUS_OFFERID,
    isGoogleHintOffer: offerId === GOOGLEHINT_OFFERID,
    isSiriusXmOffer: offerId === SIRIUSXM_OFFERID,
    isPandoraOffer: offerId === PANDORA_OFFERID,
    isCameoSpark: offerId === APPLE_TV_P3,
    isJazzInferno: offerId === APPLE_TV_P4,
    isR3dStaplerOffer: offerId === R3DSTAPLER_OFFERID || offerId ===  SIXMONTHS_OFFERID || offerId ===  GO5GNEXT_OFFERID,
    isSixMonthsOffer: offerId === SIXMONTHS_OFFERID,
    isGO5GNEXTOffer: offerId === GO5GNEXT_OFFERID
  }

  for (const key in offersObject) {
    if (offersObject[key]) {
      return {[key]: true}
    }
  }

  return {}
}

export const getOfferConfig = (offerIs) => {
  return {
    // addressVisible: !isTVisionOffer && !isAppleFitnessOffer
    addressVisible: false,
    showNewSubmissionButton: !offerIs.isTidalOffer && !offerIs.isTVisionOffer && !offerIs.isAppleFitnessOffer && !offerIs.isYouTubeBaseOffers && !offerIs.isPhiloOffer && !offerIs.isAppleTvOffer && !offerIs.isParamountOffer && !offerIs.isCameoSpark && !offerIs.isJazzInferno && !offerIs.isOomaOffer && !offerIs.isSiriusXmOffer && !offerIs.isR3dStaplerOffer
  }
}

export const pushToHistory = (history, path) => {
  history.push(path)
}

export const saniziteRawHtml = (_html) => {
  const sanitizedHtml = sanitizeHtml(_html, {
    allowedTags: [
      "div",
      "p",
      "h2",
      "h3",
      "h4",
      "a",
      "strong",
      "small",
      "b",
      "br",
      "ol",
      "ul",
      "li",
      "img",
      "span"
    ],
    allowedAttributes: {
      "a": [ "href" ],
      "div": ["style"],
      "h2": ["class"],
      "h4": ["class"],
      "img": ["src"],
      "p": ["class"],
      "span": ["style"]
    },
  })
  return sanitizedHtml
}

export const parseDateString = (dateString) => {
  const dateOnlyRegex = /^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)(-(0[1-9]|1[0-2])(-(0[1-9]|[1-2][0-9]|3[0-1])))$/

  if (dateOnlyRegex.test(dateString)) {
    const utcDate = new Date(dateString)
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000)
    return localDate  
  }
  return new Date(dateString)
}
