import React, { useState, useEffect } from "react"
import { CarouselComponent } from "../CarouselComponent"
import ImageApi from "../../utils/api/ImageApi"
import { LoadingSpinner } from "../loadingSpinner"
import { OfferApi } from "../../utils/api"
const offerId = Number.parseInt(process.env.REACT_APP_GO5GNEXT_OFFER_ID)

export const GO5GNEXTWelcomeComponent =  React.memo((props) => {
  const [landingPageMainImage, setLandingPageMainImage] = useState("")
  const [srcImage, setSrcImage] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [fileName, setFileName] = useState("main_landing_page_img")
  const [isMobileVersion, setIsMobileVersion] = useState(false)
  useEffect(() => {

      const isMobile = () => {
          const match = window.matchMedia('(pointer:coarse)')
          console.log(`${ (match && match.matches) ? 'Mobile' : 'Desktop version' }`)
          setIsMobileVersion(match && match.matches)
      }

      const retrieveStringImage = async () => {

          try {
            setIsLoading(true)  
            const resOfferCampaignDetails = await OfferApi.getOfferCampaignDetails(offerId)
            var campaignId = resOfferCampaignDetails.data.campaignId            
            if(isMobileVersion) {
              setFileName("main_landing_page_img_mob")
            }    
            if(campaignId !== null && campaignId !== 0) {          
              
              // Get Image for Landing Page
              await ImageApi.getStringImage(campaignId, fileName).then(
                (response) => {                  
                  const offerMainImageLandingPage = response    
                  setLandingPageMainImage(offerMainImageLandingPage)
                  setSrcImage(`data:image/jpg;base64,${landingPageMainImage}`)
                  setIsLoading(false)
                }
              )
            }
          } catch(error) {
            console.log(error)
          } finally {
            setIsLoading(false)
          }
        }

        isMobile()
        retrieveStringImage()      
  }, [landingPageMainImage, fileName, isMobileVersion])

return (
  isLoading ?
  <>
      <div style={{ textAlign: "center" }}>
      <LoadingSpinner className="mb-3" enabled={isLoading}></LoadingSpinner>
      <h2 className="magenta">Loading...</h2><br />
    </div>
  </>
  :
  <CarouselComponent height="100vh" bgPosition="top center" images={[`'${srcImage}'`]}>
        <div className="row">
          <div className="col mx-auto">
            <p className="text-center title-paramount"></p>
          </div>
        </div>
    </CarouselComponent>
  )
}
)