import BaseApi from "./BaseApi"

const IMAGES_ENDPOINT = "images/"

class ImageApi extends BaseApi {
    async getStringImage(offerId, imageName) {
        try {
          const endpoint = `${IMAGES_ENDPOINT}${offerId}/getbase64image/${imageName}`
          const response = await this.get(endpoint)
          return response.data
        } catch (error) {
          throw new Error("Unable to retrieve string image - ImageApi")
        }
      }

}

export default new ImageApi()
