import React from "react"
import PropTypes from "prop-types"

import { getDateAsString, isNotNullOrWhiteSpace } from "../utils/Helpers"

export const SubmissionDetailsComponent = ({submission, addressVisible}) => {

  return (
    <>
      <br />
      <h3 role='alert'>Here are the details:</h3>
      <p role='alert'>Use your tracking ID below to track the status of your submission.</p>
      <p role='alert'>
        <strong>Tracking ID: </strong>{submission.trackingId}<br />
        {addressVisible &&
          <>
            {isNotNullOrWhiteSpace(submission.firstName) && isNotNullOrWhiteSpace(submission.lastName) &&
              <>
                <strong>Name: </strong>{`${submission.firstName} ${submission.lastName}`}<br />
              </>
            }
            {isNotNullOrWhiteSpace(submission.address1) &&
              <>
                <strong>Street: </strong>{submission.address1}<br />
              </>
            }
            {isNotNullOrWhiteSpace(submission.city) &&
              <>
                <strong>City: </strong>{submission.city}<br />
              </>
            }
            {isNotNullOrWhiteSpace(submission.state) &&
              <>
                <strong>State: </strong>{submission.state}<br />
              </>
            }
            {isNotNullOrWhiteSpace(submission.postalCode) &&
              <>
                <strong>ZIP: </strong>{submission.postalCode}<br />
              </>
            }
          </>
        }
        <strong>Submission Date: </strong>{getDateAsString(submission.stampDate)}
      </p>
    </>
  )
}

SubmissionDetailsComponent.propTypes = {
  submission: PropTypes.object.isRequired,
  addressVisible: PropTypes.bool.isRequired
}
