import React from "react"
import PropTypes from "prop-types"
import {LinkButton} from "./styled"

export const PageNotFound = () => {
  return (
    <section>
      <div className="container hero3">
        <div className="row">
          <div className="col-md-12 mb-4">
            <h1 className="magenta">Promotion not Found</h1>
            <h3>Sorry, this promotion is no longer available. Please click the button below to check the status of an existing submission.</h3>
          </div>
          <div className="row">
            <div className="col-md-12 mb-4">
              <LinkButton className="ml-md-3" href={process.env.REACT_APP_TMO_ID_AUTH_URL} primary>Check Status</LinkButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
PageNotFound.propTypes = {
    isLoading: PropTypes.bool.isRequired,
  }
  