import React, {useState} from "react"
import PropTypes from "prop-types"
import {Collapse, CardBody, Card} from "reactstrap"


import {FaqComponent} from "../../components"

export const FaqContainerComponent = ({questionGroup}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const toggle = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <>
      <div onClick={toggle}>
        <span className="pointer" >{isCollapsed ? "- " : "+ "}</span>
        <span className="pointer">{questionGroup.header}</span>
      </div>
      <Collapse isOpen={isCollapsed}>
        {questionGroup.questions.map(question => {
          return (
            <Card key={question.itemText} style={{border: "none"}}>
              <CardBody className="faq-card-body">
                <FaqComponent question={question} />
              </CardBody>
            </Card>
          )
        })}
      </Collapse>
    </>
  )
}

FaqContainerComponent.propTypes = {
  questionGroup: PropTypes.object.isRequired
}
