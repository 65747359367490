import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import {
	SubmissionDetailsComponent,
	MailInThanksComponent,
} from "../components";
import { InstantValidationCodes } from "../components/Thanks/InstantValidationCodes";
import { ThankYouSection, Button } from "../components/styled";
import { AppContext } from "../components/context/AppContext";
import {
	saniziteRawHtml,
	getOfferConfig,
	pushToHistory,
	identifyOffer,
} from "../utils/Helpers";
import ReactHtmlParser from "react-html-parser"
import { ROUTE_PATHS } from "../utils/routes";
import offerIds from "../utils/offerIds";
import AnalyticsService from "../utils/AnalyticsService";
import ParagraphText from "../utils/ParagraphText";

const { R3DSTAPLER_TEXT,
	R3DSTAPLER_P_QUESTION, R3DSTAPLER_P2_BEFORE, 
	R3DSTAPLER_P3_THIS_HULU, 
	R3DSTAPLER_P4_IF_YOU_HAVE ,
	R3DSTAPLER_P5_ANNUAL,
	R3DSTAPLER_SECOND_BUTTON_TEXT } = ParagraphText

const { AAA_OFFERID } = offerIds;

export const Thanks = ({ history }) => {
	let submissionAlreadyExists = false;
	let overrideThanksText = "";
	const { state, dispatch } = useContext(AppContext);

	useEffect(() => {
		AnalyticsService.sendUniquePageview(ROUTE_PATHS.THANKS);
		dispatch({ type: "SETTHANKYOUTEXT" });
	}, [dispatch]);

	if (!history.location.state) {
		submissionAlreadyExists = false;
	} else if (history.location.state.submissionExists) {
		submissionAlreadyExists = history.location.state.submissionExists;
	}

	if (!state.campaign.crossDupeCheck) {
		state.campaign.thankYouText =
			state.campaign.dupeCheckErrorMessage +
			"<br><br><br><br> <br><br><br><br> <br><br><br><br>";
	}

	if (submissionAlreadyExists && state.campaign.secondThankYouText) {
		overrideThanksText = state.campaign.secondThankYouText;
	}

	const {
		thankYouText,
		thankYouTextTwo,
		offerFormUrl,
		offerId,
		thankYouImage,
		thankYouImageMob,
		secondThankYouText,
	} = state.campaign;

	const thankYouTextStyle = {
		maxWidth: "500px",
	};

	const offerIs = { ...identifyOffer(offerId) };
	const offerConfig = getOfferConfig(offerIs);

	const instantValidationOffer = state.iSubmission.instantValidationResult;
	const instantValidationError =
		state.iSubmission.instantValidationResult &&
		state.iSubmission.submissionErrors &&
		state.iSubmission.submissionErrors.length > 0;

	const displaySubmission = () => {
		if (!state.campaign.crossDupeCheck) {
			offerConfig.addressVisible = false;
			offerConfig.showNewSubmissionButton = false;
		} else {
			if (
				!offerIs.isOomaOffer &&
				!offerIs.isParamountOffer &&
				!offerIs.isAaaOffer &&
				!offerIs.isR3dStaplerOffer
			) {
				return (
					<SubmissionDetailsComponent
						submission={state.iSubmission}
						addressVisible={offerConfig.addressVisible}
					/>
				);
			} else {
				return null;
			}
		}
	};

	return (
		<ThankYouSection
			offerIs={offerIs}
			useThankyouImage={thankYouImage}
			useThankyouImageMob={thankYouImageMob}
		>
			<div
				className={`container-fluid ${offerIs.isTVisionOffer ? "hero2-alt" : "hero2" || offerIs.isR3dStaplerOffer ? "r3dstapler-thankyou-container" : "hero2"
					}`}
				id="announce" tabIndex="-1" autoFocus aria-live="polite"
			>
				<div className="row"
				>
					<div role="alert" className={offerIs.isR3dStaplerOffer ? "r3dstapler-container" : "hero2"} >

						{state.choseMailIn ? (
							<MailInThanksComponent offerFormUrl={offerFormUrl} />
						) : (
							<>

								<h1 role="alert"

									className={offerIs.isParamountOffer ? "p-paramount" : "p-r3dstapler "}
								>
									Thanks.
								</h1>


								{thankYouText !== "" ? (
									<p
										style={thankYouTextStyle}
									>{ReactHtmlParser(saniziteRawHtml(thankYouText))}</p>
								) : (
									"Thank you!"
								)}

								{state.campaign.crossDupeCheck &&
									instantValidationOffer &&
									!instantValidationError &&
									thankYouTextTwo &&
									overrideThanksText.length < 1 && (
										<p
											className={offerIs.isParamountOffer ? "p-paramount" : ""}
											style={thankYouTextStyle}
										>{ReactHtmlParser(saniziteRawHtml(thankYouTextTwo))}</p>
									)}
								{state.campaign.crossDupeCheck &&
									instantValidationOffer &&
									!instantValidationError &&
									overrideThanksText.length > 0 && (
										<p
											className={offerIs.isParamountOffer ? "p-paramount" : ""}
											style={thankYouTextStyle}
										>{ReactHtmlParser(saniziteRawHtml(secondThankYouText))}</p>
									)}

								{state.campaign.crossDupeCheck &&
									!instantValidationOffer &&
									thankYouTextTwo &&
									!offerIs.isAaaOffer && (
										<p
											className={offerIs.isParamountOffer ? "p-paramount" : "p-r3dstapler-d"}
											style={thankYouTextStyle}
										>{ReactHtmlParser(saniziteRawHtml(thankYouTextTwo))}</p>
									)}


								{instantValidationError && (
									<>
										<p className={offerIs.isR3dStaplerOffer ? "r3dstapler-error-text": ""} >
											<strong>
												Sorry, it appears your account is ineligible for this
												promotion. Your denial reason is:
											</strong>
											<ul>
												{state.iSubmission.submissionErrors.map((error) => (
													<li
														key={error.description || error.nonCompMessage}
													>
														<strong>
															{!instantValidationOffer
																? error.description
																: error.nonCompMessage}
														</strong>
													</li>
												))}
											</ul>
										</p>
										<br />
									</>
								)}
								<InstantValidationCodes offerIs={offerIs} history={history} />

								{displaySubmission()}

								{offerIs.isR3dStaplerOffer && instantValidationOffer &&
									!instantValidationError && (
										<>
											<div className="p-r3dstapler-d">
												<p 	><span><strong>{R3DSTAPLER_P_QUESTION} </strong></span></p>
												<p>{R3DSTAPLER_P2_BEFORE} <a
													href="https://help.hulu.com/s/article/cancel-hulu-subscription">{R3DSTAPLER_P3_THIS_HULU}</a> {R3DSTAPLER_P4_IF_YOU_HAVE} <a
														href="https://help.hulu.com/s/article/annual-billing#cancel">{R3DSTAPLER_P3_THIS_HULU}</a>{R3DSTAPLER_P5_ANNUAL}</p>
											</div>
											<p>{R3DSTAPLER_TEXT}</p>
										</>

									)}
							</>
						)}

						{offerConfig.showNewSubmissionButton ? (
							<div className="pt-3">
								<Button
									className="btn"
									onClick={() => {
										return offerId === AAA_OFFERID
											? pushToHistory(history, ROUTE_PATHS.AAA)
											: pushToHistory(history, ROUTE_PATHS.FRONTDOORFAQ);
									}}
									primary
								>
									Start new submission
								</Button>
							</div>
						) : null}


						{offerIs.isR3dStaplerOffer && instantValidationOffer &&
							!instantValidationError && (
								<div className="pt-3">
									<Button
										className="btn"
										onClick={() => {
											window.location.href = 'https://secure.hulu.com/account'
										}}
										primary
									>
										{R3DSTAPLER_SECOND_BUTTON_TEXT}
									</Button>
								</div>
							)}

						{offerIs.isGoogleHintOffer && (
							<p>Google and Nest Wifi are trademarks of Google LLC</p>
						)}
					</div>
				</div>
			</div>
		</ThankYouSection>
	);
};

Thanks.propTypes = {
	history: PropTypes.object,
};

Thanks.requiresAuth = true;
