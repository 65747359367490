import React, {useEffect} from "react"
import {Link} from "react-router-dom"

import {ROUTE_PATHS} from "../utils/routes"

import {Button} from "../components/styled"
import AnalyticsService from "../utils/AnalyticsService"

export const ContactSuccess = () => {
  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.CONTACT_SUCCESS);
  },)
  
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-4">
            <h1 className="magenta">Thank You!</h1>
            <p>Your email has been sent</p>
            <div>
              <Link to={ROUTE_PATHS.HOME}><Button primary style={{cursor: "pointer"}}>Promotions Center</Button></Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
