import React, {useState} from "react"
import PropTypes from "prop-types"
import {Collapse, CardBody, Card, CardText} from "reactstrap"

import { saniziteRawHtml } from "../../utils/Helpers"
import ReactHtmlParser from "react-html-parser"

export const FaqComponent = ({question}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const toggle = () => {
    setIsCollapsed(!isCollapsed)
  }

  const NoDetailTextCard = () => {
    return (
      <>
        <Card style={{border: "none"}}>
          <CardBody className="faq-card-body">
            <CardText className="faq-card-text">{ReactHtmlParser(saniziteRawHtml(question.itemText))}</CardText>
          </CardBody>
        </Card>
      </>
    )
  }

  const DetailTextCard = () => {
    return (
      <>
      <div onClick={toggle}>
        <span className="pointer" >{isCollapsed ? "- " : "+ "}</span>
        <span className="pointer">{question.itemText}</span>
      </div>
      <Collapse isOpen={isCollapsed}>
        <Card style={{border: "none"}}>
          <CardBody className="faq-card-body">
            <CardText className="faq-card-text">{ReactHtmlParser(saniziteRawHtml(question.detailText))}</CardText>
          </CardBody>
        </Card>
      </Collapse>
    </>
    )
  }

  return (
    <>
      {question.detailText !== null ? <DetailTextCard /> : <NoDetailTextCard /> }
    </>
  )
}

FaqComponent.propTypes = {
  question: PropTypes.object.isRequired
}
