import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../components/context/AppContext";

import {
	FoundPromotionComponent,
	TermsAndConditionsComponent,
	QuestionsAndContactComponent,
} from "../components";
// import { TermsAndConditionsImage } from "../components/styled/TermsAndConditionsImage";
import { ROUTE_PATHS } from "../utils/routes";
import { SubmissionApi } from "../utils/api";
// import { createBrowserHistory } from "history";
import AnalyticsService from "../utils/AnalyticsService";
import { LoadingSpinner } from "../components/loadingSpinner";

// const DEFAULT_CONFIG = {
// 	baseURL: process.env.REACT_APP_API_URL,
// };

export const Promotion = ({ history }) => {
	const { state, dispatch } = useContext(AppContext);
	const { campaign, selectedPhone } = state;
	const [loading, setLoading] = useState(false);

	const userPhone = selectedPhone
		? selectedPhone
		: state.lookupValues.phoneNumber;
	const carrier = state.user.Provider === '' || state.user.Provider === undefined ? 'T-MOBILE' : state.user.Provider;
	const [iSubmission, setISubmission] = useState(false);
	useEffect(() => {
		AnalyticsService.sendUniquePageview(ROUTE_PATHS.PROMOTION);
		let isMounted = true;
		setLoading(isMounted);

		const getExistingSubmission = async () => {
			var carrier = state.user.Provider;
			var userInfo = state.user.userInfo;

			if (!iSubmission){
				await SubmissionApi.validateIfExists(
					campaign.offerId,
					carrier,
					userPhone,
					userInfo
				).then((response) => {
					if (response.exists) {
						dispatch({ type: "SETISUBMISSION", payload: response.isubmission });
						setISubmission(response.isubmission);
						history.push(ROUTE_PATHS.THANKS, { submissionExists: true });
					} else {
						setLoading(false);
					}
				});
			}
		};

		
		//const history = createBrowserHistory();
		getExistingSubmission();
		return () => {
			isMounted = false;
		}; // cleanup toggles value, if unmounted
	}, [
		campaign,
		userPhone,
		carrier,
		history,
		dispatch,
		iSubmission,
		state.user.Provider,
		state.campaign.offerId,
		state.user.userInfo
	]);
	
	const ActiveContent = () => (
		<>
			<FoundPromotionComponent
				offerName={campaign.name}
				termsTitle={campaign.termsAndConditionsTitle}
			/>
			{/* <TermsAndConditionsImage
				className="img-fluid"
				data={`${DEFAULT_CONFIG.baseURL}promo-admin/image/offer/${campaign.offerId}/asset/T%26CS_IMAGE`}
				type="image/jpg"
				alt="TC Image"
				title="TC Image"
			></TermsAndConditionsImage> */}
			<TermsAndConditionsComponent
				shortPromoDescription={campaign.shortPromoDescription}
				additionalText={campaign.termsAndConditionsAdditionalText}
				terms={campaign.termsAndConditions}
				hasMailIn={campaign.offerFormUrl}
				legalFooterText={campaign.legalFooterText}
				offerId={campaign.offerId}
				isAAAWidgetOffer = {campaign.isAAAWidgetOffer}
			/>
			<QuestionsAndContactComponent showHeaderRow />
		</>
	);

	return (
		<section>
			<div className="container hero3 text-left">
				<div className="row">
					<div className="col-md-12">
						{loading ? (
							<>
								<div className="container hero3">
									<div className="row">
										<div className="col-md-12" style={{ textAlign: "center" }}>
											<LoadingSpinner
												className="mb-3"
												enabled={loading}
											></LoadingSpinner>
											<h2 className="magenta">Loading...</h2>
											<br />
										</div>
									</div>
								</div>
							</>
						) : (
							ActiveContent()
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

Promotion.requiresAuth = true;
