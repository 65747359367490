import ReactGA from 'react-ga'

class AnalyticsService {

  sendEvent = (category, action, label, gtmObject) => {
    ReactGA.event({
      category,
      action,
      label
    })
    window.dataLayer.push(gtmObject)
  }

  sendGa4Event = (gtmObject) => {
    window.dataLayer.push(gtmObject)
  }
  
  sendUniquePageview = (page) => {
    const dataLayer = window.dataLayer

    // Record unique pageviews in an array
    let uniquePageviews = window.sessionStorage.getItem("unique-pageviews");
    if (uniquePageviews) {
      uniquePageviews = JSON.parse(uniquePageviews);
    } else {
      uniquePageviews = [];
    }
  
    // Don't continue if the current page has already been viewed in this session
    if (uniquePageviews.indexOf(page) !== -1) {
      return;
    }
  
    // Track a pageview for a newly visited URL in this session
    uniquePageviews.push(page);
    uniquePageviews = JSON.stringify(uniquePageviews);
    window.sessionStorage.setItem("unique-pageviews", uniquePageviews);
  
    // Send "unique_page_view" event to GA4
    const properties = {
      event: "unique_page_view",
      page_location: page,
    };
    dataLayer.push(properties); 
  }
  
}

export default new AnalyticsService()
