import React from "react"

export const FirstRespondersQuestionsAndContactComponent = () => {
  return (
    <>
    <p>Questions before you continue? To find answers <a href="https://www.t-mobile.com/cell-phone-plans/first-responder-discounts">click here</a>.</p>
    </>
  )
}

