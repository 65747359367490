import * as Yup from "yup"

export const states = [
  {
    "name": "Alabama",
    "abbreviation": "AL"
  },
  {
    "name": "Alaska",
    "abbreviation": "AK"
  },
  {
    "name": "Arizona",
    "abbreviation": "AZ"
  },
  {
    "name": "Arkansas",
    "abbreviation": "AR"
  },
  {
    "name": "California",
    "abbreviation": "CA"
  },
  {
    "name": "Colorado",
    "abbreviation": "CO"
  },
  {
    "name": "Connecticut",
    "abbreviation": "CT"
  },
  {
    "name": "Delaware",
    "abbreviation": "DE"
  },
  {
    "name": "District Of Columbia",
    "abbreviation": "DC"
  },
  {
    "name": "Florida",
    "abbreviation": "FL"
  },
  {
    "name": "Georgia",
    "abbreviation": "GA"
  },
  {
    "name": "Hawaii",
    "abbreviation": "HI"
  },
  {
    "name": "Idaho",
    "abbreviation": "ID"
  },
  {
    "name": "Illinois",
    "abbreviation": "IL"
  },
  {
    "name": "Indiana",
    "abbreviation": "IN"
  },
  {
    "name": "Iowa",
    "abbreviation": "IA"
  },
  {
    "name": "Kansas",
    "abbreviation": "KS"
  },
  {
    "name": "Kentucky",
    "abbreviation": "KY"
  },
  {
    "name": "Louisiana",
    "abbreviation": "LA"
  },
  {
    "name": "Maine",
    "abbreviation": "ME"
  },
  {
    "name": "Maryland",
    "abbreviation": "MD"
  },
  {
    "name": "Massachusetts",
    "abbreviation": "MA"
  },
  {
    "name": "Michigan",
    "abbreviation": "MI"
  },
  {
    "name": "Minnesota",
    "abbreviation": "MN"
  },
  {
    "name": "Mississippi",
    "abbreviation": "MS"
  },
  {
    "name": "Missouri",
    "abbreviation": "MO"
  },
  {
    "name": "Montana",
    "abbreviation": "MT"
  },
  {
    "name": "Nebraska",
    "abbreviation": "NE"
  },
  {
    "name": "Nevada",
    "abbreviation": "NV"
  },
  {
    "name": "New Hampshire",
    "abbreviation": "NH"
  },
  {
    "name": "New Jersey",
    "abbreviation": "NJ"
  },
  {
    "name": "New Mexico",
    "abbreviation": "NM"
  },
  {
    "name": "New York",
    "abbreviation": "NY"
  },
  {
    "name": "North Carolina",
    "abbreviation": "NC"
  },
  {
    "name": "North Dakota",
    "abbreviation": "ND"
  },
  {
    "name": "Ohio",
    "abbreviation": "OH"
  },
  {
    "name": "Oklahoma",
    "abbreviation": "OK"
  },
  {
    "name": "Oregon",
    "abbreviation": "OR"
  },
  {
    "name": "Pennsylvania",
    "abbreviation": "PA"
  },
  {
    "name": "Puerto Rico",
    "abbreviation": "PR"
  },
  {
    "name": "Rhode Island",
    "abbreviation": "RI"
  },
  {
    "name": "South Carolina",
    "abbreviation": "SC"
  },
  {
    "name": "South Dakota",
    "abbreviation": "SD"
  },
  {
    "name": "Tennessee",
    "abbreviation": "TN"
  },
  {
    "name": "Texas",
    "abbreviation": "TX"
  },
  {
    "name": "Utah",
    "abbreviation": "UT"
  },
  {
    "name": "Vermont",
    "abbreviation": "VT"
  },
  {
    "name": "Virginia",
    "abbreviation": "VA"
  },
  {
    "name": "Virgin Islands",
    "abbreviation": "VI"
  },
  {
    "name": "Washington",
    "abbreviation": "WA"
  },
  {
    "name": "West Virginia",
    "abbreviation": "WV"
  },
  {
    "name": "Wisconsin",
    "abbreviation": "WI"
  },
  {
    "name": "Wyoming",
    "abbreviation": "WY"
  }
]

export const contactReasons = [
  "Status of Request",
  "Terms and Conditions of Offer",
  "Unqualified Letter Received",
  "Other"
]

export const handleSubmissionError = (setSubmitting, setStatus, error = "There was a problem. Please try again.") => {
  setStatus({formError: error})
  setSubmitting(false)
}

export const handleSubmissionErrorAAA = (setSubmitting, setStatus, error = "Oops, something went wrong. Please try again later, if you continue having problems, please contact customer care 1-800-937-8997 or dial 611 from your mobile phone.") => {
  setStatus({formError: error})
  setSubmitting(false)
}

export const addOptionalRegistrationSchema = (fields,COSTCO_OFFER_ID) => {
  let validationObject = {}

  if (fields) {
      const validAddressRegEx = /([a-zA-Z0-9!@#$%^&*)(+=._-]+\s+[a-zA-Z0-9]+.*$)/
      const validNameRegex = /[A-Za-z]+((\s)?(('|-|\.)?([A-Za-z])+[^|]*))*$/
      const validPostalCodeRegEx = /\d{5}/
      const pipeNotAllowedRegex = /(^[^\\|]*$)/
      const symbolsNotAllowed = /^[^-!@#$%,&*`()_+|~={}[\]'/\\|]*$/
      const asciiNotAllowed = /^[^€‚ƒ„…†‡ˆ‰‹ŒŽ‘’“”•–—˜™š›œžŸ¡¢£¤¥¦§¨©ª«¬®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ]*$/

      if (fields.some(field => field.fieldName === "firstName")){
        validationObject.firstName = Yup
          .string()
          .required("First Name is required.")
          .min(2, "First Name must have at least 2 letters.")
          .max(25, "First Name cannot be greater than 25 letters.")
          .matches(validNameRegex, "First Name is invalid.")
          .matches(symbolsNotAllowed, "The First Name has an invalid character.")
          .matches(asciiNotAllowed, "The First Name has an invalid character.")
      }

      if (fields.some(field => field.fieldName === "lastName")){
         validationObject.lastName = Yup
        .string()
        .required("Last Name is required.")
        .min(2, "Last Name must have at least 2 letters.")
        .max(25, "Last Name cannot be greater than 25 letters.")
        .matches(validNameRegex, "Last Name is invalid.")
        .matches(symbolsNotAllowed, "The Last Name has an invalid character.")
        .matches(asciiNotAllowed, "The Last Name has an invalid character.")
      }

      if (fields.some(field => field.fieldName === "email")){
        validationObject.email1 = Yup
          .string()
          .max(255, "Email cannot be greater than 255 characters.")
          .matches(pipeNotAllowedRegex, "The email has an invalid character.")
          .email("Email is invalid.")
      }

      let addressOneField = fields.filter(field => field.fieldName === "address")[0]
      let addressTwoField = fields.filter(field => field.fieldName === "address2")[0]
      if (addressOneField && addressTwoField) {
        validationObject.address1 = Yup
          .string()
          .required("Address is required.")
          .max(50, "Address cannot be greater than 50 letters.")
          .matches(validAddressRegEx, "Address must start with a street number or PO Box.")
          .matches(symbolsNotAllowed, "The address has an invalid character.")
          .matches(asciiNotAllowed, "The address has an invalid character.")
        validationObject.address2 = Yup
          .string()
          .max(50, "Address cannot be greater than 50 letters.")
          .matches(pipeNotAllowedRegex, "The Apt./Suite has an invalid character.")
          .matches(symbolsNotAllowed, "The Apt./Suite has an invalid character.")
          .matches(asciiNotAllowed, "The Apt./Suite has an invalid character.")
      }
  

      let cityField = fields.filter(field => field.fieldName === "city")[0]
      // let stateField = fields.filter(field => field.fieldName === "state")[0]
      let postalCodeField = fields.filter(field => field.fieldName === "zipCode")[0]

      if (cityField) {
        validationObject.city = Yup
          .string()
          .required("City is required.")
          .min(2, "City is invalid.")
          .max(30, "City cannot be greater than 30 letters.")
          .matches(validNameRegex, "City is invalid.")
          .matches(pipeNotAllowedRegex, "The city has an invalid character.")
          .matches(symbolsNotAllowed, "The City has an invalid character.")
          .matches(asciiNotAllowed, "The City has an invalid character.")
          // State is handled by a validateState function
      }

      if (postalCodeField) {
        validationObject.postalCode = Yup
          .string()
          .required("Zip Code is required.")
          .min(5, "Zip Code must be at least five digits.")
          .max(5, "Zip Code must be at most five digits.")
          .matches(validPostalCodeRegEx, "Zip Code is invalid.")
          .matches(pipeNotAllowedRegex, "The address has an invalid character.")
      }

      let costcoMemberIdField = fields.filter(field => field.fieldName === "costcoMemberId")[0]

      if (costcoMemberIdField) {
        const onlyNumbersRegex = /^[0-9]*$/
          if (COSTCO_OFFER_ID) {
            validationObject.costcoMemberId = Yup
            .string()
            .required("Costco Member ID is required.")
            .matches(onlyNumbersRegex, "Costco Member ID has an invalid character.")
            .matches(/^\d{12}$/, "Costco Member ID is invalid.")
            
          }
          else{
            validationObject.costcoMemberId = Yup
            .string()
            .required("Costco Member ID is required.")
            .min(12, "Costco Member ID is invalid.")
            .matches(onlyNumbersRegex, "Costco Member ID is invalid.")
            .matches(pipeNotAllowedRegex, "Costco Member ID has an invalid character.")
          }
                   // State is handled by a validateState function
      }

      let samsMemberNumberField = fields.filter(field => field.fieldName === "samsMemberNumber")[0]

      //Sams Club Membership ID should start with 101 / 69
      if (samsMemberNumberField) {
        const onlyNumbersRegex = /^[0-9]*$/
        const startsWith101or69 = /^(?=101|69)\d+$/
        validationObject.samsMemberNumber = Yup
          .string()
          .required("Sam's Club Membership Number is required.")
          .matches(onlyNumbersRegex, "Sam's Club Membership Number is invalid.")
          .min(17, "Sam's Club Membership Number must be at least seventeen digits.")
          .max(17, "Sam's Club Membership Number must be at most seventeen digits.")
          .matches(pipeNotAllowedRegex, "Sam's Club Membership Number has an invalid character.")
          .matches(startsWith101or69, "Sam's Club Membership Number should start with 101 or 69.")
      }

      let confirmEmailField = fields.filter(field => field.fieldName === "confirmEmail")[0]
      if (confirmEmailField){
        validationObject.confirmEmail1 = Yup
          .string()
          .oneOf([Yup.ref('email1'), null], 'Confirm email must match the provided email.')
          .required('Confirm Email is required.')        
      }

      let companyField = fields.filter(field => field.fieldName === "company")[0]
      if (companyField){
        validationObject.company = Yup
          .string()
          .max(50, "Company name cannot be greater than 50 letters.")
          .required('Company name is required.')
      }

      let rcptDateField = fields.filter(field => field.fieldName === "rcptDate")[0]
      if (rcptDateField){
        validationObject.rcptDate = Yup
          .date()
          .required('Purchase Date is required.')
      }

      let etfAmount1field = fields.filter(field => field.fieldName === "etfAmount1")[0]
      if (etfAmount1field){
        validationObject.etfAmount1 = Yup
          .number()
          .min(0, "Must be between 0 and 2000")
          .max(2000, "Must be between 0 and 2000")
          .required('Purchase Price is required.')
      }

    const optionalFields = fields.filter(field => field.default === false && field.visible === true)
    if (optionalFields.length > 0) {
      const imei2FieldVisible = optionalFields.find(field => field.fieldName === "imei2") !== undefined

      const noMatchSchema = Yup
        .string()
        .notOneOf([Yup.ref("imei"), null], "The first and second purchased devices cannot have the same IMEI info. Please re-enter.")

      optionalFields.forEach(field => {
        switch (field.fieldName) {
        case "imei":
          validationObject.imei = Yup
            .string()
            .required(imei2FieldVisible
              ? "15-Digit IMEI # of First Purchased Device is required."
              : "15-Digit IMEI # of Purchased Device is required.")
            .min(15,imei2FieldVisible
              ? "15-Digit IMEI # of First Purchased Device is invalid."
              : "15-Digit IMEI # of Purchased Device is invalid.")
            .max(15, imei2FieldVisible
              ? "15-Digit IMEI # of First Purchased Device is invalid."
              : "15-Digit IMEI # of Purchased Device is invalid.")
              .matches(pipeNotAllowedRegex, "The IMEI # has an invalid character.")
          validationObject.confirmImei = Yup
            .string()
            .oneOf([Yup.ref("imei"), null],
              imei2FieldVisible
                ? "The entered 15-Digit IMEI # of First Purchased Device and Confirm First IMEI # info does not match. Please re-enter."
                : "The entered 15-digit IMEI and Confirm 15-digit IMEI info does not match. Please re-enter.")
            .required(imei2FieldVisible ? "Confirm First IMEI # is required." : "Confirm IMEI # is required.")
            .matches(pipeNotAllowedRegex, "The IMEI # has an invalid character.")
          break
        case "imei2":
          validationObject.imei2 = Yup
            .string()
            .required("15-Digit IMEI # of Second Purchased Device is required.")
            .min(15, "15-Digit IMEI # of Second Purchased Device is invalid.")
            .max(15, "15-Digit IMEI # of Second Purchased Device is invalid.")
            .concat(imei2FieldVisible ? noMatchSchema : null)
          validationObject.confirmImei2 = Yup
            .string()
            .oneOf([Yup.ref("imei2"), null], "The entered 15-Digit IMEI # of Second Purchased Device and Confirm Second IMEI # info does not match. Please re-enter. ")
            .required("Confirm Second IMEI # is required")
          break
        case "dealerCode":
          validationObject.dealerCode = Yup
            .string()
            .required("Dealer Code is required")
          break
        case "telephone1":
          const validPhoneLengthRegEx = /(^$)|(^\d{10}$)/
          const notStartInZeroOrOneRegex = /^(?!0|1)\d+$/
          const onlyNumbersRegex = /^[0-9]*$/
          validationObject.telephone1 = Yup
            .string()
            .matches(onlyNumbersRegex, "Please enter a valid phone number consisting of numerics only")
            .matches(validPhoneLengthRegEx, "Phone Number must be 10 digits")
            .matches(notStartInZeroOrOneRegex, "Phone Number cannot start with 0 or 1")
            .required("Phone Number is required")
          break
        case "MSISDN":
          validationObject.MSISDN = Yup
            .string()
            .required("T-Mobile phone number is required")
          break
        default:
          break
        }
      })
    }
  }

  return Yup.object().shape(validationObject)
}

export const addOptionalReCaptchaSchema = (validationObject, recaptchaVisible) => {
  if (recaptchaVisible) {
    validationObject.recaptcha = Yup.string().nullable().required("Please check the captcha")
  }

  return Yup.object().shape(validationObject)
}

export const addSchemaValidation = () => {
  const validationSchema = Yup.object().shape({
    transactionDate: Yup.string().required("Transaction date field is required"),
    faq1: Yup.string().required("Channel of your Transaction field is required"),
    faq2: Yup.string().required("Transaction Type field is required")
  });
  return validationSchema
}

export const validateDisableButtonTfb = (error) => {
  if (error === undefined) {
     return false;
 } else {
     return true;
 }
} 



