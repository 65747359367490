import React from "react"
import {PropTypes} from "prop-types"
import {Link} from "react-router-dom"

import {Button} from "./styled"

import { saniziteRawHtml } from "../utils/Helpers"
import ReactHtmlParser from "react-html-parser"
import {ROUTE_PATHS} from "../utils/routes"
import { TextBoxWrapper } from "./styled/ScrollableTextBox"
import { AAAWidget } from "./AAAPromo"

export const TermsAndConditionsComponent = ({shortPromoDescription,terms, hasMailIn, legalFooterText, additionalText, offerId, isAAAWidgetOffer}) => {
  const nextPage = hasMailIn ? ROUTE_PATHS.SUBMISSION_METHOD : ROUTE_PATHS.REGISTER
  const legalFooter = legalFooterText ? legalFooterText : "By proceeding, I verify I have read and agree to the Terms & Conditions of this promotion."
  const hasHtmlTagP = terms.indexOf('</p>') > -1 ? true : false;

  return (
    <>
      {shortPromoDescription &&  <div>{ReactHtmlParser(saniziteRawHtml(shortPromoDescription))}</div>}
      {additionalText && <h3 className="mb-1 mt-3">{additionalText}</h3>}
      <p className="mb-1 mt-3"><strong>Promotion Terms</strong></p>
      <TextBoxWrapper maxLines={4} className="mb-4">
        {!hasHtmlTagP &&
        <p>{ReactHtmlParser(saniziteRawHtml(terms))}</p>}
        {hasHtmlTagP &&
        ReactHtmlParser(saniziteRawHtml(terms))}
      </TextBoxWrapper>
      {  isAAAWidgetOffer ? <AAAWidget></AAAWidget>
        :  <Link to={nextPage} ><Button style={{cursor: "pointer"}} className="btn agree-to-terms" id="btnSubmit" primary>Continue</Button></Link>
      }
      <p className="small">{legalFooter}</p>
    </>
  )
}

TermsAndConditionsComponent.propTypes = {
  terms: PropTypes.string.isRequired,
  hasMailIn: PropTypes.string,
  legalFooterText: PropTypes.string,
  additionalText: PropTypes.string
}
