import React from "react"
import { CarouselComponent } from "../CarouselComponent"

export const FirstRespondersWelcomeComponent = () => {
  return (
      <CarouselComponent  bgPosition="0px -50px;" images={["/images/FirstResponders/landing_desktop.jpg"]}>
          <div className="row">
            <div className="col mx-auto">
              <h1 className="text-center">First Responder Verification</h1>
            </div>
        </div>
      </CarouselComponent>
  )
}
