import React from "react"
import PropTypes from "prop-types"

import { LinkButton, ErrorMessage } from "../styled"
import { FirstRespondersQuestionsAndContactComponent } from "../index"
import { getTmoAuthUrl } from "../../utils/Helpers"

export const FirstRespondersGetStartedComponent = ({errorMessage}) => {
  const tmoAuthUrl = getTmoAuthUrl("FIRSTRESPONDERS2019", false)

  return (
    <section>
      <div className="container py-5 hero3">
        <div className="row text-center">
          <div className="col">
            <h3 className="magenta text-center">Verify today to confirm your eligibility and keep your Magenta First Responder plan</h3>
            <p>If you are a T-Mobile for Business customer, please "<a href={process.env.REACT_APP_TMO_TFB_ID_AUTH_URL} rel="noreferrer">click here to login</a>".</p>
            <p>Bringing a phone to T-Mobile? Please wait until your number is active on our network before continuing. The temporary number assigned to you at activation is not compatible with our promotions.</p>
            <p>Current customer with a Magenta First Responder plan being asked to re-verify? You can also do that here to continue to keep your current plan.</p>
          </div>
        </div>
        <div className="row text-center">
          <div className="mx-auto col-md-10">
            <div>
              <LinkButton className="btn" id="btnSubmit" href={tmoAuthUrl} primary>Continue</LinkButton>
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage> }
            </div>
          </div>
        </div>
        <div className="text-center">   
          <FirstRespondersQuestionsAndContactComponent />
          </div>
        <div className="row text-center">
          <div className="col">
            If you are the child, parent, or spouse of a first responder killed in the line of duty, please dial 611 to speak directly with a TEX expert, who will be able to assist with the verification process.
          </div>
        </div>
      </div>
    </section>
  )
}

FirstRespondersGetStartedComponent.propTypes = {
  errorMessage: PropTypes.string,
  offerId: PropTypes.number
}
