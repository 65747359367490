import React, {useEffect} from "react"
import PropTypes from "prop-types"

const openAndPostDnsForm = () => {
  const dnsDomain = process.env.REACT_APP_TMO_DNS_PAGE
  const dnsForm = document.createElement("form")
  const winWidth = 800
  const winHeight = 800

  const localDnsValue = getLocalDnsValue()

  dnsForm.target = "DoNotSell"
  dnsForm.method = "post"
  dnsForm.action = dnsDomain

  const inputs = createAndPopulateFormInputs(localDnsValue)

  inputs.forEach(function(input) {
    dnsForm.appendChild(input)
  })

  document.body.appendChild(dnsForm)

  window.open(dnsDomain, dnsForm.target, winWidth, winHeight, true, window.scrollbars)
  dnsForm.submit()
}

const getLocalDnsValue = () => {
  const allCookies = document.cookie
  if (!allCookies
    .split(";")
    .filter(function(cookie) {
      return cookie.trim().indexOf("dnt=") === 0
    }).length) {
    return "Sell" // no cookie equals "Sell"
  }

  let localDnsCookie = allCookies
    .split(";")
    .filter(function(cookie) {
      return cookie.trim().indexOf("dnt=") === 0
    })[0]

  return localDnsCookie
    .substring(localDnsCookie.indexOf("=") + 1, localDnsCookie.length) === "1"
    ? "DoNotSell"
    : "Sell"
}

const createAndPopulateFormInputs = (localDnsValue) => {
  const localDnsInput = document.createElement("input")
  localDnsInput.type = "hidden"
  localDnsInput.name = "LocaldoNotSellSetting"
  localDnsInput.value = localDnsValue

  const siteInput = document.createElement("input")
  siteInput.type = "hidden"
  siteInput.name = "Site"
  siteInput.value = "Sell_Web"

  const originInput = document.createElement("input")
  originInput.type = "hidden"
  originInput.name = "Origin_URL"
  originInput.value = window.location.origin

  const brandInput = document.createElement("input")
  brandInput.type = "hidden"
  brandInput.name = "Brand"
  brandInput.value = "Magenta"

  return [localDnsInput, siteInput, originInput, brandInput]
}

const addUnavEventListener = () => {
  const unavFooter = document.querySelector("tmo-digital-footer")
  if (unavFooter) {
    unavFooter.addEventListener("digitalUnavEvent",
      (event) => {
        if (event.detail && event.detail.toLowerCase() !== "opendnspage") {
          return
        }

        openAndPostDnsForm()
      })
  }
}

export const CcpaComponent = ({children}) => {

  useEffect(() => {
    addUnavEventListener()
  }, [])

  return (
    <>
      {children}
    </>
  )
}

CcpaComponent.propTypes = {
  children: PropTypes.element.isRequired
}
