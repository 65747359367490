import BaseApi from "./BaseApi"

const EMAIL_ENDPOINT = "/email"

class EmailApi extends BaseApi {
  async sendContactEmail(contactValues) {
    try {
      return await this.post(`${EMAIL_ENDPOINT}/contact`, contactValues )
    } catch (error) {
      throw new Error("Unable to send contact details")
    }
  }
}

export default new EmailApi()
