import styled, { css } from "styled-components"

export const Button = styled.button`
font-size: 1rem;
padding: 8px 20px 8px 20px;
min-width: 120px;
width: 250px;
margin-bottom: 20px;

@media (min-width: 768px)  {
  width: auto;
  margin-bottom: 30px
}

${props => props.primary && css`
    background: #e20074 ;
    color: #fff;
    border: none !important;
    border-radius: 0px;
    background-color: #e20074
  `}

  ${props => props.secondary && css`
    color: #e20074;
    background-color: transparent;
    border: 1px solid #e20074;
    border-radius: 0px
`}

`
