import React from "react"
import { CarouselComponent } from "../CarouselComponent"

export const OomaWelcomeComponent = () => {
  return (
    <CarouselComponent height="100vh" bgPosition="top center" images={["/images/Ooma/landing_desktop.jpg"]}>
        <div className="row">
          <div className="col mx-auto">
            <p className="text-center title-paramount">Redeem your home phone offer from Ooma and get a free device!</p>
          </div>
        </div>
    </CarouselComponent>
  )
}
