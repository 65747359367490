import React, {useContext,useState} from "react"
import PropTypes from "prop-types"

import {Button, ErrorMessage} from "../styled"
import { FileInput, Checkbox } from "./index"
import { PrivacyPolicyComponent } from "../index"
import {AppContext} from "../../components/context/AppContext"

import { saniziteRawHtml } from "../../utils/Helpers"
import ReactHtmlParser from "react-html-parser"

export const UploadForm = ({instructions, handleSubmit, isSubmitting, errorMessage, uploadConfirmation}) => {
  const { state } = useContext(AppContext)
  const [checked, setChecked] = useState(false)

  const handleChange = () => {
    setChecked((current) => !current)
  }

  return (
    <div>
      <div>{ReactHtmlParser(saniziteRawHtml(instructions))}</div>
      <FileInput />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <div className="div-pad-bottom">
        <Checkbox id="uploadConfirm" label={uploadConfirmation} onClick={handleChange}/></div>
      <div className="btn-upload">
        <Button className="btn" id="btnSubmit" primary onClick={handleSubmit}
          disabled={isSubmitting || state.files.length === 0 || !checked}>
          {isSubmitting ? "Uploading" : "Submit"}</Button>
      </div>
      <PrivacyPolicyComponent />
    </div>
  )
}

UploadForm.propTypes = {
  instructions: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  uploadConfirmation: PropTypes.string.isRequired
}
