import styled from "styled-components"

const getBorderColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676"
  }
  if (props.isDragReject) {
    return "#ff1744"
  }
  if (props.isDragActive) {
    return "2196f3"
  }

  return "#eeeeee"
}

export const Dropzone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getBorderColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`
export const ThumbnailContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16;
`

export const Thumbnail = styled.div`
  display: inline-flex;
  border-radius: 2;
  border: 1px solid #eaeaea;
  margin-bottom: 8;
  margin-right: 8;
  width: 100;
  height: 100;
  padding: 4;
  box-sizing: border-box;
`

export const ThumbNailInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`

export const ThumbnailImage = styled.img`
  display: block;
  width: auto;
  height: 100%
`
