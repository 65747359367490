import React, {useContext} from "react"
import {AppContext} from "../context/AppContext"
import {SubmissionButton} from "./SubmissionButton"
import {LinkButton} from "../styled"
import PropTypes from "prop-types"

export const InstantValidationCodes = ({offerIs, history}) => {
  const {state} = useContext(AppContext)
  const {offerId} = state.campaign
  const instantValidationOffer = state.iSubmission.instantValidationResult
  const AAA_REWARD_URL = process.env.REACT_APP_AAA_REWARD_REDIRECT_URL
  const instantValidationError = state.iSubmission.instantValidationResult && state.iSubmission.submissionErrors && state.iSubmission.submissionErrors.length > 0
  return <>
    {state.iSubmission.instantValidationResult && state.iSubmission.instantValidationCodes && ![268651, 268637, 268590, 268683, 268772].includes(offerId) &&
      <div>
        {/* {!offerIs.isParamountOffer ? <strong>Reward codes:</strong> : null} */}
        <br />
        {state.iSubmission.instantValidationCodes.map(code =>
          <div key={code.instantCodeValue} style={{ marginTop: "1.5rem" }}>
            <LinkButton role="alert" primary className={offerIs.isParamountOffer ? "btn-paramount" : ""} target="_blank" href={code.instantCodeValue}>
              {!offerIs.isParamountOffer ? "Redeem Now" : "New Paramount+ subscriber? Redeem here."}
            </LinkButton>
          </div>
        )}
        <br />
      </div>
    }
    {(offerId === 268637 && state.campaign.crossDupeCheck) && instantValidationOffer !== "INVALID" &&
      <div>
        {state.iSubmission.instantValidationCodes.map(code =>
          <div key={code} style={{ marginTop: "1.5rem" }}>
            <LinkButton primary className="btn" target="_blank" href={AAA_REWARD_URL + code.instantCodeValue}>
              Redeem Now
            </LinkButton>
          </div>)}
      </div>
    }
    {offerId === 268651 && !instantValidationError &&
      <div>
        {state.iSubmission.instantValidationCodes.map(code =>
          <div key={code.instantCodeValue} style={{ marginTop: "1.5rem" }}>
            <LinkButton role="alert" primary className={offerIs.isParamountOffer ? "btn-paramount" : ""} target="_blank" href={"https://tv.youtube.com/account_settings/promo_code?coupon_code=" + code.instantCodeValue} >
              {"Existing YouTube TV Subscriber? Redeem Here"}
            </LinkButton>
          </div>
        )}
      </div>
    }
    {offerId === 268772 && !instantValidationError &&
      <div>
        {state.iSubmission.instantValidationCodes.map(code =>
          <div key={code.instantCodeValue} style={{ marginTop: "1.5rem" }}>
            <LinkButton role="alert" primary className="btn" target="_blank" href={code.instantCodeValue}>
              Redeem Now
            </LinkButton>
          </div>
        )}
      </div>
    }
    {offerId === 268651 && !instantValidationError &&
      <div>
        {state.iSubmission.instantValidationCodes.map(code =>
          <div key={code.instantCodeValue} style={{ marginTop: "1.5rem" }}>
            <LinkButton role="alert" primary className={offerIs.isParamountOffer ? "btn-paramount" : ""} href={"https://tv.youtube.com/tmo_uncarrier?coupon_code=" + code.instantCodeValue} target="_blank">
              {"New YouTube TV Subscriber? Redeem Here"}
            </LinkButton>
            <p className="p-paramount">
              Apply this code:
              <b style={{ paddingRight: 2 }}>{code.instantCodeValue ? code.instantCodeValue : ""}</b>
            </p>
          </div>
        )}
        <br />
      </div>
    }

    {offerId === 268590 && !instantValidationError && state.iSubmission.instantValidationCodes &&
      <div>
        {state.iSubmission.instantValidationCodes.map(code =>
          <div key={code.instantCodeValue} style={{ marginTop: "1.5rem" }}>
            <LinkButton role="alert" primary className={offerIs.isParamountOffer ? "btn-paramount" : ""} href={"https://tv.youtube.com/welcome/partner/tmobile/?utm_source=tmobile&ac=3GAY4SPBZFDX&coupon_code=" + code.instantCodeValue.split("=")[3]} target="_blank">
              {"New YouTube TV Subscriber? Redeem Here"}
            </LinkButton>
            <p className="p-paramount">
              Apply this code:
              <b style={{ paddingRight: 2 }}>{code.instantCodeValue ? code.instantCodeValue.split("=")[3] : ""}</b>
            </p>
          </div>
        )}
        <br />
      </div>
    }

    {offerId === 268590 && !instantValidationError && state.iSubmission.instantValidationCodes &&
      <div>
        {state.iSubmission.instantValidationCodes.map(code =>
          <div key={code.instantCodeValue} style={{ marginTop: "1.5rem" }}>
            <LinkButton role="alert" primary className={offerIs.isParamountOffer ? "btn-paramount" : ""} target="_blank" href={"https://tv.youtube.com/tmo_uncarrier?coupon_code=" + code.instantCodeValue.split("=")[3]} >
              {"Existing YouTube TV Subscriber? Redeem Here"}
            </LinkButton>
          </div>
        )}
      </div>
    }

    <SubmissionButton offerIs={offerIs} history={history} />

    {offerIs.isParamountOffer &&
      state.iSubmission.instantValidationCodes &&
      state.iSubmission.instantValidationCodes.map(code =>
        <>
          <LinkButton className="btn-paramount" href="https://www.paramountplus.com/account" target="_blank" primary>Existing Paramount+ Essential monthly subscriber? Use code below and Redeem here.</LinkButton>
          <p className="p-paramount">
            Apply this code:
            <br />
            <b>{code.instantCodeValue ? code.instantCodeValue.split("=")[1] : ""}</b>
            <br />
            in your account under "Redeem a Coupon"
          </p>

          <p className="p-paramount">
            <b>All other existing Paramount+ subscribers, <br />click <a href="https://www.t-mobile.com/offers/paramount-plus-deal#paramount-plus-faq" target="_blank" rel="noopener noreferrer">here</a> before redeeming offer.</b>
          </p>
        </>
      )
    }

    {
      offerIs.isGoogleHintOffer && !instantValidationError &&
      <div>
        {state.iSubmission.instantValidationCodes.map(code =>
          <div key={code.instantCodeValue} style={{ marginTop: "1.5rem" }}>
            <LinkButton role="alert" primary target="_blank" href={code.instantCodeValue} >
              Redeem Now
            </LinkButton>
          </div>
        )}
      </div>
    }
  </>

}

InstantValidationCodes.propTypes = {
  offerIs: PropTypes.object,
  history: PropTypes.object
}
