import React from "react"
import PropTypes from "prop-types"

import {ErrorMessage } from "../styled"

export const TextInput = ({
  field,
  form: {touched, errors},
  ...props
}) => {
  return (
    <>
      <input
        type={props.type} {...field} {...props}
        className="form-control"
        placeholder={props.placeholder}
        disabled={props.disabled}
        aria-label={props.arialabel}
        aria-describedby="text-errors"/>
      {touched[field.name] && errors[field.name] && <div role="alert"><ErrorMessage id="text-errors">{errors[field.name]}</ErrorMessage></div>}
    </>
  )
}

TextInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  arialabel: PropTypes.string
}
