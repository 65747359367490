import React, { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import "./LoadingSpinner.css"

export class LoadingSpinner extends Component {
  static propTypes = {
    enabled: PropTypes.bool,

    className: PropTypes.string
  }

  static defaultProps = {
    enabled: true
  }


  render() {
    const { enabled, className, ...props} = this.props

    if (!enabled) {
      return null
    }

    return (
      <div className={
        classnames({
          "loader": true,
          [className]: true
        })
      } {...props} >
        <svg version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="80px"
          height="80px"
          viewBox="0 0 50 50"
          style={{
            enableBackground: "new 0 0 50 50"
          }}>
          <path fill="#000"
            d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
          </path>
        </svg>
      </div>
    )
  }
}
