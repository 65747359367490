import React from "react"

import { CrossCompanyPhoneCheck } from "../auth/CrossCompanyPhoneCheck"
import { Button } from "../styled/Button"
import { saniziteRawHtml } from "../../utils/Helpers"
import ReactHtmlParser from "react-html-parser"

const SAMSCLUB_CODE = "240003";

export const SamsClubGetStartedComponent = (props) => {

  const initialValues = {
    phoneNumber: ""
  }

  const getButton = () => ({ disabled, handleClick }) => {
    return (
      <>
        <Button
          type="button"
          className="btn"
          primary
          disabled={disabled}
          onClick={() => {
            sessionStorage.setItem("promoCode", SAMSCLUB_CODE);
            handleClick()
          }}
        >
          Continue
        </Button>
      </>)
  }

  const submitButtons = [
    getButton()
  ]

  return (
    <section>
      <div className="container py-5 hero3">
        <div className="row text-center">
          <div className="col">
            <h3 className="magenta text-center">Let's start your submission</h3>
          </div>
        </div>
        <div className="row text-center">
          <div className="col">
            <div className="col">{ReactHtmlParser(saniziteRawHtml(props.firstSectionText))}</div>
            <div>&nbsp;</div>
            <div className="col">{ReactHtmlParser(saniziteRawHtml(props.secondSectionText))}</div>
            <div>&nbsp;</div>
          </div>
        </div>
        <div className="mx-auto col">
          <CrossCompanyPhoneCheck initialValues={initialValues} buttons={submitButtons} phoneInputClass="col-md-4 mx-auto" />
        </div>
        <div className="mx-auto col-md-5 text-center">
          <p>
            Questions before you continue?
            To find answers <a href="https://www.t-mobile.com/content/t-mobile/consumer/isp/FAQ.html" target="_blank" rel="noopener noreferrer">click here</a>.
            </p>          
        </div>
      </div>
    </section>
  )
}

