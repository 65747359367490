import React from "react"
import PropTypes from "prop-types"

export const MaintenanceModeComponent = ({maintenanceText}) => (
  <div className="container hero3">
    <div className="row">
      <h3 className="magenta">{maintenanceText}</h3>
    </div>
  </div>
)

MaintenanceModeComponent.propTypes = {
  maintenanceText: PropTypes.string
}
