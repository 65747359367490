import React, { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../components/context/AppContext"
import { Formik, Form, Field } from "formik"
import { TextInput, SelectInput } from "../components/forms"
import { Button, ErrorMessageStyled } from "../components/styled"
import { SubmissionApi, AuthApi } from "../utils/api"
import { TrackerComponent } from "../components"
import { LoadingSpinner } from "../components/loadingSpinner"
import { validatePhone } from "../utils/Helpers"

export const SubmissionStatus = () => {

    const { state, dispatch } = useContext(AppContext);
    const [eligibleLines, setEligibleLines] = useState([]);
    const [isFetchingSubmissions, setIsFetchingSubmissions] = useState(false);
    const [hasSubmissions, setHasSubmissions] = useState(false);
    const [tfbError, setTfbError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const dataRef = useRef(null);
    const requestData = {
        UserInfo: "",
        PhoneNumber: "",
        IsTFBAccount: state.user.isTFBAccount
    };

    const sectionStyles = {
        minHeight: "30rem"
    }

    const initialValues = {
        tfbPhoneNumber: ""
    }

    const getEligibleLines = async () => {
        const response = await AuthApi.getEligiblesLines(requestData);
        setEligibleLines(response.data);
    }

    useEffect(() => {
        if (!state.user.isTFBAccount) {
          try {
              getEligibleLines();
              getAllPreviousSubmissionsByBan(requestData);
          } catch (error) {
            console.log(error.message);
            setErrorMessage("Something went wrong. Please try again.");
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            setTfbError("");
            const selectedPhoneNumber = values.phoneNumber || values.tfbPhoneNumber;
            const tfbRequestData = state.user;
            tfbRequestData.primaryPhoneNumber= selectedPhoneNumber;
            if (!state.user.isTFBAccount) {
                filterSubmissionsByMsisdn(selectedPhoneNumber);
            }
            else {
                const user = await AuthApi.setTfbUserInfo(tfbRequestData);
                if (!user) {
                  setTfbError("The phone number you entered was not found. Please try again");
                  dispatch({type: "SETSUBMISSIONS", payload: ""});
                  dispatch({type: "SETFILTEREDSUBMISSIONSBYMSISDN", payload: ""});
                  setSubmitting(false);
                  return;
              }
              dispatch({ type: "SETUSER", payload: user });
              requestData.PhoneNumber = selectedPhoneNumber;
              requestData.UserInfo = state.user.userInfo;
              await getAllPreviousSubmissionsByBan(requestData);
              filterSubmissionsByMsisdn(selectedPhoneNumber);
            }
            setSubmitting(false);
        } catch (error) {
          setSubmitting(false);
        }
      };

    const filterSubmissionsByMsisdn = (phoneNumber) => {
        let allsubmissionsFilteredByMsisdn = [];
        const msisdnFilterCondition = submission => submission.confirmMIN === phoneNumber;
        const previousSubmissions = dataRef.current || state.submissions;
        if (previousSubmissions) {
            allsubmissionsFilteredByMsisdn = [
                ...previousSubmissions.iSubmissions.filter(msisdnFilterCondition),
                ...previousSubmissions.submissions.filter(msisdnFilterCondition)
            ];
            dispatch({ type: "SETFILTEREDSUBMISSIONSBYMSISDN", payload: allsubmissionsFilteredByMsisdn });
        }
        setHasSubmissions(allsubmissionsFilteredByMsisdn.length >= 0);
    }

    const getAllPreviousSubmissionsByBan = async (requestData) => {
        try {
            setIsFetchingSubmissions(true);
            const submissions = await SubmissionApi.getAllPreviousSubmissionsByBan(requestData);
            dispatch({ type: "SETSUBMISSIONS", payload: submissions });
            dataRef.current = submissions;
            setIsFetchingSubmissions(false);
        } catch (error) {
            setIsFetchingSubmissions(false);
        }
    };

    const isSubmitButtonDisabled = (isSubmitting, errors) => isSubmitting || Object.keys(errors).length > 0;

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ isSubmitting, errors }) => (
                    <Form>
                        <div className="container hero3">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="magenta">Submission Status</h1>
                                    <p>Please select a line to view the status of your rebate</p>
                                    <p>Fields marked with * are required</p>
                                </div>
                            </div>
                            {!state.user.isTFBAccount &&
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="phoneNumber">Please select a phone number to check the status of your previous request's status*</label>
                                        <Field id="phoneNumber" name="phoneNumber" component={SelectInput} validate={validatePhone} disabled={false} aria-required="true">
                                            <option value="">Select Phone Number</option>
                                            {eligibleLines.map((line) => {
                                                return (<option key={line} value={line}>{line}</option>)
                                            })}
                                        </Field>
                                    </div>
                                </div>
                            }
                            {state.user.isTFBAccount &&
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="tfbPhoneNumber">Please enter your primary phone number to check the status of your previous request's status*</label>
                                        <Field id="tfbPhoneNumber" name="tfbPhoneNumber" type="text" component={TextInput} validate={validatePhone} arialabel="Enter TFB PhoneNumber" aria-required="true" />
                                    </div>
                                    { tfbError && <ErrorMessageStyled>{tfbError}</ErrorMessageStyled>}
                                </div>
                            }
                            <div className="mt-3  text-center text-md-left">
                                <Button type="submit" className="btn" id="btnSubmit" primary disabled={isSubmitButtonDisabled(isSubmitting, errors)}>Continue</Button>
                            </div>
                        </div>
                        {errorMessage && <ErrorMessageStyled>{errorMessage}</ErrorMessageStyled>}
                        {isSubmitting && isFetchingSubmissions &&
                            <div className="row">
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                    <LoadingSpinner />
                                    <h2 className="magenta">Loading...</h2><br />
                                </div>
                            </div>}
                        {!isFetchingSubmissions && hasSubmissions && !tfbError &&
                            <section style={sectionStyles}>
                                <TrackerComponent submissions={state.filteredSubmissionsByMsisdn} isLoading={isFetchingSubmissions} error={""} />
                            </section>}
                    </Form>)}
            </Formik>
        </>
    )
}

SubmissionStatus.requiresAuth = true;
