import React, { useContext } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { AppContext } from "../components/context/AppContext"
import { Button } from "./styled"
import { getDateAsString } from "../utils/Helpers"
import { ROUTE_PATHS } from "../utils/routes"
import AnalyticsService from "../utils/AnalyticsService"

export const TrackerRowComponent = withRouter(({ submission, history }) => {
  const switch2Offers = process.env.REACT_APP_SWITCH2_OFFER_IDS.split(",");
  const { dispatch } = useContext(AppContext)
  const handleRowClick = (submission) => {
    AnalyticsService.sendGa4Event({ event: 'Promotions', trigger: "view", category: submission, action: "trackerComponent", label: "PromoCodeEntry" })
    dispatch({ type: "SETSELECTEDSUBMISSION", payload: submission })
    if (switch2Offers.includes(submission.offerId.toString()))
      window.location.href = `${process.env.REACT_APP_SWITCH2_URL}KS-` + (submission.msisdn || submission.confirmMIN);
    else
      history.push(`${ROUTE_PATHS.STATUS}/${submission.submitId || submission.iSubmitId}`)
  }

  return (
    <tr>
      <td style={{ textAlign: "center" }}>
        <Button onClick={(e) => handleRowClick(submission, e)} className="btn" primary>View</Button>
      </td>
      <td data-label="Description">{submission.offer.description}</td>
      <td data-label="Offer Value">{submission.offer.offerValue}</td>
      <td data-label="Offer Date">{getDateAsString(submission.stampDate)}</td>
    </tr>
  )
})

TrackerRowComponent.propTypes = {
  submission: PropTypes.object.isRequired,
}


