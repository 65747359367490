import React, { useState } from "react";

import { CrossCompanyPhoneCheck } from "../auth/CrossCompanyPhoneCheck";
import { Button } from "../styled/Button";
import { saniziteRawHtml } from "../../utils/Helpers";
import ReactHtmlParser from "react-html-parser";

const R3DSTAPLER_PROMO_CODE = "HULUONUS";

export const R3dStaplerGetStartedComponent = (props) => {
  const [submissionPromoCode, setSubmissionPromoCode] = useState(
    R3DSTAPLER_PROMO_CODE
  );

  const initialValues = {
    phoneNumber: "",
  };

  const getButton =
    (promoCode) =>
    ({ disabled, handleClick }) => {
      return (
        <>
          <Button
            type="button"
            className="btn"
            primary
            disabled={disabled}
            onClick={() => {
              setSubmissionPromoCode(promoCode);
              sessionStorage.setItem("promoCode", promoCode);
              handleClick();
            }}
          >
            Continue
          </Button>
        </>
      );
    };

  const submitButtons = [getButton(R3DSTAPLER_PROMO_CODE)];

  return (
    <section>
      <div className="container py-5 hero3">
        <div className="row text-center">
          <div className="col">
            <h3 className="magenta text-center">Let's start your submission</h3>
          </div>
        </div>
        <div className="row text-center">
          <div className="col">
            <div className="col">
              {ReactHtmlParser(saniziteRawHtml(props.firstSectionText))}
            </div>
            <div style={{ textAlign: "center" }}>
              <img
                style={{ marginTop: "1rem" }}
                src={"/images/SixMonths/R3dstapler_6Months_Logo.png"}
                alt="R3dstapler 6 Months Logo"
                className="mb-3"
              />
            </div>
            <div className="col">
              {ReactHtmlParser(saniziteRawHtml(props.secondSectionText))}
            </div>
            <div>&nbsp;</div>
          </div>
        </div>
        <div className="mx-auto col">
          <CrossCompanyPhoneCheck
            initialValues={initialValues}
            buttons={submitButtons}
            promoCode={submissionPromoCode}
            phoneInputClass="col-md-4 mx-auto"
          />
        </div>
        <div className="mx-auto col-md-5 text-center">
          <p>
            Questions before you continue? To find answers{" "}
            <a
              href="https://promotions.t-mobile.com/faq"
              target="_blank"
              rel="noopener noreferrer"
            >
              click here
            </a>{" "}
            or{" "}
            <a
              href="https://promotions.t-mobile.com/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact us
            </a>
            .
          </p>
        </div>
      </div>
    </section>
  );
};
