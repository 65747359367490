import React from "react"
import { CarouselComponent } from "../CarouselComponent"

export const AppleFitnessWelcomeComponent = () => {
  return (
    <CarouselComponent bgPosition="top center" height="110vh" images={[getLandingImage()]}>
    </CarouselComponent>
  )
}

const getLandingImage = () => {
  let isDesktop = window.matchMedia && window.matchMedia("(min-width: 768px)").matches
  if (isDesktop) { return "/images/Apple/AppleTVLanding-desktop.png" } else { return "/images/Apple/AppleTVLanding-mobile.png" }
}