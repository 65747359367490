import React, { useReducer, createContext, useEffect} from "react"
import PropTypes from "prop-types"

export const STATE_NAME = "TMO_PROMO_STATE"

const initialState = {
  user: {phoneNumbers: []},
  selectedPhone: "",
  campaign: {},
  iSubmission: {},
  files: [],
  submissions: [],
  selectedSubmission: {},
  faq: [],
  isSubmitted: false,
  choseMailIn: false,
  commonError: "",
  lookupValues: {},
  miscError: {},
  aaaMemberId: null,
  costcoMemberId: "",
  samsMemberNumber: "",
  validateLine: true,
  promoCode: ""
}

const persistedState = JSON.parse(sessionStorage.getItem(STATE_NAME))

const finalInitialState = {...initialState, ...persistedState}

const appReducer = (state, action) => {
  switch (action.type) {
  case "SETISUBMISSION":
    return {...state, iSubmission: action.payload }
  case "SETFILES":
    return {...state, files: action.payload}
  case "SETUSER" :
    return {...state, user: action.payload}
  case "SETSUBMISSIONS":
    return {...state, submissions: action.payload}
  case "SETCAMPAIGN":
    return {...state, campaign: action.payload}
  case "SETSELECTEDPHONE":
    return {...state, selectedPhone: action.payload}
  case "SETSELECTEDSUBMISSION":
    return {...state, selectedSubmission: action.payload }
  case "SETFAQ":
    return {...state, faq: action.payload}
  case "SETSUBMITTED":
    return {...state, isSubmitted: action.payload}
  case "SETCHOSEMAILIN":
    return {...state, choseMailIn: action.payload}
  case "SETTHANKYOUTEXT":
    return {...state, thankYouText: action.payload}
  case "COMMONERROR":
    return {...state, commonError: action.payload}
  case "SETLOOKUPVALUES":
    return {...state, lookupValues: action.payload}
  case "RESETUSER":
    return {...state, user: initialState.user}
  case "MISCERROR":
    return {...state, miscError: action.payload}
  case "SETAAAMEMBERID":
    return {...state, aaaMemberId: action.payload}
  case "SETCOSTCOMEMBERID":
    return { ...state, costcoMemberId: action.payload }
  case "SETSAMSMEMBERNUMBER":
    return { ...state, samsMemberNumber: action.payload }
  case "SETLINEVALIDATION":
    return {...state, validateLine: action.payload}
  case "SETPROMOCODE":
    return {...state, promoCode: action.payload}
  case "SETFILTEREDSUBMISSIONSBYMSISDN":
    return {...state, filteredSubmissionsByMsisdn: action.payload}   
  default:
    return state
  }
}

export const AppContext = createContext()

export const AppProvider = props => {
  const [state, dispatch] = useReducer(appReducer, finalInitialState)

  useEffect(() => {
    //reset miscError
    state.miscError = {}

    sessionStorage.setItem(STATE_NAME, JSON.stringify(state))
  }, [state])

  return (
    <AppContext.Provider value={{state, dispatch}}>
      {props.children}
    </AppContext.Provider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.element
}