import React from "react"
import { CarouselComponent } from "../CarouselComponent"

export const AAAPromoWelcomeComponent = () => {
  return (
    <CarouselComponent height="100vh" bgPosition="top center" images={["/images/AAA/landing_desktop.jpg"]}>
        <div className="row">
          <div className="col mx-auto">
            <p className="text-center title-paramount">
            Redeem your year of AAA ON US and start enjoying 24/7 roadside assistance & member-only discounts today!
              </p>
          </div>
        </div>
    </CarouselComponent>
  )
}
