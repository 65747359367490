import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { HeaderComponent } from "./HeaderComponent"
import { FooterComponent } from "./FooterComponent"

const addUnavScript = () => {
  const script = document.createElement("script")
  script.src = `${process.env.REACT_APP_TMO_UNAV_ENV}/client/unav.min.js`
  script.async = true
  script.type = "text/javascript"
  document.body.appendChild(script)
}

const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1)
  const vars = query.split("&")
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=")
    if (pair[0] === variable) { return pair[1] }
  }
  return (false)
}

export const LayoutComponent = ({ children }) => {
  const [hide, sethide] = useState(false)
  useEffect(() => {
    if (getQueryVariable('user_token')) {
      sethide(true)
    } else {
      addUnavScript()
    }

  }, [hide,])

  return (
    <>
      {!hide && <HeaderComponent />}
      {children}
      {!hide && <FooterComponent />}
    </>
  )
}

LayoutComponent.propTypes = {
  children: PropTypes.element.isRequired
}
