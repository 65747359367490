import React from "react"
import { useState } from "react"
import PropTypes from "prop-types"


export const Checkbox = ({ label, checked, ...props }) => {
  const defaultChecked = checked ? checked : false
  const [isChecked, setIsChecked] = useState(defaultChecked)

  return (
    <div className="checkbox-wrapper">
      <label>
        <div className="checkbox-padding">
          <div className="checkbox-inline">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => setIsChecked((prev) => !prev)}
              className={isChecked ? "checked" : ""}
              {...props}
            /></div></div>
        <span>{label}</span>
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  label: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
}
