import React, {useEffect, useContext, useState} from "react"
import PropTypes from "prop-types"
import {AppContext} from "../components/context/AppContext"
import { OfferApi } from "../utils/api"
import { MaintenanceModeComponent, PageNotFound } from "../components"
import { AppleFitnessGetStartedComponent, AppleFitnessWelcomeComponent } from "../components/AppleFitness"
import AnalyticsService from "../utils/AnalyticsService"
import { ROUTE_PATHS } from "../utils/routes"

const offerId = Number.parseInt(process.env.REACT_APP_APPLETVP3_OFFER_ID)
const BODY_TEXT_FIRST_SECTION = `<p>Lets start your Apple TV+ On Us submission</p> <p>Enter your T-Mobile for Business and select your promotion below based on your rate plan.&nbsp; You will then be prompted to log into your account.</p> <p>T-Mobile postpaid consumer voice customers ready to add Apple TV+,&nbsp;<u><strong><a href="https://www.t-mobile.com/account/benefit-redemption/paramountplus">click here</a></strong></u>&nbsp;to login and add your promotion.</p>`
const BODY_TEXT_FIRST_SECTION2 = `<p><strong>T-Mobile for Business customers, Business Unlimited Ultimate+, or equivalent plan</strong>&nbsp;<strong>can get Apple TV+ On Us!</strong>&nbsp;Initial code provides 12 months of Apple TV+ On Us. Cancel anytime. Subscriber account registration and credit card required. Before your 12-month code expires, if you are still on an eligible rate plan, you will be provided another code or given the option to continue the promotion on your T-Mobile bill. If you do not redeem the offer extension or cancel your subscription you will be billed $4.99/mo. when your 12-month promotional trial ends.&nbsp;</p> <p>&ndash;Unsure if your plan qualifies?&nbsp; Unlimited Premium, Unlimited Plus, Advantage Unlimited Plus, Business Unlimited Ultimate, Magenta Plus Business, and Magenta MAX for Business are also eligible, including Military, First Responder, 55+, and Deaf or Hard of Hearing/Visually Impaired versions of these plans.</p>`


export const AppleFitness = () => {
  const {dispatch, state} = useContext(AppContext)
  const [inMaintenanceMode, setInMaintenanceMode] = useState(true)
  const [maintenanceText, setMaintenanceText] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isErrorFetchingMaintenanceText, setIsErrorFetchingMaintenanceText] = useState(false)
  const [landingPageFirstSection, setLandingPageFirstSection] = useState("");
  const [landingPageSecSection, setLandingPageSecSection] = useState("");
  const [isPageNotFound, setIsPageNotFound] = useState(false)

  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.APPLEFITNESS);  
    dispatch({type: "RESETUSER"})
  }, [dispatch])

  useEffect(() => {
    const checkMaintenanceMode = async () => {
      setIsLoading(true)
      try {
        const clientMaintenanceFlagResult = await OfferApi.getClientAsset("MaintenanceFlag")
        const clientMaintenanceText = await OfferApi.getClientAsset("MaintenanceText")
        const pageNotFound = await OfferApi.getOfferAsset(offerId ,"PAGE_NOT_FOUND")
        setIsPageNotFound(pageNotFound)

        if (clientMaintenanceFlagResult.notes.toLowerCase() === "true") {
          setMaintenanceText(clientMaintenanceText.notes)
          setIsLoading(false)
          return
        }

        const offerMaintenanceFlag = await OfferApi.getOfferAsset(offerId, "MaintenanceFlag")
        const offerMaintenanceText = await OfferApi.getOfferAsset(offerId, "MaintenanceText")

        if (offerMaintenanceFlag.notes.toLowerCase() === "true") {
          setMaintenanceText(offerMaintenanceText.notes)
          setIsLoading(false)
          return
        }

        setInMaintenanceMode(false)
      } catch (error) {
        if (error.message === "Unable to retrieve client asset") {
          setIsErrorFetchingMaintenanceText(true)
        }
        setErrorMessage("Something went wrong. Please try again.")
      } finally {
        setIsLoading(false)
      }
    }

    const getLandingPageText = async () => {
      setIsLoading(true);
      try {
        const firstSectionText = await OfferApi.getOfferAsset(
          offerId,
          "BODY_TEXT_LANDING_PAGE"
        );
        const secondSectionText = await OfferApi.getOfferAsset(
          offerId,
          "BODY_TEXT_LANDING_PAGE2"
        );

        firstSectionText.length === 0
          ? setLandingPageFirstSection(BODY_TEXT_FIRST_SECTION)
          : setLandingPageFirstSection(firstSectionText.notes);
        secondSectionText.length === 0
          ? setLandingPageSecSection(BODY_TEXT_FIRST_SECTION2)
          : setLandingPageSecSection(secondSectionText.notes);

        setIsLoading(false);
      } catch (error) {
        if (error.message === "Unable to retrieve client asset") {
          console.log(error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    checkMaintenanceMode()
    getLandingPageText()

  }, [inMaintenanceMode,landingPageFirstSection,landingPageSecSection])

  const ActiveContent = () => (
    <>
       {!isPageNotFound &&  <AppleFitnessGetStartedComponent errorMessage={state.commonError} offerId={offerId} 
      firstSectionText={landingPageFirstSection}
      secondSectionText={landingPageSecSection} 
      setErrorMessage={setErrorMessage} />}
    </>)

  return (
    <>
    <AppleFitnessWelcomeComponent />
    {isPageNotFound &&  <PageNotFound/>}
    {isErrorFetchingMaintenanceText &&
    <div className="text-center container">
      <h1 className="magenta">Temporarily Unavailable</h1>
      <p>This site is currently under going scheduled maintenance. We&apos;ll be back soon.</p>
      <p> Thank you for your patience.</p>
    </div> }
    {isLoading && <div style={{textAlign: "center"}}>Loading ...</div>}
    {errorMessage.length > 0 && !isLoading && !isErrorFetchingMaintenanceText && <div style={{textAlign: "center"}}>{errorMessage}</div>}
    {inMaintenanceMode && !isLoading && <MaintenanceModeComponent maintenanceText={maintenanceText} />}
    {!inMaintenanceMode && !isLoading && !isErrorFetchingMaintenanceText && <ActiveContent /> }
    </>
  )
}

AppleFitness.propTypes = {
  history: PropTypes.object
}
