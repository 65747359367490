import React, { useEffect, useState } from "react"
import { CarouselComponent } from "../CarouselComponent"
import ImageApi from "../../utils/api/ImageApi"
import { LoadingSpinner } from "../loadingSpinner"

export const SixMonthsWelcomeComponent = React.memo((props) => {

    const [landingPageMainImage, setLandingPageMainImage] = useState("")
    const [srcImage, setSrcImage] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [fileName, setFileName] = useState("main_landing_page_img")
    const [isMobileVersion, setIsMobileVersion] = useState(false)

    useEffect(() => {

        const isMobile = () => {
            const match = window.matchMedia('(pointer:coarse)')
            setIsMobileVersion(match && match.matches)
        }

        const retrieveStringImage = async () => {

            try {
              setIsLoading(true)
      
              if(isMobileVersion) {
                setFileName("main_landing_page_img_mob")
              }
      
              if(props.offerCampaignId !== null && props.offerCampaignId !== 0) {

                // Get Image for Landing Page
                await ImageApi.getStringImage(props.offerCampaignId, fileName).then(
                  (response) => {
                    const offerMainImageLandingPage = response
      
                    setLandingPageMainImage(offerMainImageLandingPage)
                    setSrcImage(`data:image/jpg;base64,${landingPageMainImage}`)
                    setIsLoading(false)
                  }
                )
              }
            } catch(error) {
              console.log(error)
            } finally {
              setIsLoading(false)
            }
          }

          isMobile()

          retrieveStringImage()      

    }, [landingPageMainImage, props.offerCampaignId, fileName, isMobileVersion])

  return (
    isLoading ?
    <>
        <div style={{ textAlign: "center" }}>
        <LoadingSpinner className="mb-3" enabled={isLoading}></LoadingSpinner>
        <h2 className="magenta">Loading...</h2><br />
      </div>
    </>

    :

    <CarouselComponent height="100vh" bgPosition="top center" images={[`'${srcImage}'`]}>
      <div className="row">
        <div className="col mx-auto">
          <p className="text-center title-paramount">{props.backgrndImgText}</p>
        </div>
      </div>
    </CarouselComponent>
  )
})
