import styled from "styled-components"

export const ErrorMessage = styled.div`
  width: 100%;
  margin-top: .25rem;
  font-size: 60%;
  color: #aa1e4b;
`
//color: #DB3363;

