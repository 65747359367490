import React from "react"
import PropTypes from "prop-types"
import { TrackerRowComponent } from "./index"
import { ErrorMessage } from "./styled"

export const TrackerComponent = ({ submissions, isLoading, error }) => {
  return (
    <div className="container hero3">
      <div className="row">
        <h2 className="magenta">Reward Tracker</h2>
        <div className="col-md-12 no-padding">
          <p><strong>Check the status of your previously submitted promotion</strong></p>
          {isLoading && <div>Looking for your submissions ...</div>}
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {!isLoading && submissions.length === 0 && <p>You don&apos;t have any submissions yet</p>}
          {submissions.length > 0 && <div id="tracker">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Promotion Name</th>
                  <th scope="col">Promotion Value</th>
                  <th scope="col">Submission Date</th>
                </tr>
              </thead>
              <tbody>
                {submissions.map((submission) => <TrackerRowComponent submission={submission} key={submission.trackingId} isLoading={isLoading} />)}
              </tbody>
            </table>
          </div>}
        </div>
      </div>
    </div>
  )
}

TrackerComponent.propTypes = {
  submissions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string
}
