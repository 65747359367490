import React, { useState } from "react";
import PropTypes from "prop-types";

import { LinkButton } from "../styled";
import { QuestionsAndContactComponent } from "../index";
import { CrossCompanyPhoneCheck } from "../auth/CrossCompanyPhoneCheck";
import { saniziteRawHtml } from "../../utils/Helpers";
import ReactHtmlParser from "react-html-parser";

const CAMEOSPARK_PROMO_CODE = "2022APPLETVP3";
const JAZZINFERNO_PROMO_CODE = "2022APPLETVP4";

export const AppleFitnessGetStartedComponent = (props) => {
  const [submissionPromoCode] = useState("CAMEOSPARK_PROMO_CODE");

  const initialValues = {
    phoneNumber: "",
  };

  const getButton =
    (promoCode) =>
    ({ disabled, handleClick }) => {
      return (
        <>
          {promoCode === CAMEOSPARK_PROMO_CODE && (
            <>
              <div className="row text-left">
                {ReactHtmlParser(saniziteRawHtml(props.secondSectionText))}
              </div>
            </>
          )}
          {promoCode === JAZZINFERNO_PROMO_CODE && (
            <>
              <div className="row text-center">
                <p>
                  {" "}
                  <strong>
                    T-Mobile for Business customers on a Go5G Business, Business
                    Unlimited Advanced, or equivalent plan can get 6 months of
                    Apple TV+ On Us!
                  </strong>
                  &nbsp;After 6 months, pay $9.99/mo. Cancel anytime. Subscriber
                  account registration and credit card required. Cannot be
                  combined with previous T-Mobile Apple TV+ promotions.&nbsp;
                </p>
              </div>
            </>
          )}
          <LinkButton
            className="btn"
            id="btnTfbContinue"
            href={process.env.REACT_APP_TMO_ID_AUTH_URL}
            onClick={() => sessionStorage.setItem("promoCode", promoCode)}
            primary
          >
            Continue
          </LinkButton>
        </>
      );
    };

  const submitButtons = [
    getButton(CAMEOSPARK_PROMO_CODE),
    getButton(JAZZINFERNO_PROMO_CODE),
  ];

  return (
    <section>
      <div className="container py-5 hero3">
        <div className="row text-center">
          <div className="col">
            {ReactHtmlParser(saniziteRawHtml(props.firstSectionText))}
          </div>
        </div>

        <div className="mx-auto col">
          <div>
            <CrossCompanyPhoneCheck
              initialValues={initialValues}
              buttons={submitButtons}
              promoCode={submissionPromoCode}
              phoneInputClass="col-md-4 mx-auto"
            />
          </div>
        </div>
        <div className="text-center">
          <QuestionsAndContactComponent />
        </div>
      </div>
    </section>
  );
};

AppleFitnessGetStartedComponent.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  offerId: PropTypes.number,
};
