import React from "react"
import PropTypes from "prop-types"

import {ErrorMessage } from "../styled"

export const SelectInput = ({
  field,
  form: {touched, errors, handleChange},
  ...props
}) => {
  return (
    <>
      <select {...field} {...props}
        className="form-control"
        onChange={handleChange}
        disabled={props.disabled}
        aria-describedby="select-errors">
        {props.children}
      </select>
      {touched[field.name] && errors[field.name] && <div role="alert"><ErrorMessage id="select-errors">{errors[field.name]}</ErrorMessage></div>}
    </>
  )
}

SelectInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired
}
