import React, { useState } from "react";

import { CrossCompanyPhoneCheck } from "../auth/CrossCompanyPhoneCheck";
import { Button } from "../styled/Button";
import { saniziteRawHtml } from "../../utils/Helpers";
import ReactHtmlParser from "react-html-parser";

const OOMA_PROMO_CODE = "2023OOMAP1";

export const OomaGetStartedComponent = (props) => {
  const [submissionPromoCode, setSubmissionPromoCode] =
    useState(OOMA_PROMO_CODE);

  const initialValues = {
    phoneNumber: "",
  };

  const getButton =
    (promoCode) =>
    ({ disabled, handleClick }) => {
      return (
        <>
          <Button
            type="button"
            className="btn"
            primary
            disabled={disabled}
            onClick={() => {
              setSubmissionPromoCode(promoCode);
              handleClick();
            }}
          >
            Continue
          </Button>
        </>
      );
    };

  const submitButtons = [getButton(OOMA_PROMO_CODE)];

  return (
    <section>
      <div className="container py-5 hero3">
        <div className="row text-center">
          <div className="col">
            <h3 className="magenta text-center">Let's start your submission</h3>
          </div>
        </div>
        <div className="row text-center">
          <div className="col">
            <div className="col">
              {ReactHtmlParser(saniziteRawHtml(props.firstSectionText))}
            </div>
            <img
              style={{ marginTop: "1rem" }}
              src={"images/Ooma/ooma_logo.png"}
              alt="Ooma Logo"
              className="mb-3"
            />
            <div className="col">
              {ReactHtmlParser(saniziteRawHtml(props.secondSectionText))}
            </div>
          </div>
        </div>
        <div className="mx-auto col">
          <CrossCompanyPhoneCheck
            initialValues={initialValues}
            buttons={submitButtons}
            promoCode={submissionPromoCode}
            phoneInputClass="col-md-4 mx-auto"
          />
        </div>
        <div className="mx-auto col-md-5 text-center">
          <p>
            Questions before you continue? To find answers{" "}
            <a
              href="https://www.t-mobile.com/content/t-mobile/consumer/isp/FAQ.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              click here
            </a>
            .
          </p>
        </div>
      </div>
    </section>
  );
};
