import React, { useContext, useRef } from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { Button } from "../styled";
import { validatePhone } from "../../utils/Helpers";
import {
	promoCodeCompanyLookup,
	companyLookupFactory,
} from "../../utils/LookupHelper";
import { AppContext } from "../context/AppContext";
import { useCookies } from "react-cookie";
// import axios from "axios";

export const CrossCompanyPhoneCheck = ({
	initialValues,
	promoCode,
	buttons,
	fromYoutubeComponent,
}) => {
	const { dispatch } = useContext(AppContext);
	const phoneNumberInputContainer = useRef();

	const [cookies] = useCookies(["id_token"]);

	const companyLookupFn = promoCode
		? promoCodeCompanyLookup
		: companyLookupFactory;

	const focusOnPhoneNumber = (phno) => {
		if (phno.length >= 0 && phno.length < 10 && fromYoutubeComponent) {
			phoneNumberInputContainer.current.querySelector("input").focus();
		}
	};

	const getCompanyLookup = companyLookupFn(
		() => false,
		() => {
			dispatch({
				type: "MISCERROR",
				payload: {
					ooma: "We don't recognize that phone number, please enter a valid T-Mobile phone number.",
				},
			});
		},
		dispatch
	);

	const DefaultButton = ({ disabled }) => (
		<Button type="submit" className="btn" primary disabled={disabled}>
			Continue
		</Button>
	);

	return (
		<section ref={phoneNumberInputContainer}>
			<Formik
				initialValues={initialValues}
				validate={(values) => {
					validatePhone(values.phoneNumber);
					focusOnPhoneNumber(values.phoneNumber);
				}}
				onSubmit={(values, actions) => {
					let id_token = cookies.id_token;

					if (id_token === undefined || id_token === null || id_token === "") {
						getCompanyLookup(
							values.phoneNumber.replace(/\D+/g, ""),
							actions,
							promoCode
						);
					} else {
						if (promoCode) {
							sessionStorage.setItem("promoCode", promoCode);
						}
						window.location.href = window.location.origin + "/rebates";
					}
				}}
			>
				{({ isSubmitting, status, errors, submitForm }) => (
					<Form>
						{buttons ? (
							buttons.map((Btn, idx) => (
								<div className="mt-3 text-center" key={idx}>
									<Btn disabled={isSubmitting} handleClick={submitForm} />
								</div>
							))
						) : (
							<div className="mt-3 text-center">
								<DefaultButton disabled={isSubmitting} />
							</div>
						)}
					</Form>
				)}
			</Formik>
		</section>
	);
};

CrossCompanyPhoneCheck.propTypes = {
	initialValues: PropTypes.any.isRequired,
	promoCode: PropTypes.string,
	buttons: PropTypes.arrayOf(PropTypes.func),
	checkStatusMode: PropTypes.bool,
	phoneInputClass: PropTypes.string,
	fromYoutubeComponent: PropTypes.string,
};
