import BaseApi from "./BaseApi";
import * as popTokenBuilderUtil from "./PopTokenGenerator";

const AUTH_ENDPOINT = "/auth";
const axios = require("axios");

class AuthApi extends BaseApi {
	getLocalDnsValue() {
		const allCookies = document.cookie;

		if (
			!allCookies.split(";").filter((cookie) => {
				return cookie.trim().indexOf("dnt=") === 0;
			}).length
		) {
			return "0"; // no cookie equals "Sell"
		}

		const localDnsCookie = allCookies.split(";").filter((cookie) => {
			return cookie.trim().indexOf("dnt=") === 0;
		})[0];

		return localDnsCookie.substring(
			localDnsCookie.indexOf("=") + 1,
			localDnsCookie.length
		) === "1"
			? "1"
			: "0";
	}

	async loginWithBan(ban) {
		try {
			const endpoint = `${AUTH_ENDPOINT}/ban`;
			const response = await this.post(endpoint, {ban: ban});
			const {data: user} = response;

			return user;
		} catch (error) {
			throw new Error("Unable to log in with BAN");
		}
	}

	async loginWithTmoId(tmoToken, sessionNumber) {
		try {
			const endpoint = `${AUTH_ENDPOINT}/code`;
			//popTokenBuilderUtil.generateKeys();
			const response = await this.post(endpoint, { code: tmoToken, sessionNumber: sessionNumber, pubkey: sessionStorage.getItem("pubkey") });
			//popTokenBuilderUtil.deleteKeys();
			const {data: user} = response;
			this.setAuthToken(response.headers['usertoken']);
			return user;

		} catch (error) {
			throw new Error("Unable to log in with TMO ID")
		}
	}

	async loginWithIdToken(idToken) {		
		try {
			const endpoint = `${AUTH_ENDPOINT}/token`;
			const xAuthOriginator = idToken;

			popTokenBuilderUtil.requestAnonymousToken();
			const Authorization = sessionStorage.getItem("anonymous_token");

			const xAuthorization = popTokenBuilderUtil.generatePopToken();
			const transId = this.getServiceTransactionID();
			const appName = this.getUIAPPName();

			var httpClientIDToken = axios.create({
				baseURL: this.config.baseURL,
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					"Authorization": "Bearer " + Authorization,
					"x-auth-originator": xAuthOriginator,
					"X-Authorization": xAuthorization,
					"ServiceTransactionID": transId,
					"HeliosSourceApp": appName
				},
			});

			httpClientIDToken.interceptors.response.use(
				(response) => {
					console.log("Response: " + response);
					return response;
				},
				(error) => {
					console.log("Error: " + error);
					console.log("Error.response: " + error.response);
					if (error.response.status === 401) {
						this.deleteAuthToken();
					}
				}
			);

			const response = await httpClientIDToken({
				method: "POST",
				url: endpoint,
				data: { pubkey: sessionStorage.getItem("pubkey") }
			});

			if (response) {
				const { data: user } = response;
				this.setAuthToken(response.headers['usertoken']);
				this.setServiceTransactionID(response.headers['servicetransactionid'])				
				return user;
			}
		} catch (error) {
			console.log(error);
		}
	}
 
	async userTokenHeaderCallback(token) {
		try {
		const endpoint = `${AUTH_ENDPOINT}/httpHeaderCallback`;
		const response =  await this.post(endpoint, {token: token});
		const {data: user} = response;

		return user;

		} catch (error) {
			throw new Error("Unable to log in with TMO ID");
		}
	}
 
	async getGlobalDnsSettingV2(tMobileId) {
		try {
			const dnsValue = this.getLocalDnsValue();
			const endpoint = `${AUTH_ENDPOINT}/getdnssettingv2`;
			const response = await this.post(endpoint, {
				tMobileId: tMobileId,
				doNotSellValue: dnsValue,
			});
			const { data: dnsV2 } = response;

			return dnsV2;
		} catch (error) {
			throw new Error("Unable to retrieve DNS setting");
		}
	}

	async setGlobalDnsSetting(tMobileId, dnsValue) {
		try {
			const endpoint = `${AUTH_ENDPOINT}/setdnssetting`;
			const response = await this.post(endpoint, {
				tMobileId: tMobileId,
				doNotSellValue: dnsValue,
			});
			return response;
		} catch (error) {
			throw new Error("Unable to set the DNS setting");
		}
	}

	async getEligiblesLines(values) {
		try {
			const endpoint = `${AUTH_ENDPOINT}/geteligiblelines`;
			const response = await this.post(endpoint, values);
			return response;
		} catch (error) {
			throw new Error("Unable to set the DNS setting");
		}
	}


	async getCompanyLookup(msisdn) {
		try {
			const endpoint = `${AUTH_ENDPOINT}/msisdncompanylookup/${msisdn}`;
			const response = await this.get(endpoint);
			return response.data;
		} catch (error) {
			throw new Error("Unable to retrieve cross company lookup");
		}
	}

	async setTfbUserInfo(user) {
		try {
			const endpoint = `${AUTH_ENDPOINT}/tfbuserinfo`;
			const response = await this.post(endpoint, user);

          return response.data;
      } catch (err) {
          return null;
          //throw new Error("Unable to retrieve User Info")
      }
  }
}

export default new AuthApi();
