import React, { useContext, useEffect } from "react"
import { Button } from "../styled/Button"
import { ROUTE_PATHS } from "../../utils/routes"
import { AppContext } from "../context/AppContext"

export const AAAWidget = () =>{
  const {dispatch} = useContext(AppContext)
  useEffect(() => {
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_AAA_WIDGET_SCRIPT_URL;
    document.body.appendChild(script);
    
  });

  function handleLoaded(){
  }

  const handleCancel = () =>{
    alert("Membership validation required. Please complete all fields and try again");
  }

  const handleSuccess = (aaaMemberInfo) =>{
    if(aaaMemberInfo){
      if(aaaMemberInfo.number) dispatch({ type: "SETAAAMEMBERID", payload: aaaMemberInfo.number })
      window.location = ROUTE_PATHS.REGISTER
    }
  }

  const showAAAWidget =()=>{
    window.showMemberValidationForm(handleSuccess,handleCancel,handleLoaded)
  }

  return (
    <Button style={{cursor: "pointer"}} onClick={() => showAAAWidget()} className="btn agree-to-terms" id="btnSubmit" primary>Continue</Button>
  )
}
