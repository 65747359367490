import { isOnPage } from "./Helpers"



const redirectToAuth = (company, dispatch) => { 
  // Use promo code if need to redirect promos to specific URLS
  // let promoCode = sessionStorage.getItem("promoCode");
  if (company === "1" || company.toUpperCase() === "T-MOBILE") {
    //Apple offers only applicable for TFB customers
    if (isOnPage('apple') || isOnPage('pandora')) {
      window.location.href = process.env.REACT_APP_TMO_TFB_ID_AUTH_URL
    } else {
      window.location.href = process.env.REACT_APP_TMO_ID_AUTH_URL
    }
  }
}

const defaultLookupValues = (phoneNumber, company) => ({phoneNumber, company})

function baseCompanyLookupFactory(before, onCompanyNotFound, dispatch, getLookupValues = defaultLookupValues) {
  //reset miscError
  dispatch({type: "MISCERROR", payload: {}})

  return async (phoneNumber) => {
    before()
    const company = "T-MOBILE"
    if (!company || company === "NotFound" || company === "0") {
      onCompanyNotFound()
    } else {
      let lookupValues = getLookupValues(phoneNumber, company)
      dispatch({type: "SETLOOKUPVALUES", payload: lookupValues})

      redirectToAuth(company, dispatch)
    }
  }
}

export const companyLookupFactory = (before, onCompanyNotFound, dispatch) => async (
  phoneNumber,
  actions
) => {
  await baseCompanyLookupFactory(before, onCompanyNotFound, dispatch)(phoneNumber)
  actions.setSubmitting(false)
}

export const promoCodeCompanyLookup = (before, onCompanyNotFound, dispatch) => async (
  phoneNumber,
  actions,
  promoCode
) => {
  const getLookupValues = (phoneNumber, company) => ({phoneNumber, company, promoCode})
  await baseCompanyLookupFactory(before, () => onCompanyNotFound(promoCode), dispatch, getLookupValues)(phoneNumber)

  actions.setSubmitting(false)
}
