import React from "react"
import PropTypes from "prop-types"



export const FoundPromotionComponent = ({offerName, termsTitle}) => {
  const title = termsTitle ? termsTitle : "Review your promotion"
  return (
  <>
    <h1 className="magenta">{title}</h1>
    <h3 className="text-left">{offerName}</h3>
  </>
  )
}

FoundPromotionComponent.propTypes = {
  offerName: PropTypes.string.isRequired,
  termsTitle: PropTypes.string
}
