import React from "react"


export const PrivacyPolicyComponent = () => (
  <div className="container">
    <div>
      <p className="accessibility-font">Promotion request form information being collected is subject to the privacy policy of T-Mobile.com. By proceeding, I certify the information on this page is true and accurate and agree to receive text messages about my rebate (which could include info about rate plan upgrades and other steps required to become eligible).</p>
    </div>
  </div>
)
