import React, { useEffect, useState,useContext } from "react"
import PropTypes from "prop-types"
import { AppContext } from "../components/context/AppContext"
import { OfferApi } from "../utils/api"

import { WelcomeComponent, GetStartedComponent, MaintenanceModeComponent, CheckStatusComponent, PageNotFound } from "../components"
import MetaTags from "react-meta-tags"



export const Home = () => {
  const [inMaintenanceMode, setInMaintenanceMode] = useState(true)
  const [maintenanceText, setMaintenanceText] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isPageNotFound, setIsPageNotFound] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isErrorFetchingMaintenanceText, setIsErrorFetchingMaintenanceText] = useState(false)
  const {dispatch} = useContext(AppContext);
  sessionStorage.removeItem("isFromStandardPage");

  useEffect(() => {
    dispatch({type: "RESETUSER"})
  }, [dispatch])

  useEffect(() => { 
    const checkMaintenanceMode = async () => {
      setIsLoading(true)
      try {
        const result = await OfferApi.getClientAsset("MaintenanceFlag")
        const maintenanceText = await OfferApi.getClientAsset("MaintenanceText")
        const pageNotFound = await OfferApi.getClientAsset("PAGE_NOT_FOUND")
       setIsPageNotFound(pageNotFound)

        if (result.notes.toLowerCase() === "true") {
          setMaintenanceText(maintenanceText.notes)
          setIsLoading(false)
          return
        }

        setInMaintenanceMode(false)
      } catch (error) {
        if (error.message === "Unable to retrieve client asset") {
          setIsErrorFetchingMaintenanceText(true)
        }
        setErrorMessage("Something went wrong. Please try again.")
      } finally {
        setIsLoading(false)
      }
    }

    checkMaintenanceMode()
  }, [setInMaintenanceMode])

  const ActiveContent = () => (
    <>
      {!isPageNotFound &&  <GetStartedComponent/>}
      <CheckStatusComponent />
    </>)

  return (
    <>
      <MetaTags>
        <link rel="canonical" href="https://www.t-mobile.com/offers/deals-hub" />
      </MetaTags>

      <WelcomeComponent />
      {isPageNotFound &&  <PageNotFound/>}
      {isErrorFetchingMaintenanceText && !isPageNotFound &&
        <div className="text-center container">
          <h1 className="magenta">Temporarily Unavailable</h1>
          <p>This site is currently under going scheduled maintenance. We&apos;ll be back soon.</p>
          <p> Thank you for your patience.</p>
        </div>}
      {isLoading && <div style={{ textAlign: "center" }}>Loading ...</div>}
      {errorMessage.length > 0 && !isLoading && !isErrorFetchingMaintenanceText && <div style={{ textAlign: "center" }}>{errorMessage}</div>}
      {inMaintenanceMode && !isLoading && <MaintenanceModeComponent maintenanceText={maintenanceText} />}
      {!inMaintenanceMode && !isLoading && !isErrorFetchingMaintenanceText && <ActiveContent />}
    </>
  )
}

Home.propTypes = {
  history: PropTypes.object
}
