import React from "react"
import PropTypes from "prop-types"
import { GetTFBMsisdnForm } from "../components/forms"

export const GetTFBMsisdn = ({history}) => {
    return (
        <>
            <div className="container hero3">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="magenta">Start a new Submission</h1>
                        <p> To start a new submission, enter your primary phone number on the account. </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <p>  Field marked with * are required</p>
                        <div className="mx-auto col-md-12">
                            <GetTFBMsisdnForm history={history}></GetTFBMsisdnForm>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

GetTFBMsisdn.propTypes = {
    history: PropTypes.object
}