import React from "react"

export const CheckStatusComponent = () => {

  return (
    <section>
      <div className="row">
          <div className="container">
             <div className="text-center">
                <p>Click here to <a href={process.env.REACT_APP_TMO_ID_AUTH_URL.replace("TMobileAuthCallback", "submissionstatus")} rel="noreferrer">check your submission status</a></p>
              </div>
          </div>
        </div>
    </section>
  )
}