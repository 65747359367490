import React, {useContext} from "react"
//import PropTypes from "prop-types"

import {AppContext} from "../components/context/AppContext"

//const FIRST_RESPONDERS_OFFERID = Number.parseInt(process.env.REACT_APP_FIRST_RESPONDERS_OFFER_ID)

export const SheerIdIFrame = (/*props*/) => {
  const {state} = useContext(AppContext)

  let iFrameSrc = state.campaign.iFrameExperienceOneUrl

  let accountType = state.user.accountType
  if (accountType === "B") {
    iFrameSrc = state.campaign.iFrameExperienceTwoUrl
  }

  iFrameSrc = iFrameSrc.replace("firstNameHere", state.iSubmission.firstName)
  iFrameSrc = iFrameSrc.replace("lastNameHere", state.iSubmission.lastName)
  iFrameSrc = iFrameSrc.replace("emailHere", state.iSubmission.email1)
  iFrameSrc = iFrameSrc.replace("TrackingIdHere", state.iSubmission.trackingId)
  iFrameSrc = iFrameSrc.replace("ISubmitIdHere", state.iSubmission.iSubmitId)

  return (
    <section>
      <div className="container hero3">
        <div className="container">
          <div className="row">
            <iframe src={iFrameSrc} className="sheerId" title="First Responder Verification">
            </iframe>
          </div>
        </div>
      </div>
    </section>
  )
}

SheerIdIFrame.propTypes = {
  //iFrameUrl: PropTypes.string
}

SheerIdIFrame.requiresAuth = true
