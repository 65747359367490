import {useEffect} from "react"
import {withRouter} from "react-router-dom"

export const ScrollToTopComponent = withRouter(({children, location: { pathname} }) => {
    useEffect(() => {
        const noop = () => { };
        Object.defineProperty(window, 'scrollTo', { value: noop, writable: true });
    window.scrollTo(0,0)
  }, [pathname])

  return children
})
