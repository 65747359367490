import React, {useEffect} from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"

import { ROUTE_PATHS } from "../utils/routes"
import { NoWrapSpan } from "../components/styled"
import AnalyticsService from "../utils/AnalyticsService"

export const Contact = () => {

  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.CONTACT);
  },)
  return (
    <>
      <MetaTags>
        <link rel="canonical" href="https://www.t-mobile.com/contact-us" />
      </MetaTags>
      <section>
        <div className="container my-4">
          <div className="row">
            <div className="col-md-8 mb-4">
              <h1 className="magenta">Contact us.</h1>
              <p>To find answers to Frequently Asked Questions <Link to={ROUTE_PATHS.FAQ}>click here</Link>.</p>
              <p>Just want to check the status of your submission? See how it&apos;s going <Link to={ROUTE_PATHS.DASHBOARD}>here</Link>.</p>
              <p>You can also call for support: </p>
              <p>For <strong>T-Mobile non-device offers</strong> and for <strong>general help</strong>, dial 611 from your T-Mobile phone, or call <a href="tel:18009378997"><NoWrapSpan>1-800-937-8997</NoWrapSpan></a>.
              </p>
              <p>For <strong>T-Mobile device offers</strong>, call T-Mobile Promotions Customer Care at <a href="tel:18774703619"><NoWrapSpan>1-877-470-3619</NoWrapSpan></a></p>
              <ul>
                <li>Monday-Friday: 8 a.m. - 10 p.m. ET</li>
                <li>Saturday: 9 a.m. - 8 p.m. ET</li>
              </ul>
              <strong>For Costco or Sam’s Club Member Benefit Programs ONLY: Dial 611 from your T-Mobile phone or call <a href="tel:8009378997"><NoWrapSpan>800-937-8997</NoWrapSpan></a></strong>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

Contact.propTypes = {
  history: PropTypes.object
}
