import React from "react"
import { CarouselComponent } from "../CarouselComponent"

export const GoogleWelcomeComponent = (props) => {
  return (
    <CarouselComponent height="100vh" bgPosition="top center" images={["/images/Google/Google_LandingPage_desktop.jpg"]}>
      <div className="row">
        <div className="col mx-auto">
          <p className="text-center title-paramount">{props.backgrndImgText}</p>
        </div>
      </div>
    </CarouselComponent>
  )
}
