import React, { useEffect, useState, useContext, useRef } from "react"
import PropTypes from "prop-types"
import AnalyticsService from "../utils/AnalyticsService"
import { ROUTE_PATHS } from "../utils/routes"
import { FrontDoorFaqForm } from "../components/forms"
import { AuthApi } from "../utils/api";
import { ErrorMessage } from "../components/styled"
import { AppContext } from "../components/context/AppContext"
import { SelectFrontdorPhoneForm } from "../components/forms"
import { TrackerComponent } from "../components"
import { SubmissionApi } from "../utils/api";


export const FrontDoorFaq = ({ history }) => {
    const [hasPreviousSubmissions, setHasPreviousSubmissions] = useState(false);
    const [showSection, setShowSection] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [tfbError, setTfbError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [inputPhoneNumber, setInputPhoneNumber] = useState("");
    const [eligiblesLinesResponse, setEligiblesResponse] = useState([]);
    const [isFetchingSubmissions, setIsFetchingSubmissions] = useState(false);
    const [submissionFetchingErrorMessage, setSubmissionFetchingErrorMessage] = useState("");
    const { state, dispatch } = useContext(AppContext);
    const [disableButton, setDisableButton] = useState(true)
    const [isSubmittingPromoCode, setIsSubmittingPromoCode] = useState(false);
    const [showPromoCodeLink, setShowPromoCodeLink] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const [isShowPromoCode, setShowPromoCode] = useState(false);
    const isTFBAccount = state.user.isTFBAccount;
    const dataRef = useRef(null);
    const requestData = {
        UserInfo: "",
        PhoneNumber: "",
        IsTFBAccount: isTFBAccount
    };

    useEffect(() => {
        AnalyticsService.sendUniquePageview(ROUTE_PATHS.FRONTDOORLANDING);
        if (eligiblesLinesResponse.length > 0 && !isTFBAccount) {
            setEligiblesResponse(eligiblesLinesResponse)
        } else if (eligiblesLinesResponse.length <= 0 && !isTFBAccount) {
            const getListOfLines = async () => {
                setIsSubmitting(true)
                try {
                    let eligibleNumbersRequest = {
                        userInfo: '',
                        isTFBAccount: isTFBAccount,
                        phoneNumber: '',
                    }
                    await AuthApi.getEligiblesLines(eligibleNumbersRequest).then((response) => {
                        //if user has ineligible reason -- where will errors show in the UI?
                        if (response.data.length <= 0) {
                            setErrorMessage('There is no eligibles lines');
                        }
                        else {
                            setEligiblesResponse(response.data)
                        }
                        setIsSubmitting(false)
                    },
                        (error) => {
                            setErrorMessage("Something went wrong, please try again");
                            setIsSubmitting(false);
                        })
                }
                catch (error) {
                    setErrorMessage("Something went wrong, please try again");
                    setIsSubmitting(false);
                }
            }
            getListOfLines()
        }
    }, [eligiblesLinesResponse, isTFBAccount])

    useEffect(() => {
        if (!isTFBAccount)
            getPreviousSubmissions(requestData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const sectionStyles = {
        minHeight: "30rem",
    };

    const getSubmissions = async (event) => {
        AnalyticsService.sendGa4Event({ event: 'Promotions', trigger: "submitted", category: "getSubmissions", action: isTFBAccount, label: "continue" })
        setDisableButton(true)
        if (isTFBAccount) {
            await setUserTfbInfo(event);

        } else if (!isTFBAccount) {
            filterSubmissionsByMsisdn(event.phoneNumber)
            setInputPhoneNumber(event.phoneNumber)
        }
    }

    const setUserTfbInfo = async (event) => {
        let values = state.user;
        values.primaryPhoneNumber = event.tfbNumber.trim();
        const user = await AuthApi.setTfbUserInfo(values);
        if (!user) {
            dispatch({
                type: "COMMONERROR",
                payload:
                    "The phone number you entered was not found. Please try again",
            });
            setTfbError("The phone number you entered was not found. Please try again");
            setShowPromoCodeLink(false);
            setShowSection(false);
            return;
        }
        dispatch({ type: "SETUSER", payload: user });
        requestData.UserInfo = state.user.userInfo;
        requestData.PhoneNumber = event.tfbNumber;
        setInputPhoneNumber(event.tfbNumber)
        await getPreviousSubmissions(requestData);
        filterSubmissionsByMsisdn(event.tfbNumber)
    }

    const handleChanged = (event) => {
        setTfbError("");
        setShowPromoCodeLink(false)
        setShowPromoCode(false)
        setShowButton(true)
        setIsSubmitting(true)
        setIsSubmittingPromoCode(true)
        if(event.target.value === ""){
            setShowSection(false);
            setDisableButton(true);
            setInputPhoneNumber(event.target.value);
        } else {
            setDisableButton(false)
            setShowSection(false)
            setInputPhoneNumber(event.target.value);
            return;
        }
        
    }

    const getPreviousSubmissions = async (requestData) => {
        try {
            const submissions = await SubmissionApi.getPreviousSubmissionsByBan(requestData);
            dispatch({ type: "SETSUBMISSIONS", payload: submissions });
            dataRef.current = submissions;
        } catch (error) {
            setSubmissionFetchingErrorMessage("Something went wrong, please try again");
        }
    };

    const filterSubmissionsByMsisdn = (phoneNumber) => {
        const previousSubmissions = dataRef.current || state.submissions;
        setIsFetchingSubmissions(true)
        const filteredSubmissionsByMsisdn = previousSubmissions.filter(submission => submission.msisdn === phoneNumber)
        dispatch({ type: "SETFILTEREDSUBMISSIONSBYMSISDN", payload: filteredSubmissionsByMsisdn })
        setIsFetchingSubmissions(false)
        setHasPreviousSubmissions(filteredSubmissionsByMsisdn.length > 0)
        if (filteredSubmissionsByMsisdn.length > 0) {
            setShowPromoCodeLink(false)
        } else {
            setShowPromoCodeLink(true)
        }
        setShowSection(true);
    }

    const handleClickLink = (e) => {
        AnalyticsService.sendGa4Event({ event: 'Promotions', trigger: "link", category: "showPromoCodeLink", action: isTFBAccount, label: "click_link" })
        setShowSection(false)
        setShowButton(false)
        setShowPromoCode(true)
        return e.preventDefault()
    }

    const handleClickLinkCancel = (e) => {
        AnalyticsService.sendGa4Event({ event: 'Promotions', trigger: "link", category: "showPromoCodeLink", action: isTFBAccount, label: "cancel" })
        setShowSection(false)
        setIsSubmittingPromoCode(true)
        setShowButton(true)
        setDisableButton(false)
        setShowPromoCode(false)
        setHasPreviousSubmissions(false)
        if(inputPhoneNumber === undefined || inputPhoneNumber === ""){
            setDisableButton(true);
        }
        return e.preventDefault()
    }


    return (
        <>
            <div className="frontdoor">
                <div>
                    <h1 className="magenta">Before we begin...</h1>
                    {isTFBAccount ? (
                        <p> To start a new submission, enter your primary phone number on the account. </p>
                    ) : (
                        <p>Please answer the following questions so we can provide you with the right offers.</p>
                    )}
                </div>
                <p >
                    Fields marked with * are required
                </p>
                <SelectFrontdorPhoneForm submitting={isSubmittingPromoCode} tfbError={tfbError} history={history} isEligiblesNumbers={eligiblesLinesResponse} inputPhoneNumber={inputPhoneNumber} isDisable={disableButton} isTfbOrBau={isTFBAccount} onSubmit={(event) => getSubmissions(event)} onChange={(event) => handleChanged(event)} showPromoCodeLink={showPromoCodeLink} handleClickLinkCancel={(event) =>handleClickLinkCancel(event)}  handleClickLink={(event) =>handleClickLink(event)} showButton={showButton} isShowPromoCode={isShowPromoCode}></SelectFrontdorPhoneForm>
                {showSection && !hasPreviousSubmissions && (<FrontDoorFaqForm history={history} isTfbOrBau={isTFBAccount} inputPhoneNumber={inputPhoneNumber} ></FrontDoorFaqForm>)}
                {showSection && !isFetchingSubmissions && hasPreviousSubmissions && (<section style={sectionStyles}>
                    <h3 className="magenta text-center">It looks like this phone number has already submitted for a promotion.
                        Would you like to check the status?
                    </h3>
                    <TrackerComponent
                        submissions={state.filteredSubmissionsByMsisdn}
                        isLoading={isFetchingSubmissions}
                        error={submissionFetchingErrorMessage} />
                </section>)}
            </div>
            {!isSubmitting && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </>
    )
}
FrontDoorFaq.requiresAuth = true;
FrontDoorFaq.propTypes = {
    history: PropTypes.object
}
