import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import { QuestionsAndContactComponent } from "../index"
import { OfferApi } from "../../utils/api"
import { CrossCompanyPhoneCheck } from "../auth/CrossCompanyPhoneCheck"

const TVISION_PROMO_CODE = "TVISION2020"
const APPLETV_PROMO_CODE = "2020APPLETV"

export const TVisionGetStartedComponent = (props) => {
  const [tvisionOfferDetails, setTvisionOfferDetails] = useState(null)

  const initialValues = {
    phoneNumber: ""
  }

  useEffect(() => {
    const checkTvisionOfferDetails = async () => {
      try {
        setTvisionOfferDetails(await OfferApi.getOfferCampaignDetails(props.offerId))
      } catch (error) {
        setTvisionOfferDetails(null)
      }
    }
    checkTvisionOfferDetails()
  }, [props.offerId])

  return (
    <section>
      <div className="container py-5 hero3">
        <div className="row text-center">
          <div className="col">
            <h3 className="magenta text-center">Choose your offer</h3>
          </div>
        </div>
        <div className="row text-center">
          <div className="col">
              <p>Enter your T-Mobile phone number for the offer you are trying to redeem.
                 You will then be prompted to log in to your account. Please wait until your number is active on our network before continuing. 
                 The temporary number assigned to you at activation is not compatible with our promotions.</p>
                 <p>If you are a T-Mobile for Business customer, please "<a href={process.env.REACT_APP_TMO_TFB_ID_AUTH_URL} rel="noreferrer">click here to login</a>".</p>
            </div>
          </div>
        {tvisionOfferDetails && 
        <>
          <div className="row text-center">
            <div className="col" style={{marginBottom: "6px"}}>
              <b>12 months of Apple TV+ free</b>
            </div>
          </div>
          <div className="mx-auto col-md-4">
            <div>
              <CrossCompanyPhoneCheck initialValues={initialValues} promoCode={TVISION_PROMO_CODE} />
            </div>
          </div>
        </>}
        <div className="row text-center">
          <div className="col" style={{marginBottom: "6px"}}>
            <b>Apple TV 4K for $99</b>
          </div>
        </div>
        <div className="mx-auto col-md-4">
          <div>
            <CrossCompanyPhoneCheck initialValues={initialValues} promoCode={APPLETV_PROMO_CODE} />
          </div>
        </div>
        <div className="mx-auto col-md-5 text-center">
          <QuestionsAndContactComponent />
        </div>
      </div>
    </section>
  )
}

TVisionGetStartedComponent.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  offerId: PropTypes.number
}
