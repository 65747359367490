import React from "react"
import PropTypes from "prop-types"
import { Button } from "../styled"
import { pushToHistory } from "../../utils/Helpers"
import { ROUTE_PATHS } from "../../utils/routes"

export const SubmissionButton = ({ offerIs, history }) => {
  const offerKey = Object.keys(offerIs)[0]
  const routeMap = {
    isYouTubeBaseOffers: ROUTE_PATHS.YOUTUBETV,
    isTidalOffer: ROUTE_PATHS.TIDAL,
    isCameoSpark: ROUTE_PATHS.APPLEFITNESS,
    isJazzInferno: ROUTE_PATHS.APPLEFITNESS
  }
  const path = routeMap[offerKey]

  if (offerIs.isYouTubeBaseOffers || offerIs.isTidalOffer || offerIs.isCameoSpark || offerIs.isJazzInferno) {
    let text = "Start a new submission"
    const style = {}
    if (offerIs.isCameoSpark || offerIs.isJazzInferno) {
      text = "Submit for another offer"
      style.fontWeight = "600"
    }

    return <Button role="alert" className="btn" style={style} onClick={() => pushToHistory(history, path)} primary>{text}</Button>
  } else { return null }
}

SubmissionButton.propTypes = {
  offerIs: PropTypes.object,
  history: PropTypes.object
}

