import React, {useState, useEffect, useContext} from "react"
import PropTypes from "prop-types"
import {Formik, Form, Field} from "formik"

import {TextInput, SelectInput} from "./index"
import {Button, ErrorMessage} from "../styled"

import {AppContext} from "../context/AppContext"
import {states, addOptionalRegistrationSchema} from "../../utils/FormUtils"
import { PrivacyPolicyComponent } from "../index"
import { NumberInput } from "./NumberInput"

import { saniziteRawHtml } from "../../utils/Helpers"
import ReactHtmlParser from "react-html-parser"

const FIRST_RESPONDERS_OFFERID = Number.parseInt(process.env.REACT_APP_FIRST_RESPONDERS_OFFER_ID)
const TVISION_OFFERID = Number.parseInt(process.env.REACT_APP_TVISION_OFFER_ID)
const APPLETV_REBATE_OFFERID = Number.parseInt(process.env.REACT_APP_APPLETV_REBATE_OFFERID_ID)
const APPLE_TV_P1 =  Number.parseInt(process.env.REACT_APP_APPLETVP1_OFFER_ID)
const SAMSCLUB_OFFER_ID = Number.parseInt(process.env.REACT_APP_SAMSCLUB_OFFER_ID)
const GO5GNEXT_OFFER_ID = Number.parseInt(process.env.REACT_APP_GO5GNEXT_OFFER_ID)
const SIXMONTHS_OFFER_ID = Number.parseInt(process.env.REACT_APP_SIXMONTHS_OFFER_ID)
const R3DSTAPLER_OFFER_ID = Number.parseInt(process.env.REACT_APP_R3DSTAPLER_OFFER_ID)
const COSTCO_OFFER_ID = Number.parseInt(process.env.REACT_APP_COSTCO_OFFER_ID);

export const RegistrationForm = ({onSubmit, fields, showPrivacyPolicy}) => {
  const {state} = useContext(AppContext)

  const [firstNameVisible, setFirstNameVisible] = useState(false)
  const [lastNameVisible, setLastNameVisible] = useState(false)
  const [firstNameDisabled, setFirstNameDisabled] = useState(false)
  const [lastNameDisabled, setLastNameDisabled] = useState(false)
  const [emailVisible, setEmailVisible] = useState(false)
  const [emailRequired, setEmailRequired] = useState(false)
  const [confirmEmailVisible, setConfirmEmailVisible] = useState(false)

  const [retailer, setRetailer] = useState(false)
  const [rcptDate, setRcptDate] = useState(false)
  const [etfAmount1, setEtfAmount1] = useState(false)

  const [addressRowVisible, setAddressRowVisible] = useState(false)
  const [cityVisible, setCityVisible] = useState(false)
  const [stateVisible, setStateVisible] = useState(false)
  const [postalCodeVisible, setPostalCodeVisible] = useState(false)

  const [companyVisible, setCompanyVisible] = useState(false)
  const [imeiVisible, setImeiVisible] = useState(false)
  const [imei2Visible, setImei2Visible] = useState(false)
  const [dealerCodeVisible, setDealerCodeVisible] = useState(false)
  const [telephone1Visible, setTelephone1Visible] = useState(false)
  const [telephone1TextField, setTelephone1AsTextField] = useState(false)
  const [registrationInfoText, setRegistrationInfoText] = useState("")

  const [firstRespondersOffer, setFirstRespondersOffer] = useState(false)
  const [hintOffer, setHintOffer] = useState(false)

  const [aaaMemberIdVisible, setAaaMemberIdVisible] = useState(false)
  const [aaaMemberIdDisabled, setAaaMemberIdDisabled] = useState(false)

  const [costcoMemberIdVisible, setcostcoMemberIdVisible] = useState(false)
  const [costcoMemberIdDisabled] = useState(false)

  const [samsMemberNumberVisible, setsamsMemberNumberVisible] = useState(false)
  const [samsMemberNumberDisabled] = useState(false)

  var isTFBAccount = state.user.isTFBAccount

  const initialValues = {
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: "",
    email1: "",
    imei: "",
    imei2: "",
    confirmImei: "",
    confirmImei2: "",
    dealerCode: "",
    telephone1: "",
    retailer: "",
    rcptDate: "",
    etfAmount1: "",
    aaaMemberId: "",
    costcoMemberId: "",
    samsMemberNumber: ""
  }

  const validateState = (value) => {
    let error
    if (value === "") {
      error = "State is required."
    }
    return error
  }

  const validatePhone = (value) => {
    let error
    if (value === "") {
      error = "Phone is required."
    }
    return error
  }

  const validateRetailerName = (value) => {
    let error
    if (value === "") {
      error = "Retailer Name is required."
    }
    return error
  }

  const getretailerDropdownOptions = () => {
    if (fields){
      return fields.filter(field => field.fieldName === "retailer")[0].values.split(',')
    }
      return []
  }

  const validateEmail = (value) => {
    let error
    if (emailRequired && !value) {
      error = "Email address is required."
    }

    return error
  }

  const validationSchema = addOptionalRegistrationSchema(fields,COSTCO_OFFER_ID)

  useEffect(() => {
    if (fields) {

      if (fields.some(field => field.fieldName === "firstName")){
        setFirstNameVisible(true)
      }

      if (fields.some(field => field.fieldName === "lastName")){
        setLastNameVisible(true)
      }

      if (fields.some(field => field.fieldName === "email") || (isTFBAccount && !state.campaign.isInstantValidation) ){
        setEmailVisible(true)
      }

      if (fields.some(field => field.fieldName === "aaaMemberId" && field.visible === true)){
        setAaaMemberIdVisible(true)
        setAaaMemberIdDisabled(true)
        initialValues.aaaMemberId = state.aaaMemberId
      }
      
      if (fields.some(field => field.fieldName === "costcoMemberId" && field.visible === true)){
        setcostcoMemberIdVisible(true)
        initialValues.costcoMemberId = state.costcoMemberId
      }

      if (fields.some(field => field.fieldName === "samsMemberNumber" && field.visible === true)){
        setsamsMemberNumberVisible(true)
        initialValues.samsMemberNumber = state.samsMemberNumber
      }
      
      let addressOneField = fields.filter(field => field.fieldName === "address")[0]
      let addressTwoField = fields.filter(field => field.fieldName === "address2")[0]
      if (addressOneField && addressTwoField) {
        setAddressRowVisible(true)
      }

      let cityField = fields.filter(field => field.fieldName === "city")[0]
      if(cityField) {
         setCityVisible(true)
      }

      let stateField = fields.filter(field => field.fieldName === "state")[0]
      if (stateField) {
        setStateVisible(true)
      }

      let postalCodeField = fields.filter(field => field.fieldName === "zipCode")[0]
      if (postalCodeField) {
        setPostalCodeVisible(true)
      }
      if (fields.filter(field => field.fieldName === "confirmEmail" && field.visible === true).length > 0 || (isTFBAccount && !state.campaign.isInstantValidation)) {
        setConfirmEmailVisible(true)
      }

      if (fields.filter(field => field.fieldName === "imei" && field.visible === true).length > 0) {
        setImeiVisible(true)
      }

      if (fields.filter(field => field.fieldName === "imei2" && field.visible === true).length > 0) {
        setImei2Visible(true)
      }

      if (fields.filter(field => field.fieldName === "dealerCode" && field.visible === true).length > 0) {
        setDealerCodeVisible(true)
      }

      if (fields.filter(field => field.fieldName === "retailer" && field.visible === true).length > 0) {
        setRetailer(true)
      }

      if (fields.filter(field => field.fieldName === "rcptDate" && field.visible === true).length > 0) {
        setRcptDate(true)
      }

      if (fields.filter(field => field.fieldName === "etfAmount1" && field.visible === true).length > 0) {
        setEtfAmount1(true)
      }

      let telephoneOneField = fields.filter(field => field.fieldName === "telephone1")[0]
      if (telephoneOneField) {
        setTelephone1Visible(true)
        // Telephone1AsTextField can be removed when the cross-company-search is updated
        if (telephoneOneField.fieldType === "text" && state.campaign.isHintOffer) {
          setTelephone1AsTextField(true);
        }
      }

      let emailField = fields.filter(field => field.fieldName === "email")[0]
      if (emailField && emailField.required) {
        setEmailRequired(true)
      }

      let companyField = fields.filter(field => field.fieldName === "company" && field.visible === true)
      if (companyField.length > 0) {
        setCompanyVisible(true)
      }

      // phase 2, find a way to not hard-code the offer and make these more dynamic
      // this is the first responder offer
      if (state.campaign.offerId === FIRST_RESPONDERS_OFFERID) {
        setFirstRespondersOffer(true)
        initialValues.MSISDN = ""

        if (state.user.accountType !== "B" && state.user.firstName) {
          initialValues.firstName = state.user.firstName
          setFirstNameDisabled(true)
        }

        if (state.user.accountType !== "B" && state.user.lastName) {
          initialValues.lastName = state.user.lastName
          setLastNameDisabled(true)
        }
      }

      if (state.campaign.isHintOffer) {
        setHintOffer(true)
      }

      if (state.campaign.offerId === TVISION_OFFERID || state.campaign.offerId === APPLETV_REBATE_OFFERID) {
        if (state.user.firstName) {
          initialValues.firstName = state.user.firstName
        }

        if (state.user.lastName) {
          initialValues.lastName = state.user.lastName
        }

        setImeiVisible(false)
        setImei2Visible(false)
        setDealerCodeVisible(false)
        setTelephone1Visible(false)
      }
    }

    if (state.campaign.registrationInfo){
      setRegistrationInfoText(state.campaign.registrationInfo)
    }

   
  }, [fields, state.campaign, state.user, state.aaaMemberId, state.costcoMemberId, state.samsMemberNumber, initialValues, isTFBAccount])

  const IMEI_PLACEHOLDER = "15-digit IMEI #"

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({status, isSubmitting}) => (
        <Form>
          {firstRespondersOffer &&
            <div>
              <p>If the name does not match the First Responder on your account, please call 611 from your T-Mobile phone to contact Care</p>
            </div>
          }
          {(firstNameVisible || lastNameVisible) &&
            <div className="row">
              {firstNameVisible &&
                <div className="form-group col-md-6">
                  <label htmlFor="firstName">First Name</label>
                  <Field id="firstName" name="firstName" type="text" component={TextInput} placeholder="" arialabel="Enter First Name" disabled={firstNameDisabled} aria-required="true"></Field>
                </div>
              }
              {lastNameVisible &&
                <div className="form-group col-md-6">
                  <label htmlFor="lastName">Last Name</label>
                  <Field id="lastName" name="lastName" type="text" component={TextInput} placeholder="" arialabel="Enter Last Name" disabled={lastNameDisabled} aria-required="true"></Field>
                </div>
              }
            </div>
          }
          {companyVisible &&
            <div className="row">
              <div className="form-group col-md-8">
                <label htmlFor="company">Company</label>
                <Field id="company" name="company" type="text" component={TextInput} placeholder="" arialabel="Enter Company Name" ></Field>
              </div>
            </div>
          }
          {addressRowVisible &&
            <div className="row">
              <div className="form-group col-md-8">
                <label htmlFor="address1">Address</label>
                <Field id="address1" name="address1" type="text" component={TextInput} placeholder="" arialabel="Enter address" aria-required="true"></Field>
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="address2">Apt./Suite</label>
                <Field id="address2" name="address2" type="text" component={TextInput} placeholder="" arialabel="Enter Apartment or Suite"></Field>
              </div>
            </div>
          }
          {(cityVisible || stateVisible || postalCodeVisible) &&
            <div className="row">
              {cityVisible &&
                <div className="form-group col-sm-4">
                  <label htmlFor="city">City</label>
                  <Field id="city" name="city" type="text" component={TextInput} placeholder="" arialabel="Enter City" aria-required="true"></Field>
                </div>
              }
              {stateVisible &&
                <div className="form-group col">
                  <label htmlFor="state">State</label>
                  <Field name="state" component={SelectInput} validate={validateState}  disabled={false} aria-required="true">
                    <option value="">Select State</option>
                    {states.map((state) => {
                      return (<option value={state.abbreviation} key={state.abbreviation}>{state.name}</option>)
                    })}
                  </Field>
                </div>
              }
              {postalCodeVisible &&
                <div className="form-group col">
                  <label htmlFor="postalCode">ZIP Code</label>
                  <Field id="postalCode" name="postalCode" type="text" component={TextInput} placeholder="" arialabel="Enter Postal code" aria-required="true"></Field>
                </div>
              }
            </div>
          }
          {emailVisible &&
            <div className="row">
              <div className="form-group col-md-12">
                <label htmlFor="email1">{firstRespondersOffer ? "Work Email*" : "Email*"}</label>
                <Field id="email1" name="email1" type="email" component={TextInput} validate={validateEmail} placeholder="" arialabel="Enter email address" aria-required="true"></Field>
                {hintOffer && <small>If you are a Home Internet only customer: *This email is where you will receive your Virtual Prepaid Mastercard</small>}
                {samsMemberNumberVisible && <small>If you are a Home Internet only customer, this email is where you will receive your eGift card.</small>}
              </div>
              
            </div>
          }
          {confirmEmailVisible &&
            <div className="row">
              <div className="form-group col-md-12">
                <label htmlFor="confirmEmail1">{firstRespondersOffer ? "Confirm Work Email*" : "Confirm Email*"}</label>
                <Field id="confirmEmail1" name="confirmEmail1" type="email" component={TextInput} placeholder="" arialabel="Enter Email address again" aria-required="true"></Field>
              </div>
            </div>
          }
          {imeiVisible &&
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="imei">{imei2Visible ? `${IMEI_PLACEHOLDER} of First Purchased Device` : `${IMEI_PLACEHOLDER} of Purchased Device`}</label>
                <Field
                  id="imei"
                  name="imei"
                  type="string"
                  component={TextInput}
                  placeholder=""
                  arialabel="Enter IMEI number of first purchased device"
                  aria-required="true"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="confirmImei">{imei2Visible ? "Confirm First IMEI #" : "Confirm IMEI #"}</label>
                <Field
                  id="confirmImei"
                  name="confirmImei"
                  type="string"
                  component={TextInput}
                  placeholder=""
                  arialabel="Enter IMEI number of first purchased device again"
                  aria-required="true"
                />
              </div>
            </div>
          }
          {imei2Visible &&
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="imei2">{`${IMEI_PLACEHOLDER} of Second Purchased Device`}</label>
                <Field
                  id="imei2"
                  name="imei2"
                  type="string"
                  component={TextInput}
                  placeholder=""
                  arialabel="Enter IMEI number of second purchased device"
                  aria-required="true"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="confirmImei2">Confirm Second IMEI #</label>
                <Field
                  id="confirmImei2"
                  name="confirmImei2"
                  type="string"
                  component={TextInput}
                  placeholder=""
                  arialabel="Enter IMEI number of second purchased device again"
                  aria-required="true"
                />
              </div>
            </div>
          }
          {dealerCodeVisible &&
            <div className="row">
              <div className="form-group col-md-12">
                <label htmlFor="dealerCode">Dealer Code</label>
                <Field id="dealerCode" name="dealerCode" type="string" component={TextInput} placeholder="" arialabel="Enter dealer code" aria-required="true"></Field>
              </div>
            </div>
          }

          {(aaaMemberIdVisible) &&
            <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="aaaMemberId">AAA Membership Number</label>
                  <Field id="aaaMemberId" name="aaaMemberId" type="text" component={TextInput} placeholder="" arialabel="Enter AAA member id" disabled={aaaMemberIdDisabled}></Field>
                </div>
            </div>
          }
          {(costcoMemberIdVisible) &&
            <div className="row">
                <div className="form-group col-md-6"> 
                  <label htmlFor="costcoMemberId">Costco Member ID</label>
                  <Field id="costcoMemberId" name="costcoMemberId" type="text" component={TextInput} placeholder="" arialabel="Enter cost co member id" disabled={costcoMemberIdDisabled}></Field>
                </div>
            </div>
          }
          {(samsMemberNumberVisible) &&
            <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="samsMemberNumber">Sam's Club Membership Number</label>
                  <Field id="samsMemberNumber" name="samsMemberNumber" type="text" component={TextInput} placeholder="" arialabel="Enter Sam's Club Membership Number" disabled={samsMemberNumberDisabled} aria-required="true"></Field>
                </div>
            </div>
          }

          {retailer &&
            <>
              <div className="row">
                <div className="form-group col-md-12">
                  <Field name="retailer" type="text" component={SelectInput} validate={validateRetailerName} disabled={false} aria-required="true">
                  <option value="">Retailer Name (Place of Purchase)</option>
                    {getretailerDropdownOptions().map(element =><option key={element} value={element.toLowerCase().split(' ').join('_')}>{element}</option>)}
                  </Field>
                </div>
              </div>
            </>
          }
          <div className="row">
            {rcptDate &&
              <div className="form-group col-md-6">
                <label htmlFor="rcptDate">Purchase Date</label>
                <Field id="rcptDate" name="rcptDate" type="date" component={TextInput} placeholder="" arialabel="Enter purchase date" aria-required="true"></Field>
              </div>
            }
            {etfAmount1 &&
              <div className="form-group col-md-6 ">
                <label htmlFor="etfAmount1">Purchase Price</label>
               <Field id="etfAmount1" name="etfAmount1" type="number" component={TextInput} placeholder="" arialabel="Enter Purchase price" aria-required="true"></Field>
              </div>
            }
          </div>
          {telephone1Visible && !telephone1TextField && !firstRespondersOffer && !isTFBAccount &&
            <div className="row">
              <div className="mx-auto col-md-12">
                { // TODO: Make this text configurable
                  state.campaign.offerId === APPLE_TV_P1 ?
                    <p>Please choose a number on your account.</p>
                    :
                    <>
                      {state.campaign.offerId === SAMSCLUB_OFFER_ID
                        ? <p>Choose the phone number you would like to send the eGift card to. You will receive a text message with a link to redeem your eGift card if your submission is approved.</p>
                        : state.campaign.offerId === GO5GNEXT_OFFER_ID || state.campaign.offerId === SIXMONTHS_OFFER_ID || state.campaign.offerId === R3DSTAPLER_OFFER_ID
                            ? <p>Please select the phone number you would like to submit for the offer</p>
                            : <p>{(state.campaign.offerId === COSTCO_OFFER_ID || !hintOffer) && "Choose the phone number you would like to send the reward to. You will receive a text message with a link to redeem your reward if your submission is approved."} </p>
                      }
                    </>
                }
                <Field name="telephone1" component={SelectInput} validate={validatePhone} disabled={false} aria-required="true" >
                  <option value="">Select Phone Number</option>
                  {state.user.phoneNumbers.map((phone) => {
                    return (<option value={phone} key={phone}>{phone}</option>)
                  })}
                </Field>
              </div>
            </div>
          }
          {telephone1Visible && telephone1TextField && !firstRespondersOffer && !isTFBAccount &&
            <div className="row">
              <div className="form-group mx-auto col-md-12">
                { // TODO: Make this text configurable
                  state.campaign.offerId === APPLE_TV_P1 ?
                    <p>Please choose a number on your account.</p>
                    :
                    <>
                      {state.campaign.offerId === SAMSCLUB_OFFER_ID
                        ? <p>Choose the phone number you would like to send the eGift card to. You will receive a text message with a link to redeem your eGift card if your submission is approved.</p>
                        : state.campaign.offerId === GO5GNEXT_OFFER_ID || state.campaign.offerId === SIXMONTHS_OFFER_ID || state.campaign.offerId === R3DSTAPLER_OFFER_ID
                            ? <p>Please select the phone number you would like to submit for the offer</p>
                            : <p>{(state.campaign.offerId === COSTCO_OFFER_ID || !hintOffer) && "Choose the phone number you would like to send the reward to. You will receive a text message with a link to redeem your reward if your submission is approved."} </p>
                      }
                    </>
                }
                <label htmlFor="telephone1">{state.campaign.isHintOffer ? "" : "Enter T-Mobile Phone Number"}</label>
                <Field id="telephone1" name="telephone1" type="string" component={NumberInput} placeholder="" aria-required="true" arialabel={state.campaign.isHintOffer ? "" : "Enter T-Mobile Phone Number"}></Field>
                {hintOffer && <small> *If you have T-Mobile phone service with us, this is where you will receive your Virtual Prepaid Mastercard.</small>}
              </div>
            </div>
          }
          {/* Make all these fields configurable via RegistraionFields API */}
          {firstRespondersOffer  && !isTFBAccount &&
            <>
              <div className="row">
                <div className="form-group col-md-4">
                  <label htmlFor="msisdn">T-Mobile Phone Number</label>
                  <Field id="msisdn" name="MSISDN" type="text" component={SelectInput} validate={validatePhone} disabled={false} aria-required="true">
                    <option value="">Select Phone Number</option>
                    {state.user.phoneNumbers.map((phone) => {
                      return (<option value={phone} key={phone}>{phone}</option>)
                    })}
                  </Field>
                </div>
              </div>
            </>
          }
                  {/* TFB Customers Only for normal flow and first responder*/}
                  {telephone1Visible && isTFBAccount &&
                      <div className="row">
                          <div className="form-group mx-auto col-md-12">
                          
                              { // TODO: Make this text configurable
                                  state.campaign.offerId === APPLE_TV_P1 ?
                                      <p>Please choose a number on your account.</p>
                                      :
                    <>
                      {state.campaign.offerId === SAMSCLUB_OFFER_ID
                        ? <p>Choose the phone number you would like to send the eGift card to. You will receive a text message with a link to redeem your eGift card if your submission is approved.</p>
                        : state.campaign.offerId === GO5GNEXT_OFFER_ID || state.campaign.offerId === SIXMONTHS_OFFER_ID || state.campaign.offerId === R3DSTAPLER_OFFER_ID
                          ? <p>Please select the phone number you would like to submit for the offer</p>
                          : <p>{(state.campaign.offerId === COSTCO_OFFER_ID || !hintOffer) && "Choose the phone number you would like to send the reward to. You will receive a text message with a link to redeem your reward if your submission is approved."} </p>
      
                      }
                    </>
                                      
                              }
                              <Field name="telephone1" type="string" component={NumberInput} placeholder="" aria-required="true" arialabel={state.campaign.isHintOffer ? "" : "Enter T-Mobile Phone Number"}></Field>
                              {hintOffer && <small> *If you have T-Mobile phone service with us, this is where you will receive your Virtual Prepaid Mastercard.</small>}
                          </div>
                      </div>
                  }
                  {firstRespondersOffer && isTFBAccount &&
                      <>
                          <div className="row">
                              <div className="form-group col-md-4">
                                  <Field name="MSISDN" type="text" component={NumberInput} arialabel="Enter Phone number" aria-required="true">
                                  </Field>
                              </div>
                          </div>
                      </>
                  }
          
          <div className="form-group hero3">
            <Button aria-describedby="submit-errors" type="submit" className="btn" id="btnSubmit" style={{marginBottom:"5px"}} primary disabled={isSubmitting}>{isSubmitting ? "Submitting" : "Continue"}</Button>
            {status && status.formError && <div role="alert"><ErrorMessage id="submit-errors">{status.formError}</ErrorMessage></div>}
            {showPrivacyPolicy && <PrivacyPolicyComponent />}
          </div>

          {registrationInfoText && 
            <>
              <div className="container">
                <div className="row text-center">
                  <div className="col">
                    <p>{ReactHtmlParser(saniziteRawHtml(registrationInfoText))}</p>
                  </div>
                </div>
              </div>
            </>
          }
        </Form>)}
    </Formik>
  )
}

RegistrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.array,
  showPrivacyPolicy: PropTypes.bool
}
