import React from "react"
import PropTypes from "prop-types"

export const MailInThanksComponent = ({offerFormUrl}) => {
  return (
    <>
      <h3>Remember to mail-in your completed print form along with any supporting documents indicated on the form.</h3>
      <p>If the form did not open, please check your browser’s pop-up blocker. To open the PDF again, click <a href={offerFormUrl} target="_blank" rel="noopener noreferrer">here</a>.</p><br />
    </>
  )
}

MailInThanksComponent.propTypes = {
  offerFormUrl: PropTypes.string.isRequired
}
