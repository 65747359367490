import React, {useEffect, useState, useContext} from "react"
import { statement } from "@babel/template"

import {OfferApi} from "../utils/api"
import {parseClientFaq} from "../utils/Helpers"

import {AppContext} from "../components/context/AppContext"
import {FaqContainerComponent} from "../components"
import AnalyticsService from "../utils/AnalyticsService"
import MetaTags from "react-meta-tags"
import { ROUTE_PATHS } from "../utils/routes"


export const Faq = () => {
  const {dispatch, state} = useContext(AppContext)
  const [isFetchingFaq, setIsFetchingFaq ] = useState(false)

  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.FAQ);
    const fetchFaq = async () => {
      if (statement.faq) {
        return
      }

      setIsFetchingFaq(true)

      try {
        const faq = await OfferApi.getClientFaqs()
        const parsedFaq = await parseClientFaq(faq.data)

        dispatch({type: "SETFAQ", payload: parsedFaq})
        setIsFetchingFaq(false)

      } catch (error) {
        setIsFetchingFaq(false)
      }
    }

    fetchFaq()

  }, [dispatch])
  return (
    <>
      <MetaTags>
        <link rel="canonical" href="https://www.t-mobile.com/offers/deals-hub" />
      </MetaTags>
      <section>
        <div className="container hero3">
          <div className="row">
            <div className="col-md-12 mb-4">
              <h1 className="magenta">FAQs</h1>
              {isFetchingFaq && <div>Loading ...</div>}
              {state.faq.map((question) => <FaqContainerComponent key={question.header} questionGroup={question}>{question.header}</FaqContainerComponent>)}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
