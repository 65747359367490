import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import { Formik, Form, Field } from "formik"
import { TextInput } from "../forms"
import { Button } from "../styled"
import { AppContext } from "../context/AppContext"
import { AuthApi, OfferApi } from "../../utils/api"
import { ROUTE_PATHS } from "../../utils/routes";
import { ErrorMessage } from "../styled"
import {validateDisableButtonTfb } from "../../utils/FormUtils";

const initialValue = {
    phoneNumber: ""
}

export const GetTFBMsisdnForm = ({ history}) => {
    const validPhoneLengthRegEx = /(^$)|(^\d{10}$)/
    const { state, dispatch } = useContext(AppContext);
    // eslint-disable-next-line 
    const [isFetchingCampaignFromState, setIsFetchingCampaignFromStateCode] =
		useState(false);
    // eslint-disable-next-line 
    const [campaignId, setCampaignId] = useState(false);
    // eslint-disable-next-line 
    const [promoCodeInParam, setPromoCode] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [disableButton, setDisableButton] = useState(true)
    var selectedPromoCode = sessionStorage.getItem("promoCode");
    const validatePhone = async (value) => {
        let error;
        if (!value) {
          error = "T-Mobile phone number is required"
        }
    
        if (value && !value.replace(/[^a-z0-9]/g, "").match(validPhoneLengthRegEx)) {
          error = "T-Mobile phone number must be 10 digits"
        }
    
        if (value && (value.charAt(0) === "1" || value.charAt(0) === "0")) {
          error = "T-Mobile phone number cannot start with 0 or 1"
        }
        const isDisableTfbButton = validateDisableButtonTfb(error)
        setDisableButton(isDisableTfbButton)
        return error
    }

    

    
    const processTFBCustomer = async (event) => {
        let values = state.user;
        values.primaryPhoneNumber = event.phoneNumber;
        const user = await AuthApi.setTfbUserInfo(values);
        
        if (!user) {
            dispatch({
                type: "COMMONERROR",
                payload:
                    "The phone number you entered was not found. Please try again",
            });
            setErrorMessage("The phone number you entered was not found. Please try again");
            setIsSubmitting(false);
            return;
        } else {
            dispatch({ type: "SETUSER", payload: user });
            state.user.userInfo = user.userInfo;
            let values = {
                phoneNumber: event.phoneNumber,
                promoCode: selectedPromoCode,
                isTFBAccount: state.user.isTFBAccount,
                userInfo: state.user.userInfo,
                validateLine: true,
                isMyTMOFlow: state.user.isMyTMOFlow
            };
    
            dispatch({ type: "SETPROMOCODE", payload: selectedPromoCode });
            dispatch({ type: "SETSELECTEDPHONE", payload: event.phoneNumber });
            dispatch({type: "SETLOOKUPVALUES", payload: values});
    
            await OfferApi.getOfferDetails(values).then((response) => {
                if (!response.offerId && response.ineligibleReason) {
                    setErrorMessage(response.ineligibleReason);
                }
                else {
                    dispatch({ type: "SETCAMPAIGN", payload: response });
                    history.push(ROUTE_PATHS.PROMOTION);
                }
                setIsSubmitting(false)
            },
            (error) => {            
                setErrorMessage("Something went wrong, please try again");
                setIsSubmitting(false);
            })
        } 
    }

    return (
        <>
            <Formik initialValue={initialValue} onSubmit={(event)=>processTFBCustomer(event)}>
                <Form>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label htmlFor="tfbphonenumber">Please enter your primary phone number</label>
                            <Field id="tfbphonenumber" name="phoneNumber" component={TextInput} validate={validatePhone} placeholder="Phone Number" ></Field>
                        </div>
                    </div>
                    {!isSubmitting && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                    <div className="mt-3 text-center text-md-left">
                        <Button type="submit" className="btn btn-size" id="btnSubmit" primary disabled={disableButton}>Continue</Button>
                    </div>
                </Form>
            </Formik>
        </>
    )
}

GetTFBMsisdnForm.propTypes = {
    history: PropTypes.object.isRequired,
    inputPhoneNumber: PropTypes.string,
}