import React, { Component } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { ROUTE_PATHS } from "./utils/routes"

import { ScrollToTopComponent, CcpaComponent } from "./components"
import { LayoutComponent, } from "./components/layout"
import {
  Contact,
  Home,
  Dashboard,
  Promotion,
  Register,
  StatusDetail,
  Thanks,
  Upload,
  Faq,
  ContactSuccess,
  NotFound,
  AuthenticatedRoute,
  SubmissionMethod,
  FirstResponders, 
  SheerIdIFrame,
  AppleTV,
  AppleFitness, 
  Ooma,
  Google,
  AAAPromo,
  SamsClub,
  R3dStapler,
  SixMonths,
  GO5GNEXT,
  CostCo,
  FrontDoorFaq,
  GetTFBMsisdn,
  SubmissionStatus,
  PromoCode
} from "./pages"
import { AppProvider } from "./components/context/AppContext"


class App extends Component {
  render() {
    return (
      <div className="App">
        <AppProvider>
          <BrowserRouter>
              <CcpaComponent>
                <LayoutComponent>
                <ScrollToTopComponent>
                  <Switch>
                    <AuthenticatedRoute exact path={ROUTE_PATHS.PROMOTION} component={Promotion} />
                    <AuthenticatedRoute exact path={ROUTE_PATHS.FRONTDOORFAQ} component={FrontDoorFaq} />
                    <AuthenticatedRoute exact path={ROUTE_PATHS.PROMO_CODE_PAGE} component={PromoCode} />
                    <AuthenticatedRoute exact path={ROUTE_PATHS.REGISTER} component={Register} />
                    <AuthenticatedRoute exact path={ROUTE_PATHS.UPLOAD} component={Upload} />
                    <AuthenticatedRoute exact path={ROUTE_PATHS.THANKS} component={Thanks} />
                    <AuthenticatedRoute exact path={ROUTE_PATHS.STATUS_DETAIL} component={StatusDetail} />
                    <AuthenticatedRoute exact path={ROUTE_PATHS.SUBMISSION_METHOD} component={SubmissionMethod} />
                    <AuthenticatedRoute exact path={ROUTE_PATHS.SUBMISSION_STATUS} component={SubmissionStatus} />
                    <Route exact path={ROUTE_PATHS.DASHBOARD} component={Dashboard} />
                    <Route exact path={ROUTE_PATHS.HOME} component={Home} />
                    <Route exact path={ROUTE_PATHS.OFFERSEARCH} component={Home} />
                    <Route exact path={ROUTE_PATHS.ORDERSEARCH} component={Home} />
                    <Route exact path={ROUTE_PATHS.TVONUS} component={Home} />
                    <Route exact path={ROUTE_PATHS.CONTACT} component={Contact} />
                    <Route exact path={ROUTE_PATHS.CONTACT_SUCCESS} component={ContactSuccess} />
                    <Route exact path={ROUTE_PATHS.FAQ} component={Faq} />
                    <Route exact path={ROUTE_PATHS.FIRSTRESPONDER} component={FirstResponders} />
                    <Route exact path={ROUTE_PATHS.SHEERIDIFRAME} component={SheerIdIFrame} />
                    <Route exact path={ROUTE_PATHS.APPLETV} component={AppleTV} />
                    <Route exact path={ROUTE_PATHS.APPLEFITNESS} component={AppleFitness} />                    
                    <Route exact path={ROUTE_PATHS.OOMA} component={Ooma} />
                    <Route exact path={ROUTE_PATHS.GOOGLE} component={Google} />
                    <Route exact path={ROUTE_PATHS.AAA} component={AAAPromo} />
                    <Route exact path={ROUTE_PATHS.SAMSCLUB} component={SamsClub} />
                    <Route exact path={ROUTE_PATHS.REDSTAPLER} component={R3dStapler} />
                    <Route exact path={ROUTE_PATHS.SIXMONTHS} component={SixMonths} />
                    <Route exact path={ROUTE_PATHS.GO5GNEXT} component={GO5GNEXT} />
                    <Route exact path={ROUTE_PATHS.COSTCO} component={CostCo} />
                    <Route exact path={ROUTE_PATHS.GETTFBMSISDN} component={GetTFBMsisdn} />
                    <Route component={NotFound} />
                  </Switch>
                  </ScrollToTopComponent>
                </LayoutComponent>
              </CcpaComponent>
          </BrowserRouter>
        </AppProvider>
      </div>
    )
  }
}

export default App

