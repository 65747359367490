import React from "react"
import { CarouselComponent } from "../CarouselComponent"

export const TVisionWelcomeComponent = () => {
  return (
    <CarouselComponent bgPosition="top center" images={["/images/TVision/landing_desktop.jpg"]}>
        <div className="row">
          <div className="col mx-auto">
            <h1 className="text-center">Ready to Redeem your TVision offer?</h1>
          </div>
        </div>
    </CarouselComponent>
  )
}
