import React, {useContext,useEffect,useState} from "react"
import PropTypes from "prop-types"

import {AppContext} from "../components/context/AppContext"
import {QuestionsAndContactComponent} from "../components"
import {RegistrationForm} from "../components/forms"

import { SubmissionApi } from "../utils/api"
import {handleSubmissionError, handleSubmissionErrorAAA} from "../utils/FormUtils"
import {ROUTE_PATHS} from "../utils/routes"
import {validateImei} from "../utils/Helpers"
import AnalyticsService from "../utils/AnalyticsService"

const FIRST_RESPONDERS_OFFERID = Number.parseInt(process.env.REACT_APP_FIRST_RESPONDERS_OFFER_ID)
//const REAL_TIME_OFFER_IDS = process.env.REACT_APP_REAL_TIME_OFFER_IDS.split(',').map(id => parseInt(id))

const AAA_OFFER_ID = Number.parseInt(process.env.REACT_APP_AAA_OFFER_ID)


export const Register = (props) => {
  const {state, dispatch} = useContext(AppContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
//  const {campaign} = state
  const validateImeis = (values) => {
    const imeiErrors = []
    let imie2Visible = false

    if (values.imei2) {
      imie2Visible = true // two is checked first to determine error message for one
      const imeiIsValid = validateImei(values.imei2)
      if (!imeiIsValid) {
        imeiErrors.push({field: "imei2", message: "15-Digit IMEI # of Second Purchased Device is invalid."})
      }
    }

    if (values.imei) {
      const imeiIsValid = validateImei(values.imei)
      if (!imeiIsValid) {
        const message = imie2Visible
          ? "15-Digit IMEI # of First Purchased Device is invalid."
          : "15-Digit IMEI # of Purchased Device is invalid."
        imeiErrors.push({field: "imei", message} )
      }
    }

    return imeiErrors
  }
  const fields = state.campaign.offer.registrationFields
  
  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.REGISTER);
    if (state.isSubmitted && !isSubmitting) {
      props.history.replace(ROUTE_PATHS.DASHBOARD)
    }
  })

 /* const isAlreadySubmitted = async () => {
    const carrier = state.user.Provider
    const submission = await SubmissionApi.validateIfExists(campaign.offerId, carrier)
    if (submission.exists) {
      dispatch({ type: "SETISUBMISSION", payload: submission.isubmission })
      return true
    } else return false
  }*/

  const submitRegistrationForm = async (values, { setSubmitting, setStatus, setFieldError }) => {
    setIsSubmitting(true)
  
    if(!state.campaign.crossDupeCheck){
      props.history.push(ROUTE_PATHS.THANKS)
    }
    else
    {
        //ADDITIONAL BAN LIMIT CHECK TO AVOID DUPLICATED SUBMISSIONS ON REAL TIME OFFERS    
      /* if (REAL_TIME_OFFER_IDS.includes(campaign.offerId)) {
        const alreadySubmitted = await isAlreadySubmitted()            
        if (alreadySubmitted) return props.history.push(ROUTE_PATHS.THANKS)
      } */    
        
      values.firstName = values.firstName.trim()
      values.lastName = values.lastName.trim()
      values.address1 = values.address1.trim()
      values.address2 = values.address2.trim()
      values.city = values.city.trim()
      values.telephone1 = values.telephone1.trim()
      values.email1 = values.email1.trim()
      values.imei = values.imei.trim()
      values.confirmImei = values.confirmImei.trim()
      

      try 
      {
        const imeiErrors = validateImeis(values)
        if (imeiErrors.length > 0) {
          imeiErrors.forEach(error => setFieldError(error.field, error.message))
          setSubmitting(false)
          return
        }
    
        const { offerId } = state.campaign
        const submissionValues = { ...values, offerId }
        const iSubmission = state.iSubmission
        submissionValues.Provider = state.user.Provider
        let response
    
        if (!submissionValues.telephone1) {
          //Make sure the login phone number is saved if we don't have one on the form
          submissionValues.telephone1 = state.lookupValues.phoneNumber
        }

        if (!submissionValues.userInfo) {
          //Make sure userInfo is saved for TFB flow
          submissionValues.userInfo = state.user.userInfo
        }
    
        if (state.iSubmission && state.iSubmission.iSubmitId) {
          iSubmission.telephone1 = submissionValues.telephone1 !== null
            ? submissionValues.telephone1
            : state.lookupValues.phoneNumber

        
    
          response = await SubmissionApi.update(iSubmission)
          AnalyticsService.sendGa4Event({event: 'Promotions' , trigger: "registration", category: "Register", action: "Upload_Site",  label: "Submission_Registration"})
          AnalyticsService.sendEvent("Upload Site", "Submission Registration", "Register", { event: "registration" })
    
          if (!response.iSubmitId && response.ineligibleReason) {
            handleSubmissionError(setSubmitting, setStatus, response.ineligibleReason)
            return
          }
        } else {
          // These properties are saved to the iSubmitXXX table despite not being included on the form
          // Phase2: generalize so that this can be configured per offer (and what fields per offer)
          if (state.campaign.offerId === FIRST_RESPONDERS_OFFERID) {
            submissionValues.firstNameApi = state.user.firstName
            submissionValues.lastNameApi = state.user.lastName
            submissionValues.accountType = state.user.accountType
            submissionValues.accountSubType = state.user.accountSubType
            submissionValues.telephone1 = submissionValues.MSISDN
          }

               
    
          //Pass indicator for whether this is an app user
          submissionValues.isAppUser = state.user.isAppUser
          //Pass activePhoneNumbers from set user call
          submissionValues.activePhoneNumbers = state.user.phoneNumbers

          //numbers to save for confirmmin, min, loginphone and telephone1
          submissionValues.confirmMIN = state.selectedPhone
          submissionValues.MIN = state.selectedPhone
          submissionValues.logInPhoneNumber = state.lookupValues.phoneNumber
          submissionValues.isTFBAccount = state.user.isTFBAccount
          submissionValues.tfbRole = state.user.tfbRole
          submissionValues.isMyTMOFlow = state.user.isMyTMOFlow
          submissionValues.Provider = state.user.Provider !== '' ? state.user.Provider : 'T-MOBILE'
          submissionValues.userInfo = state.user.userInfo

          response = await SubmissionApi.insert(submissionValues)
    
          if (!response.iSubmitId && response.ineligibleReason) {
            handleSubmissionError(setSubmitting, setStatus, response.ineligibleReason)
            return
          }
        }

        dispatch({ type: "SETISUBMISSION", payload: response })
    
        if (state.campaign.isUploadsAllowed) {
          setSubmitting(false)
          props.history.push(ROUTE_PATHS.UPLOAD)
          return
        } else if (state.campaign.iFrameExperience) {
          setSubmitting(false)
          
          props.history.push(ROUTE_PATHS.SHEERIDIFRAME)
          return
        }
    
        
        await SubmissionApi.finalize(response).then(
          (result) =>{
            dispatch({ type: "SETISUBMISSION", payload: result })  
            setSubmitting(false)
            setIsSubmitting(false)      
            props.history.push(ROUTE_PATHS.THANKS)
          },
          (error) => {            
            if(state.campaign.offerId === AAA_OFFER_ID && error.message === 'Unable to finalize submission'){
                dispatch({ type: "SETISUBMISSION", payload: null })
              }          
            handleSubmissionErrorAAA(setSubmitting, setStatus)
            return
          }
        )
      } catch (error) {
        handleSubmissionErrorAAA(setSubmitting, setStatus)
        return
      }
    }


    
  
  
  }

  return (
    <section>
      <div className="container hero3">
        <div className="row">
          <div className="col-md-12">
            <h1 className="magenta">Confirm your details</h1>
            <p>{state.campaign.registrationCustomText}</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="mx-auto col-md-12">
            <RegistrationForm
              onSubmit={submitRegistrationForm}
              fields={fields}
              showPrivacyPolicy={state.campaign.isUploadsAllowed && !state.campaign.offerId === FIRST_RESPONDERS_OFFERID} />
            <QuestionsAndContactComponent />
          </div>
        </div>
      </div>
    </section>
  )
}

Register.propTypes = {
  history: PropTypes.object
}

Register.requiresAuth = true 
