import React, {useContext, useEffect, useState} from "react"
import PropTypes from "prop-types"

import {AppContext} from "../components/context/AppContext"
import {UploadForm} from "../components/forms"

import {SubmissionApi} from "../utils/api"
import {ROUTE_PATHS} from "../utils/routes"
import AnalyticsService from "../utils/AnalyticsService"
import { OfferApi } from "../utils/api"


export const Upload = (props) => {
  const {state, dispatch} = useContext(AppContext)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [uploadConfirmationText, setUploadConfirmationText] = useState("")

  useEffect(() => {
        AnalyticsService.sendUniquePageview(ROUTE_PATHS.UPLOAD);
    const getUploadConfirmationText = async () => {
      try {
        const uploadText = await OfferApi.getOfferAsset(state.campaign.offerId, "UPLOAD_CONFIRMATION")

        if (uploadText.length !== 0) {
          setUploadConfirmationText(uploadText.notes)
        }
      } catch (error) {
        throw error
      }
    }
    getUploadConfirmationText()
  })

  const upload = async () => {
    try {
      AnalyticsService.sendGa4Event({event: 'Promotions' , trigger: "file_uploaded", category: "Upload", action: "Upload_Site",  label: "Submission_Upload"})
      AnalyticsService.sendEvent("Upload Site", "Submission Upload", "Upload", {event: "file_uploaded"})
      return await SubmissionApi.upload(state.files, state.iSubmission,)
    } catch (error) {
      throw error
    }
  }

  const finalize = async () => {
    try {
      if (state.iSubmission.TFBRole === "") {
        state.iSubmission.TFBRole = "Empty:"+state.user.TFBRole
      }

      if (state.iSubmission.userInfo === "") {
        state.iSubmission.userInfo = state.user.userInfo
      }
      const submission = {...state.iSubmission, userPhoneNumbers: state.user.phoneNumbers, offerId: state.campaign.offerId}
      AnalyticsService.sendGa4Event({event: 'Promotions' , trigger: "submitted", category: "Upload", action: "Upload_Site",  label: "Submission_Upload"})
      AnalyticsService.sendEvent("Upload Site", "Submission Upload", "Upload", {event: "submitted"})
      return await SubmissionApi.finalize(submission)
    } catch (error) {
      throw error
    }
  }

  const onSubmit = async () => {
    try {
      setSubmitting(true)

      setErrorMessage("")

      if (state.files.length === 0) {
        setErrorMessage("You must upload at least one file")
        setSubmitting(false)
        return
      }
      
      await upload()

      const result = await finalize()

      dispatch({type: "SETISUBMISSION", payload: result})
      dispatch({type: "SETSUBMITTED", payload: true})

      setSubmitting(false)

      props.history.push(ROUTE_PATHS.THANKS)

    } catch (error) {
      setErrorMessage("Something went wrong please try again")
      setSubmitting(false)
    }
  }

  return (
    <section>
      <div className="container hero3">
        <div className="row">
          <div className="col-md-12">
            <h1 className="magenta mb-4">Upload some images and you&apos;re done.</h1>
            <UploadForm instructions={state.campaign.productList} handleSubmit={onSubmit} isSubmitting={submitting} errorMessage={errorMessage} uploadConfirmation={uploadConfirmationText} />
          </div>
        </div>
      </div>
    </section>
  )
}

Upload.propTypes = {
  history: PropTypes.object
}

Upload.requiresAuth = true
