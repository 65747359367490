import React from "react"

import {LinkButton} from "../components/styled"

export const NotFound = () => {
  return (
    <section>
      <div className="container hero3">
        <div className="row">
          <div className="col-md-12 mb-4">
            <h1 className="magenta">Page Not Found</h1>
            <h3>Sorry, that page doesn&apos;t exist. Click below to start a new submission or check the status of an existing submission.</h3>
          </div>
          <div className="ro">
            <div className="col-md-12 mb-4">
              <LinkButton href={process.env.REACT_APP_TMO_ID_AUTH_URL} primary>Start a Submission</LinkButton>
              <LinkButton className="ml-md-3" href={process.env.REACT_APP_TMO_ID_AUTH_URL} primary>Check Status</LinkButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
