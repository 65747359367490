import React from "react"
import {Link} from "react-router-dom"
import PropTypes from "prop-types"

import { ROUTE_PATHS } from "../utils/routes"

export const QuestionsAndContactComponent = ({showHeaderRow}) => {
  return (
    <>
    { showHeaderRow && <hr className="mt-5"/>}
    <p>Questions before you continue? To find answers <Link to={ROUTE_PATHS.FAQ}>click here</Link>  or <Link to={ROUTE_PATHS.CONTACT}>contact us</Link>.</p>
    </>
  )
}


QuestionsAndContactComponent.propTypes = {
  showHeaderRow: PropTypes.bool
}
