import React, {useContext, useEffect} from "react"
import PropTypes from "prop-types"

import {AppContext} from "../components/context/AppContext"
import {getDateAsString, getSubmissionStatusAsString,saniziteRawHtml} from "../utils/Helpers"
import ReactHtmlParser from "react-html-parser"
import { QuestionsAndContactComponent, RedeemButton } from "../components"
import AnalyticsService from "../utils/AnalyticsService";
import {ROUTE_PATHS} from "../utils/routes"

const FIRST_RESPONDERS_OFFERID = Number.parseInt(process.env.REACT_APP_FIRST_RESPONDERS_OFFER_ID)

export const StatusDetail = () => {
  useEffect(() => {
		AnalyticsService.sendUniquePageview(ROUTE_PATHS.STATUS_DETAIL);
	},);
  const {state: {selectedSubmission}} = useContext(AppContext)
  const defaultDate = "0001-01-01T00:00:00";
  let nonCompMessageList
  if (selectedSubmission.offerErrors) {
    nonCompMessageList = selectedSubmission.offerErrors.map((message) => <li key={message.errorId}><div>{ReactHtmlParser(saniziteRawHtml(message.nonCompMessage))}</div></li>)
  }

  const statusMessage = () => {
    if (nonCompMessageList && nonCompMessageList.length > 0) {
      return <h3>Your submission has been denied.  Here&apos;s why:<ul>{nonCompMessageList}</ul></h3>
    } else if (selectedSubmission.statusMessage !== "") {
      return <h3>{selectedSubmission.statusMessage}</h3>
    }
    return <></>
  }

  return (
    <section>
      <div className="container hero3">
        <div className="row">
          <div className="col-md-12">
            <h2 className="magenta">Reward Tracker</h2>
            {statusMessage()}
            <p><strong>Promotion Information</strong></p>
            <p>
              <strong>Promotion Number: </strong>{selectedSubmission.offerId}<br />
              <strong>Promotion Description: </strong>{selectedSubmission.offer.description}<br />
              {selectedSubmission.offerId !== FIRST_RESPONDERS_OFFERID &&
                <>
                  <strong>Promotion Date Range: </strong>
                  {getDateAsString(selectedSubmission.offer.startDate)} - {getDateAsString(selectedSubmission.offer.endDate)}
                </>
              }
            </p>
            <p><strong>Customer Information</strong></p>
            <p>
              <strong>Tracking ID: </strong>{selectedSubmission.trackingId}<br />
              <strong>Submission Date: </strong>{getDateAsString(selectedSubmission.stampDate)}
            </p>
            <p><strong>Reward Information</strong></p>
            <p>
              {selectedSubmission.codeRedemptionDate !== defaultDate && 
                <>
                  <strong>Code Redemption Date:</strong> {getDateAsString(selectedSubmission.codeRedemptionDate)}<br />
                </>
              }
              {selectedSubmission.equipmentShippedDate !== defaultDate && 
                <>
                  <strong>Equipment Shipped Date:</strong> {getDateAsString(selectedSubmission.equipmentShippedDate)}<br />
                </>
              }
              {selectedSubmission.equipmentActivationDate !== defaultDate && 
                <>
                  <strong>Equipment Activation Date:</strong> {getDateAsString(selectedSubmission.equipmentActivationDate)}<br />
                </>
              }
              <strong>Reward Type:</strong> {selectedSubmission.offer.rewardType}<br />
              <strong>Status:</strong> {getSubmissionStatusAsString(selectedSubmission)}
            </p>
            <RedeemButton submission={selectedSubmission} />
          </div>
        </div>
        <QuestionsAndContactComponent></QuestionsAndContactComponent>
      </div>
    </section>
  )
}

StatusDetail.propTypes = {
  match: PropTypes.object.isRequired
}

StatusDetail.requiresAuth = true
